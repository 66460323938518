import React from 'react'
import { useSelector } from 'react-redux';

type Props = {
    setClose: React.Dispatch<React.SetStateAction<boolean>>
    content:any
}

function PermissionModal({setClose,content}: Props) {
    const darkmode = useSelector((state: any) => state.account.darkmode);

  return (
    <div className="ContacUs " >

      <div className="d-flex justify-content-center align-items-center h-100">
       
        <div className="ContactUsContainer col-12  p-4">
          
          <div className="d-flex justify-content-between header py-3 px-5">
            <h2 className="subHeaders">Permissions</h2>
            <button onClick={() => setClose(false)}>
              <i id='contact-close-btn' className="fa-regular fa-circle-xmark"></i>
            </button>
          </div>

          <div className={` jsonContent py-5 px-5`}>
            
          
        {content}
          </div>

        </div>

      </div>

    </div>
  )
}

export default PermissionModal
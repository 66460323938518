import React, {useState} from 'react';
import {ContentHeader} from '@app/components';
import Container from '@app/components/CustomDash/Container';
import {useTranslation} from 'react-i18next';
import {PfButton, PfImage} from '@profabric/react-components';
import {Col} from 'react-bootstrap';
import General from './General';
import Account from './Account';
import ChangePassword from './ChangePassword';
import SystemHealth from './SystemHealth';
import { useSelector } from 'react-redux';

// const StyledUserImage = styled(PfImage)`
//   --pf-border: 3px solid #adb5bd;
//   --pf-padding: 3px;
// `;

function Profile() {
  const [activeTab, setActiveTab] = useState('account');
  const [t] = useTranslation();

  const darkmode = useSelector((state: any) => state.account.darkmode);


  // const toggle = (tab: string) => {
  //   if (activeTab !== tab) setActiveTab(tab);
  // };

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };

  return (
    <Container>
      <div className={`container-fluid ${darkmode ? 'darkWrapper':''}`}>
        <div className="row d-flex justify-content-center align-items-center">
          <Col xs={12}>
            <div className="mt-2 FilterButtonContainer col-lg-10 col-md-12 mx-auto pt-2 mb-2 d-flex justify-content-between">
              <h3 className="subHeaders">  </h3>
            </div>
            <div className="overflow-auto">
              <div className={`agentDetailsTable mx-5 ${darkmode && 'darkSecondaryWrapper'}`}>
                <ul className="tabs-nav">
                  <li
                    className={`tab ${activeTab === 'account' ? 'active' : ''}`}
                    onClick={() => handleTabClick('account')}
                    aria-hidden
                    id='profile-account-tab'
                  >
                    Account
                  </li>
                  <li
                    className={`tab ${activeTab === 'general' ? 'active' : ''}`}
                    onClick={() => handleTabClick('general')}
                    aria-hidden
                    id='profile-general-tab'
                  >
                    General
                  </li>
                  <li
                    className={`tab ${
                      activeTab === 'changePassword' ? 'active' : ''
                    }`}
                    onClick={() => handleTabClick('changePassword')}
                    aria-hidden
                    id='profile-change-pass-tab'
                  >
                    Change Password
                  </li>
                  <li
                    className={`tab ${
                      activeTab === 'systemHealth' ? 'active' : ''
                    }`}
                    onClick={() => handleTabClick('systemHealth')}
                    aria-hidden
                    id='profile-sys-health-tab'
                  >
                    System Health
                  </li>
                  {/* <li
                    className={`tab ${activeTab === '' ? 'active' : ''}`}
                    aria-hidden
                  /> */}
                  <li
                    className={`tab ${activeTab === '' ? 'active' : ''}`}
                    aria-hidden
                  />
                </ul>

                <div className="tab-content">
                  <div className="tab-table-container">
                    {activeTab === 'account' && <Account />}
                    {activeTab === 'general' && <General />}
                    {activeTab === 'changePassword' && <ChangePassword />}
                    {activeTab === 'systemHealth' && <SystemHealth />}
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </div>
      </div>
      {/* <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3">
              <div className="card card-primary card-outline">
                <div className="card-body box-profile">
                  <div className="text-center">
                    <StyledUserImage
                      width={100}
                      height={100}
                      rounded
                      src="/img/default-profile.png"
                      alt="User profile"
                    />
                  </div>
                  <h3 className="profile-username text-center"> */}
      {/* [Name from API] */}
      {/* {user.name} */}
      {/* </h3> */}
      {/* <p className="text-muted text-center">
                    {/* Roles [From API] */}
      {/* {user.roles || ''} */}
      {/* Description */}
      {/* {roleDescription || user.roles} */}
      {/* </p> */}
      {/* <ul className="list-group list-group-unbordered mb-3">
                    <li className="list-group-item">
                      <b>Last Login</b>

                      <span className="float-right">
                        {/* [From API] */}
      {/* {formatDate(user.lastLogin) || ''} */}
      {/* </span> */}
      {/* </li>
                    <li className="list-group-item">
                      <b>Password Change</b>
                      <span className="float-right">
                        {/* [From API] */}
      {/* {formatDate(user.lastPasswordChange) || ''}
                      </span> */}
      {/* </li> */}
      {/* <li className="list-group-item">
                      <b>Created At</b>
                      <span className="float-right">
                        {/* [From API]] */}
      {/* {formatDate(user.createdAt)} */}
      {/* </span> */}
      {/* </li> */}
      {/* </ul> */}
      {/* </div> */}
      {/* /.card-body */}
      {/* </div> */}
      {/* </div> */}
      {/* <div className="col-md-9">
              <div className="card">
                <div className="card-header p-2">
                  <ul className="nav nav-pills">
                    <li className="nav-item">
                      <button
                        type="button"
                        className={`nav-link ${
                          activeTab === 'GENERAL' ? 'active' : ''
                        }`}
                        onClick={() => toggle('GENERAL')}
                      >
                        {t<string>('General')}
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        type="button"
                        className={`nav-link ${
                          activeTab === 'PASSWORD' ? 'active' : ''
                        }`}
                        onClick={() => toggle('PASSWORD')}
                      >
                        {t<string>('Change Password')}
                      </button>
                    </li>
                  </ul>
                </div>
                <div className="card-body">
                  <div className="tab-content">
                    <SettingsTab isActive={activeTab === 'GENERAL'} />
                    <ChangePasswordTab isActive={activeTab === 'PASSWORD'} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <div className="container-fluid">
        <div className="row">
          <div className="col-md-3">
            <Link
              to="/change-password"
              className="d-flex justify-content-end pr-1"
            >
              Change Password
            </Link>
          </div>
        </div>
      </div> */}
    </Container>
  );
}

export default Profile;

import Container from '@app/components/CustomDash/Container';
import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {toggleSidebarMenu} from '@app/store/reducers/ui';
import {addWindowClass, removeWindowClass, sleep} from '@app/utils/helpers';
import {swaggerGetUsersCount} from '@app/services/swaggerAuth';
import GlobeChart from './GlobeChart';
import {swaggerGetAgentCount} from '@app/services/agents';
import {getAlertsCount} from '@app/services/alerts';
import UserManuel from './UserManuel';
import {getVulnerabilityCount} from '@app/services/threats';
import { getSettings } from '@app/services/settings';

export type Settings  = {
  customerId: string;
  createdBy: string;
  updatedBy: string;
  createdAt: number;
  updatedAt: number;
  key: string;
  value: string;
  category: string;
  uom: string;
  active: boolean;
  description: string;
  id: string;
}

function Dashboard() {
  const dispatch = useDispatch();
  const menuSidebarCollapsed = useSelector(
    (state: any) => state.ui.menuSidebarCollapsed
  );
  const controlSidebarCollapsed = useSelector(
    (state: any) => state.ui.controlSidebarCollapsed
  );
  const screenSize = useSelector((state: any) => state.ui.screenSize);
  const token = useSelector((state: any) => state.auth.token);
  const [agentCount, setAgentCount] = useState<any>();
  const [usersCount, setUsersCount] = useState(0);
  const [alertsCount, setAlertsCount] = useState(0);
  const [vulnerabilityCount, setVulnerabilityCount] = useState(0);
  const [agentSettingsValue, setAgentSettingsValue] = useState(0);

  const darkmode = useSelector((state: any) => state.account.darkmode);

  const handleToggleMenuSidebar = () => {
    dispatch(toggleSidebarMenu());
  };

  const fetchSettings = (token: string) => {
    getSettings(token).then((response) => {
      const {data} = response;
      const managedEndpoints = data.find((item:Settings) => item.key === "Managed Endpoints");
      setAgentSettingsValue(managedEndpoints.value)

    });
  };

  const getVulnarabilityCount = async () => {
    getVulnerabilityCount(token,false)
      .then((res) => {
        setVulnerabilityCount(res.data);
      })
      .catch(() => {});
  };

  const getAgentCount = () => {
    swaggerGetAgentCount(token)
      .then((res) => {
        setAgentCount(res.data);
      })
      .catch(() => {});
  };

  const getUsersCount = () => {
    swaggerGetUsersCount(token)
      .then((response) => {
        setUsersCount(response.data);
      })
      .catch(() => {});
  };

  const fetchAlertsCount = () => {
    getAlertsCount(token,'','',false)
      .then((response) => {
        setAlertsCount(response.data);
      })
      .catch(() => {});
  };

  useEffect(() => {
    getAgentCount();
    getUsersCount();
    getVulnarabilityCount();
    fetchAlertsCount();
    fetchSettings(token)
  }, []);

  useEffect(() => {
    removeWindowClass('sidebar-closed');
    removeWindowClass('sidebar-collapse');
    removeWindowClass('sidebar-open');
    if (menuSidebarCollapsed && screenSize === 'lg') {
      addWindowClass('sidebar-collapse');
    } else if (menuSidebarCollapsed && screenSize === 'xs') {
      addWindowClass('sidebar-open');
    } else if (!menuSidebarCollapsed && screenSize !== 'lg') {
      addWindowClass('sidebar-closed');
      addWindowClass('sidebar-collapse');
    }
  }, [screenSize, menuSidebarCollapsed]);

  useEffect(() => {
    if (controlSidebarCollapsed) {
      removeWindowClass('control-sidebar-slide-open');
    } else {
      addWindowClass('control-sidebar-slide-open');
    }
  }, [screenSize, controlSidebarCollapsed]);

  return (
    <Container>
      <div className="h-100 col-12 p-0">
        {/* <div className="d-sm-flex  align-items-center justify-content-between px-3 mb-4 ">
          <h3 className="subHeaders">Dashboard</h3>
          <a
            href="#"
            className="d-none d-sm-inline-block CustomBtn filterButton shadow-sm"
            onClick={() => {
              setShowUserMAnuel(true)
            }}
          >
            Help
          </a>
        </div> */}

        <div className={`d-flex justify-content-between newOverLayDesign px-1 `}>
          {/* <!-- Earnings (Monthly) Card Example --> */}
          {/* <div className="col-xl-3 col-md-6 mb-4">
            <div className="card border-left-primary shadow h-100 py-2">
              <div className="card-body">
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                      Earnings (Monthly)
                    </div>
                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                      $40,000
                    </div>
                  </div>
                  <div className="col-auto">
                    <i className="fas fa-calendar fa-2x text-gray-300"></i>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="col-lg-4 col-md-6 col-12">
            <div className={`${
                darkmode ? 'darkSecondaryWrapper mainWidgestContainer' : ''
              }`}>

           
            <div
              className={`CustomDashCard `}
            >
              <div className="inner">
                <div>
                  <h3>{vulnerabilityCount ? vulnerabilityCount : 0}</h3>
                  <p style={{fontSize: '21px'}}>Vulnerabilities</p>
                </div>
                <div className="icon">
                  {darkmode ?  <img src="/img/Threats 1dark.png" alt="" />:  <img src="/img/Threats 1.png" alt="" />}
                 
                </div>
              </div>

              <a href="/vulnerability" className="small-box-footer" id='more-info-vulnerabilities-btn'>
                More info <i className="fa-solid fa-chevron-right"></i>
              </a>
            </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-12  mt-md-0">
          <div className={`${
                darkmode ? 'darkSecondaryWrapper mainWidgestContainer' : ''
              }`}>
            <div
              className={`CustomDashCard `}
            >
              <div className="inner pb-0">
                <div>
                  <h3>{agentCount ? agentCount : '0'}</h3>

                  <p className="mb-0" style={{fontSize: '21px'}}>Managed Endpoints</p>
                  <p className="" style={{fontSize: '0.8rem', marginBottom: '13px'}}>
                    Captured out of <span style={{fontWeight: 'bolder'}}>{agentSettingsValue}</span> endpoints
                  </p>
                </div>
                <div className="icon">
                  {darkmode ?  <img src="/img/dashboard agents icon 1dark.png" alt="" />:  <img src="/img/dashboard agents icon 1.png" alt="" />}

                </div>
              </div>
              <a href="/agents" className="small-box-footer" id='more-info-managed-endpoints-btn'>
              More info <i className="fa-solid fa-chevron-right"></i>
              </a>
            </div>
          </div>
          </div>

          {/* <div className="col-lg-3 col-md-6 col-12 mt-3 mt-md-3 mt-lg-0">
          <div className={`${
                darkmode ? 'darkSecondaryWrapper mainWidgestContainer' : ''
              }`}>
            <div
              className={`CustomDashCard   `}
            >
              <div className="inner">
                <div>
                  <h3>{usersCount ? usersCount : 0}</h3>

                  <p style={{fontSize: '21px'}}>Users</p>
                </div>
                <div className="icon">
                {darkmode ?   <img src="/img/dashboard users icon 1dark.png" alt="" />:   <img src="/img/dashboard users icon 1.png" alt="" />}

                 
                </div>
              </div>
              <a href="/users" className="small-box-footer">
              More info <i className="fa-solid fa-chevron-right"></i>
              </a>
            </div>
            </div>
          </div> */}

          <div className="col-lg-4 col-md-6 col-12   mt-lg-0">
          <div className={`${
                darkmode ? 'darkSecondaryWrapper mainWidgestContainer' : ''
              }`}>
            <div
              className={`CustomDashCard  `}
            >
              <div className="inner">
                <div>
                  <h3>{alertsCount ? alertsCount : 0}</h3>

                  <p style={{fontSize: '21px'}}>Alerts</p>
                </div>
                <div className="icon">
                {darkmode ?  <img src="/img/dashboard alert icon 1dark.png" alt="" />:  <img src="/img/dashboard alert icon 1.png" alt="" />}

                  
                </div>
              </div>

              <a href="/alerts" className="small-box-footer" id='more-info-alerts-btn'>
              More info <i className="fa-solid fa-chevron-right"></i>
              </a>
            </div>
            </div>
          </div>
        </div>

        <div className='h-100'>
          <GlobeChart />
        </div>
      </div>
    </Container>
  );
}

export default Dashboard;

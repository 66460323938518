import { swaggerPostProfile } from '@app/services/swaggerAuth';
import React, { useEffect, useState } from 'react'
import {useDispatch, useSelector} from 'react-redux';
import { toast } from 'react-toastify';
import { setDarkMode } from '@app/store/reducers/Account';


type Props = {}

function General({}: Props) {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const token = useSelector((state: any) => state.auth.token);
    const currentUser = useSelector((state: any) => state.auth.currentUser);

    const darkmode = useSelector((state: any) => state.account.darkmode);
    const dispatch = useDispatch()

    const handleDarkModeToggle = () => {
      const newDarkModeState = !darkmode; // Toggle dark mode
      // Dispatch action to update dark mode state
      dispatch(setDarkMode(newDarkModeState));
      // Update localStorage if needed
      localStorage.setItem('darkmode', String(newDarkModeState)); // Update localStorage
    };
  
    const handleSubmit = (e: any) => {
      e.preventDefault();
  
      // Profile POST API
      // http://69.58.117.210:44444/ui/user/profile/11111111-1111-1111-111111111111
      // axios.post(`http://69.58.117.210:44444/ui/user/profile/${token}`, {
      //   name,
      //   email,
      //   password
      // })
      swaggerPostProfile(token, name, email, password)
        .then((response) => {
          toast.success('Form Submission Successful');
          setName('');
          setEmail('');
          setPassword('');
        })
        .catch((err) => {
          toast.error('Form Submission Failed');
          setName('');
          setEmail('');
          setPassword('');
        });
    };
  
    useEffect(() => {
      if (currentUser !== null) {
        setName(currentUser.name);
        setEmail(currentUser.userId);
      }
    }, [currentUser])
  return (
    <div className='GeneralTabContainer col-lg-6 mx-auto'>

<form className="form-horizontal" onSubmit={handleSubmit}>
        <div className="form-group ">
          <label htmlFor="inputName" className="col-sm-2 col-form-label">
            Name
          </label>
          <div className="">
            <input
              type="name"
              className="form-control"
              id="inputName"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="form-group ">
          <label htmlFor="inputEmail" className="col-sm-2 col-form-label">
            Email
          </label>
          <div className="">
            <input
              type="email"
              className="form-control"
              id="inputEmail"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled
            />
          </div>
        </div>
        {/* <div className="form-group row">
          <label htmlFor="inputEmail" className="col-sm-2 col-form-label">
            Password
          </label>
          <div className="col-sm-10">
            <input
              type="password"
              className="form-control"
              id="inputPassword"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
        </div> */}
        <div className='form-group' >
            <label htmlFor="" className="col-sm-2 col-form-label" >
              Theme
            </label> 
            <div className="toggleContainer">
                  <label className="toggle">
                    <input
                      type="checkbox"
                      id="modeToggle"
                      checked={darkmode}
                      onChange={(e) => {
                        let check = e.target.checked;
                        if (check) {
                          dispatch(setDarkMode(true));
                          localStorage.setItem('darkmode', 'true');
                        } else {
                          dispatch(setDarkMode(false));
                          localStorage.removeItem('darkmode');
                        }
                      }}
                    />
                    <span id='theme-toggle' className="slider round"></span>
                  </label>
                  <label className='text' htmlFor='modeToggle'  style={{color: `${!darkmode ? '#fff' : '#0C273D'}`,
                left: `${!darkmode ? '27px' : '2px'}`
                }}>
                    {darkmode ? 'dark mode' : 'light mode'}
                  </label>
                </div>
        </div>
        <div className="form-group pl-1 mt-5 pt-5">
          <div className="">
            <button className='filterButton w-100' type="submit " id='profile-acc-submit-btn' >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default General
import {getAgentsLocation} from '@app/services/swaggerAuth';
import {Icon, LatLngBoundsExpression} from 'leaflet';
import React, {useEffect, useRef, useState} from 'react';
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMapEvents,
  useMap
} from 'react-leaflet';
import {useSelector} from 'react-redux';
import MarkerClusterGroup from 'react-leaflet-cluster';

const CustomMarker = ({position, icon, onClick, children}) => {
  // const map = useMapEvents({
  //   click: onClick, // Replace with 'mouseover' if you want to trigger on hover
  // });

  return (
    <Marker position={position} icon={icon} eventHandlers={{click: onClick}}>
      {children}
    </Marker>
  );
};

const GlobeChart = () => {
  const currentUser = useSelector((state: any) => state.auth.currentUser);
  const token = useSelector((state: any) => state.auth.token);

  const [agentsList, setAgentsList] = useState<Array<any>>([]);
  const [hoveredAgentsList, setHoveredAgentsList] = useState<Array<any> | null>(
    []
    );
  const onlineThresholdValue = localStorage.getItem('onlineThresholdValue') || 120;

  const isOnline = (timestamp: any) => {
    const currentTime = new Date().getTime();
    const timeDifference = currentTime - timestamp;
    return timeDifference <= 60 * 1000 * onlineThresholdValue;
  };

  const fetchAgentsLocation = () => {
    getAgentsLocation(token).then((response) => {
      const {data} = response;
      const agentsList = data.map((agent: any) => ({
        ...agent,
        isOnline: isOnline(agent.lastSync)
      }));
      // console.log('agentsList', agentsList);
      setAgentsList(data);
    });
  };

  const position = [51.505, -0.09];

  const customIcon = new Icon({
    iconUrl: '/img/markerIcon.png',
    iconSize: [30, 30]
  });

  const multipleAgentsCondition = (firstAgent: any, secondAgent: any) => {
    return (
      firstAgent.longitude === secondAgent.longitude &&
      firstAgent.latitude === secondAgent.latitude
    );
  };

  const handleClusterEvent = (event, hoveredAgent) => {
    console.log('hoveredAgent', hoveredAgent);
    console.log('CLUSTER ONCLICK');
    const { layer } = event;
    // console.log('handleClusterEvent', event);
    // setClusterData(layer.getAllChildMarkers());

    console.log('ON MOUSE ENTER CALLED');
    // const hoveredAgentsList = agentsList.filter(
    //   (agent) =>
    //     agent.longitude === hoveredAgent.longitude &&
    //     agent.latitude === hoveredAgent.latitude
    // );
    const hoveredAgentsList = agentsList?.filter((agent) =>
      multipleAgentsCondition(agent, hoveredAgent)
    );
    window.console.log(
      'hovered Agents Name',
      hoveredAgentsList.map((agent) => agent.hostname)
    );
    console.log('hoveredAgentsList', hoveredAgentsList);

    // this line hides the custom markers
    // setHoveredAgentsList(hoveredAgentsList);
  };

  // const handleAgentsMouseEnter = (hoveredAgent: any) => {
  //   console.log('ON MOUSE ENTER CALLED');
  //   // const hoveredAgentsList = agentsList.filter(
  //   //   (agent) =>
  //   //     agent.longitude === hoveredAgent.longitude &&
  //   //     agent.latitude === hoveredAgent.latitude
  //   // );
  //   const hoveredAgentsList = agentsList.filter((agent) =>
  //     multipleAgentsCondition(agent, hoveredAgent)
  //   );
  //   window.console.log(
  //     'hovered Agents Name',
  //     hoveredAgentsList.map((agent) => agent.hostname)
  //   );
  // };

  // removed unncessary API call from useEffect
  useEffect(() => {
    if (token) {
      fetchAgentsLocation();
    }
  }, [token]);


  const worldBounds:LatLngBoundsExpression = [
    [-90, -180], // Southwest coordinates
    [90, 180]    // Northeast coordinates
  ];

 
  // console.log(agentsList);

  return (
    <div className="mt-0 h-100">
      <MapContainer center={[28.3089, -81.3708]} minZoom={3} maxZoom={18} zoom={4}  maxBoundsViscosity={1.0} maxBounds={worldBounds} scrollWheelZoom >
        <TileLayer
         attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
         url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
          minNativeZoom={5}
        />
        
        <MarkerClusterGroup>
          {agentsList?.map((currentAgent, index) => (
            <CustomMarker
              position={[
                currentAgent?.latitude ? currentAgent?.latitude : 0,
                currentAgent?.longitude ? currentAgent?.longitude : 0
              ]}
              icon={customIcon}
              onClick={(event: any) => handleClusterEvent(event, currentAgent)}
            >
              <Popup>
                <div className="listMainContainer">
                  <h5>
                    {' '}
                    <span className="mr-2">
                      <i className="fa-solid fa-location-dot" />
                    </span>
                    {currentAgent?.city}
                  </h5>
                  <div className="ListSubContainer">
                    <ul>
                      <a href={`/agents/agent-details/${currentAgent.id}`}>
                        {currentAgent.hostname}
                      </a>
                    </ul>
                  </div>
                </div>
              </Popup>
            </CustomMarker>
          ))}

          {/* {agentsList?.map((currentAgent, index) => (
            <Marker
              position={[
                currentAgent?.latitude ? currentAgent?.latitude : 0,
                currentAgent?.longitude ? currentAgent?.longitude : 0
              ]}
              icon={customIcon}
            >
              <Popup>
                <div className="listMainContainer">
                  <h5>
                    {' '}
                    <span className="mr-2">
                      <i className="fa-solid fa-location-dot" />
                    </span>
                    {currentAgent?.city}
                  </h5>
                  <div className="ListSubContainer">
                    <ul>
                      <a href={`/agents/agent-details/${currentAgent.id}`}>
                        {currentAgent.hostname}
                      </a>
                    </ul>
                  </div>
                </div>
              </Popup>
            </Marker>
          ))} */}

          {/* {
                agentsList.map((currentUser,index)=>{
                    setHoveredAgentsList(agentsList.filter(
                        (agent) =>
                          agent.longitude === currentUser.longitude &&
                          agent.latitude === currentUser.latitude
                      ))
                      if(hoveredAgentsList?.length > 5){
                        return (
                            <MarkerClusterGroup>
                                <Popup>
                <div className='listMainContainer'>
                  <h5> <span className='mr-2'><i className="fa-solid fa-location-dot"></i></span>
                   {currentUser?.city}
                   </h5>
                  <div className='ListSubContainer'>
                    <ul>
                    
                    {hoveredAgentsList?.map((i,index)=> (
                        <li>
                            <a href={`/agents/agent-details/${i.id}`}>{i.hostname}</a>
                        </li>
                    ))}
                    </ul>
                  </div>
          
                </div>
                </Popup>
                            </MarkerClusterGroup>
                        )
                      }else{
                        return(
                            <Marker position={[currentUser?.latitude ? currentUser?.latitude : 0, currentUser?.longitude ? currentUser?.longitude : 0 ]} icon={customIcon}>
                            <Popup>
                            <div className='listMainContainer'>
                              <h5> <span className='mr-2'><i className="fa-solid fa-location-dot"></i></span>
                               {currentUser?.city}
                               </h5>
                              <div className='ListSubContainer'>
                                <ul>
                                <a href={`/agents/agent-details/${currentUser.id}`}>{currentUser.hostname}</a>
                                </ul>
                              </div>
                      
                            </div>
                            </Popup>
                          </Marker>
                        )
                      }
                })
              } */}
        </MarkerClusterGroup>
      </MapContainer>
    </div>
  );
};

export default GlobeChart;

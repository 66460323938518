import React from 'react';
import {Form, Row} from 'react-bootstrap';

const External = () => {
  return (
    <Row>
      <Form className="ml-5 col-8">
        <Form.Group className="my-2 row">
          <div className="col-3" />

          {/* <Form.Check className="mr-4" type="checkbox" label="DHCP" /> */}
          <div class="form-check mr-4">
            <input
              class="form-check-input"
              type="checkbox"
              id="dhcp-checkbox"
              // disabled
            />
            <label for="dhcp-checkbox">DHCP</label>
          </div>
          {/* <Form.Check type="checkbox" label="Static IP Address" /> */}
          <div class="form-check mr-4">
            <input
              class="form-check-input"
              type="checkbox"
              id="static-ip-address"
              // disabled
            />
            <label for="static-ip-address">Static IP Address</label>
          </div>
        </Form.Group>
        <Form.Group className="mb-4 mt-2 row">
          <Form.Label className="col-3 mt-1" htmlFor="disabledTextInput">
            Node 1
          </Form.Label>
          <Form.Control className="col" placeholder="" />
        </Form.Group>
        <Form.Group className="my-4 row">
          <Form.Label className="col-3 mt-1" htmlFor="disabledTextInput">
            Node 2
          </Form.Label>
          <Form.Control className="col" placeholder="" />
        </Form.Group>
        <Form.Group className="my-4 row">
          <Form.Label className="col-3 mt-1" htmlFor="disabledTextInput">
            Common Subnet
          </Form.Label>
          <Form.Control className="col" placeholder="" />
        </Form.Group>
        <Form.Group className="my-4 row">
          <Form.Label className="col-3 mt-1" htmlFor="disabledTextInput">
            Common Gateway
          </Form.Label>
          <Form.Control className="col" placeholder="" />
        </Form.Group>
        <Form.Group className="my-4 row">
          <Form.Label className="col-3 mt-1" htmlFor="disabledTextInput">
            DNS
          </Form.Label>
          <Form.Control className="col" placeholder="" />
        </Form.Group>

        {/* <Row>
          <div className="col-3"></div>
          <div>
            <Button type="submit">Submit</Button>
          </div>
        </Row> */}
        <div className="d-flex mt-5">
          <div className="col-3" />
          <button className="filterButton w-50 mr-4 me-4" type="submit">
            Reset
          </button>
          <button
            type="button"
            className="filterButton w-50 ml-4 ms-4"
            onClick={() => {}}
          >
            Close
          </button>
        </div>
      </Form>
    </Row>
  );
};

export default External;

import Container from '@app/components/CustomDash/Container';
import {
  swaggerGetAgentReport,
  swaggerGetAgentReportDownload
} from '@app/services/agents';
import React, {useEffect, useState} from 'react';
import Pagination from '@app/components/common/Pagination';
import {Button, Col, Form} from 'react-bootstrap';
import {useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import {useNavigate} from 'react-router-dom';
import {handleInputChange} from '@app/utils/helpers';
import NoOfRecords from '@app/components/common/NoOfRecords';
import {RECORDS_PER_PAGE} from '@app/services/constants/config';

type Props = {};

function Reports({}: Props) {
  const token = useSelector((state: any) => state.auth.token);
  const navigate = useNavigate();

  const [listingData, setListingData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [numberOfRecords, setNumberOfRecords] = useState(RECORDS_PER_PAGE);

  // Perform array slicing based on the current page
  const startIndex = (pageNumber - 1) * numberOfRecords;
  const endIndex = pageNumber * numberOfRecords;
  const paginatedListingData = listingData?.slice(startIndex, endIndex);

  const [sortOrder, setSortOrder] = useState('asc');
  const [sortColumn, setSortColumn] = useState(null);

  // TODO FIX BUGS
  const sortTableData = (column: any) => {
    const sortedData = [...listingData];

    // *** NEW IMPLEMENTATION ***
    // If One column asc then next column also asc, if one dsc, then next also dsc..
    if (sortColumn === column) {
      sortedData.reverse();
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      if (sortOrder === 'asc' || sortOrder === null) {
        // sort aesc if the current order is asc or null
        sortedData.sort((a, b) => (a[column] < b[column] ? -1 : 1));
        setSortColumn(column);
        setSortOrder('asc');
      } else {
        sortedData.sort((a, b) => (a[column] > b[column] ? -1 : 1)); // sort desc if the current order is desc
        setSortColumn(column);
        setSortOrder('desc');
      }
    }

    setListingData(sortedData);
  };

  const fetchAgentRepots = () => {
    swaggerGetAgentReport(token)
      .then((res) => {
        const {data} = res;
        setListingData(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const downloadCSV = () => {
    swaggerGetAgentReportDownload(token)
      .then((res) => {
        const url = URL.createObjectURL(new Blob([res.data]));

        // Create a hidden anchor element
        const a = document.createElement('a');
        a.href = url;
        a.download = 'AgentReports.csv'; // Replace with the desired filename and extension
        a.click();

        // Release the temporary URL
        URL.revokeObjectURL(url);
      })
      .catch((err) => {
        // console.log(err)
        toast.error('Failed to download Agent Reports');
      });
  };

  const darkmode = useSelector((state: any) => state.account.darkmode);

  useEffect(() => {
    fetchAgentRepots();
  }, []);

  // Pagination Bugfix
  useEffect(() => {
    if (paginatedListingData?.length === 0) {
      setPageNumber(1);
    }
  }, [paginatedListingData]);

  return (
    <Container>
      <div className="container-fluid">
        <div className="row d-flex justify-content-center align-items-center">
          <Col xs={12}>
            <div className="mt-2 FilterButtonContainer col-lg-10 col-md-12 mx-auto pt-2 mb-2 d-flex justify-content-between">
              <h3 className="subHeaders"> </h3>
              <div className="d-flex flex-wrap align-items-center justify-content-end">
                {/* <button
                    type="button"
                    
                    className="m-1 filterButton"
                  >
                    <i className="fa-solid fa-filter" />
                    <span className="ml-2">Filter</span>
                  </button> */}
                <NoOfRecords
                  value={numberOfRecords}
                  setValue={(value) => setNumberOfRecords(value)}
                  totalRecords={listingData?.length}
                />

                {listingData?.length !== 0 && (
                  <div className="paginationContainer">
                    <Pagination
                      className="mr-1"
                      currentPage={pageNumber}
                      recordsPerPage={numberOfRecords}
                      totalRecords={listingData?.length}
                      onPageChange={(page: number) => setPageNumber(page)}
                    />
                  </div>
                )}
                {/* <button type="button" className="m-1 filterButton">
                  <i className="fa-solid fa-filter" />
                  <span className="ml-2">Filter</span>
                </button> */}

                <button
                  className="reportsBackButton"
                  onClick={() => {
                    {
                      navigate('/reports');
                    }
                  }}
                  id="me-back-btn"
                >
                  {' '}
                  <i className="fa-solid fa-left-long"></i>
                  <span>Back</span>
                </button>
                <button
                  type="button"
                  onClick={downloadCSV}
                  className="m-1 filterButton mainFilterButton"
                  id="me-download-btn"
                >
                  <i className="fa-solid fa-download"></i>
                  <span className="ml-2">Reports</span>
                </button>
              </div>
            </div>
            {/* <section className=" FilterOption col-lg-10 col-md-12 mx-auto mb-0 d-flex  flex-column">
                  <Form className="d-flex flex-wrap  justify-content-between">
                    <Form.Group
                      controlId="role"
                      className="col-lg-3 col-md-3 col-5 "
                    >
                      <Form.Label>Agent Id</Form.Label>
                      <Form.Control
                        type="search"
                        placeholder="Enter id"
                        value={''}
                        onChange={(e) => {
                        }}
                      />
                    </Form.Group>
                    <Form.Group
                      controlId="role"
                      className="col-lg-3 col-md-3 col-5 "
                    >
                      <Form.Label className='invisible'>Agent Id</Form.Label>
                      <Form.Control
                        type="search"
                        placeholder="Enter id"
                        value={''}
                        onChange={(e) => {
                        }}
                      />
                    </Form.Group>

                    <Form.Group
                      controlId="role"
                      className="col-lg-3 col-md-3 col-5"
                    >
                      <Form.Label className='invisible'>Alert Type</Form.Label>
                      <Form.Control
                        as="select"
                        value={''}
                        onChange={(e) => {
                         
                        }}
                      >
                        <option value="">--Select--</option>
                        
                      </Form.Control>
                    </Form.Group>

                    <Form.Group
                      controlId="role"
                      className="col-lg-3 col-md-3 col-5"
                    >
                      <Form.Label className='invisible'>Acknowledged</Form.Label>
                      <Form.Control
                        as="select"
                        value={''}
                        onChange={(e) => {
                      
                        }}
                      >
                        <option value="">--Select--</option>
                     
                      </Form.Control>
                    </Form.Group>
                  </Form>

                  <div className="d-flex justify-content-center mt-4 pl-1">
                    <button
                      type="button"
                      className=" pointer"
                    >
                      Apply
                    </button>
                    <button
                      type="button"
                      className="mx-5 pointer"
                      onClick={() => {
                      
                      }}
                    >
                      close
                    </button>
                    <button
                      type="button"
                      className="  pointer"
                      onClick={() => {
                      }}
                    >
                      Close
                    </button>
                  </div>
                </section> */}

            <div className="card-body col-lg-10 col-md-12 mx-auto px-0 pb-3">
              <div className="d-flex tableContainer overflow-auto flex-column">
                {!loading && paginatedListingData?.length > 0 && (
                  <table
                    className={`customTable ${darkmode ? 'darkTable' : ''}`}
                  >
                    <thead className="">
                      <tr>
                        <th
                          className="p-2 text-left col-2 pl-4"
                          onClick={() => {
                            // handleTableHeaderSorting('userId')
                            sortTableData('id');
                          }}
                        >
                          Endpoint ID{' '}
                          {sortColumn === 'id' &&
                            (sortOrder === 'asc' ? (
                              <i className="fa fa-sort-up" />
                            ) : (
                              <i className="fa fa-sort-down" />
                            ))}
                        </th>
                        <th
                          className="p-2 text-left col-2"
                          onClick={() => {
                            // handleTableHeaderSorting('userId')
                            sortTableData('ip');
                          }}
                        >
                          IP Address{' '}
                          {sortColumn === 'ip' &&
                            (sortOrder === 'asc' ? (
                              <i className="fa fa-sort-up" />
                            ) : (
                              <i className="fa fa-sort-down" />
                            ))}
                        </th>
                        <th
                          className="p-2 text-left col-2"
                          onClick={() => {
                            // handleTableHeaderSorting('userId')
                            sortTableData('osFamily');
                          }}
                        >
                          OS{' '}
                          {sortColumn === 'osFamily' &&
                            (sortOrder === 'asc' ? (
                              <i className="fa fa-sort-up" />
                            ) : (
                              <i className="fa fa-sort-down" />
                            ))}
                        </th>
                        <th
                          className="p-2 text-left col-2"
                          onClick={() => {
                            // handleTableHeaderSorting('userId')
                            sortTableData('version');
                          }}
                        >
                          Agent Version{' '}
                          {sortColumn === 'version' &&
                            (sortOrder === 'asc' ? (
                              <i className="fa fa-sort-up" />
                            ) : (
                              <i className="fa fa-sort-down" />
                            ))}
                        </th>
                        <th
                          className="text-left p-2 col-2"
                          onClick={() => {
                            // handleTableHeaderSorting('userId')
                            sortTableData('city');
                          }}
                        >
                          {' '}
                          Location{' '}
                          {sortColumn === 'city' &&
                            (sortOrder === 'asc' ? (
                              <i className="fa fa-sort-up" />
                            ) : (
                              <i className="fa fa-sort-down" />
                            ))}
                        </th>
                      </tr>
                    </thead>

                    <tbody className="">
                      {paginatedListingData?.map((data: any) => (
                        <tr key={data.id}>
                          <td className="p-2 text-left">{data.id}</td>
                          <td className="p-2 text-left">{data.ip}</td>

                          <td className="p-2 text-left">
                            {data.osFamily} {data.osVersion}
                          </td>
                          <td className="p-2 text-left">{data.version}</td>
                          <td className="p-2 text-left">{data.city}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
                {loading && (
                  <p className={` ${darkmode ? 'noRecordsDark' : 'noRecords'}`}>
                    Loading...
                  </p>
                )}
                {!loading && paginatedListingData.length === 0 && (
                  <p className={` ${darkmode ? 'noRecordsDark' : 'noRecords'}`}>
                    No Managed Endpoint Found
                  </p>
                )}
                {/* </div> */}
              </div>
            </div>
          </Col>
        </div>
      </div>
    </Container>
  );
}

export default Reports;

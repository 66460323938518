import Container from '@app/components/CustomDash/Container';
import {getRbacData} from '@app/services/swaggerAuth';
import {setRbacDetails} from '@app/store/reducers/auth';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {JsonTable} from 'react-json-to-html';
import PermissionModal from './PermissionModal';
import { getRbacDetailValue } from '@app/utils/helpers';

const RoleBasedAccessControlDetails = () => {
  const [activeTab, setActiveTab] = useState('appUsage');
  const [listingData, setListingData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showContact, setShowContact] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {rbacId} = useParams();

  const token = useSelector((state: any) => state.auth.token);

  const rbacDetails = useSelector((state: any) => state.auth.rbacDetails);
  const darkmode = useSelector((state: any) => state.account.darkmode);

  const permissionsData = JSON.parse(rbacDetails?.permissions);

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    getRbacData(token)
      .then((response) => {
        const {data} = response;
        for (let i = 0; i < data.length; i++) {
          if (data[i]?.id === rbacId) {
            dispatch(setRbacDetails(data[i]));
          }
        }
        setListingData(data);
        setLoading(false);
      })
      .catch((err) => {
        setListingData([]);
        setLoading(false);
      });
  }, []);

  return (
    <Container>
      <div>
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            <h3 className="subHeaders mx-5 "></h3>
          </div>

          <div className="overflow-auto ">
            {loading ? (
              <p
                className={`mt-0 mx-5 ${
                  darkmode ? 'noRecordsDark' : 'noRecords'
                }`}
              >
                Loading...
              </p>
            ) : (
              <div
                className={`customTable-2 mx-5 ${
                  darkmode && 'darkSecondaryWrapper'
                }`}
              >
                <div className="tab-content">
                  <div className="tab-table-container row">
                    <div className="col-6 p-4">
                      <div className="row my-3">
                        <div className="col-4 ml-4">
                          <span> Endpoint ID</span>
                        </div>
                        <div className="col-7">
                          <span>{rbacDetails?.agentId}</span>
                        </div>
                      </div>
                      <div className="row my-3">
                        <div className="col-4 ml-4">
                          <span>City</span>
                        </div>
                        <div className="col-7">
                          <span>{getRbacDetailValue(rbacDetails?.city)}</span>
                        </div>
                      </div>
                      <div className="row my-3">
                        <div className="col-4 ml-4">
                          <span>State</span>
                        </div>
                        <div className="col-7">
                          <span>{getRbacDetailValue(rbacDetails?.state)}</span>
                        </div>
                      </div>
                      <div className="row my-3">
                        <div className="col-4 ml-4">
                          <span>Postal Code</span>
                        </div>
                        <div className="col-7">
                          <span>{getRbacDetailValue(rbacDetails?.postalCode)}</span>
                        </div>
                      </div>
                      <div className="row my-3">
                        <div className="col-4 ml-4">
                          <span>Country</span>
                        </div>
                        <div className="col-7">
                          <span>{getRbacDetailValue(rbacDetails?.country)}</span>
                        </div>
                      </div>
                      <div className="row my-3">
                        <div className="col-4 ml-4">
                          <span>Country Code</span>
                        </div>
                        <div className="col-7">
                          <span>{getRbacDetailValue(rbacDetails?.countryCode)}</span>
                        </div>
                      </div>
                      <div className="row my-3">
                        <div className="col-4 ml-4">
                          <span>Company Name</span>
                        </div>
                        <div className="col-7">
                          <span>{getRbacDetailValue(rbacDetails?.company)}</span>
                        </div>
                      </div>
                      <div className="row my-3">
                        <div className="col-4 ml-4">
                          <span>Department</span>
                        </div>
                        <div className="col-7">
                          <span>{getRbacDetailValue(rbacDetails?.department)}</span>
                        </div>
                      </div>
                      <div className="row my-3">
                        <div className="col-4 ml-4">
                          <span>Description</span>
                        </div>
                        <div className="col-7">
                          <span>{getRbacDetailValue(rbacDetails?.description)}</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 p-4">
                      <div className="row my-3">
                        <div className="col-4">
                          <span>Employee ID</span>
                        </div>
                        <div className="col-7">
                          <span>{getRbacDetailValue(rbacDetails?.employeeId)}</span>
                        </div>
                      </div>
                      <div className="row my-3">
                        <div className="col-4">
                          <span>Employee Number</span>
                        </div>
                        <div className="col-7">
                          <span>{getRbacDetailValue(rbacDetails?.employeeNumber)}</span>
                        </div>
                      </div>
                      <div className="row my-3">
                        <div className="col-4">
                          <span>Employee Type</span>
                        </div>
                        <div className="col-7">
                          <span>{getRbacDetailValue(rbacDetails?.employeeType)}</span>
                        </div>
                      </div>
                      <div className="row my-3">
                        <div className="col-4">
                          <span>First Name</span>
                        </div>
                        <div className="col-7">
                          <span>{getRbacDetailValue(rbacDetails?.firstName)}</span>
                        </div>
                      </div>
                      <div className="row my-3">
                        <div className="col-4">
                          <span>Middle Initial</span>
                        </div>
                        <div className="col-7">
                          <span>{getRbacDetailValue(rbacDetails?.initial)}</span>
                        </div>
                      </div>
                      <div className="row my-3">
                        <div className="col-4">
                          <span>Last Name</span>
                        </div>
                        <div className="col-7">
                          <span>{getRbacDetailValue(rbacDetails?.lastName)}</span>
                        </div>
                      </div>
                      <div className="row my-3">
                        <div className="col-4">
                          <span>Phone No.</span>
                        </div>
                        <div className="col-7">
                          <span>{getRbacDetailValue(rbacDetails?.telephoneNumber)}</span>
                        </div>
                      </div>
                      <div className="row my-3">
                        <div
                          className={`col-4 ${
                            darkmode ? 'permissionsDark' : 'permissions'
                          }`}
                        >
                          <a href="#"  className="tooltipDiv">
                            <div
                             
                              onClick={() => setShowContact(true)}
                            >
                              {' '}
                              Permissions
                              <p className="tooltipText">
                                Click Here To View Permissions{' '}
                              </p>
                            </div>
                          </a>
                        </div>
                        {/* <div className='col-8'>
                      <div className="col-7">
                        <span>
                          {console.log(
                            'rbacDetails.country',
                            rbacDetails?.country
                          )}
                          {rbacDetails?.country === 'null' ||
                          !rbacDetails?.country
                            ? ''
                            : rbacDetails?.country}
                        </span>
                      </div>
                    </div>
                    <div className="row my-3">
                      <div className="col-4 ml-4">
                        <span>Country Code</span>
                      </div>
                      <div className="col-7">
                        <span>{rbacDetails?.countryCode}</span>
                      </div>
                    </div>
                    <div className="row my-3">
                      <div className="col-4 ml-4">
                        <span>Department</span>
                      </div>
                      <div className="col-7">
                        <span>{rbacDetails?.department}</span>
                      </div>
                    </div>
                    <div className="row my-3">
                      <div className="col-4 ml-4">
                        <span>Description</span>
                      </div>
                      <div className="col-7">
                        <span>{rbacDetails?.description}</span>
                      </div>
                    </div>
                    <div className="row my-3">
                      <div className="col-4 ml-4">
                        <span>Employee ID</span>
                      </div>
                      <div className="col-7">
                        <span>{rbacDetails?.employeeId}</span>
                      </div>
                    </div>
                    <div className="row my-3">
                      <div className="col-4 ml-4">
                        <span>Employee Number</span>
                      </div>
                      <div className="col-7">
                        <span>{rbacDetails?.employeeNumber}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 p-4">
                    <div className="row my-3">
                      <div className="col-4">
                        <span>Employee Type</span>
                      </div>
                      <div className="col-7">
                      <span>{rbacDetails?.employeeType === 'null' || 
                        !rbacDetails.employeeType ? '' : rbacDetails?.employeeType}</span>
                      </div>
                    </div>
                    <div className="row my-3">
                      <div className="col-4">
                        <span>First Name</span>
                      </div>
                      <div className="col-7">
                        <span>{rbacDetails?.firstName}</span>
                      </div>
                    </div>
                    <div className="row my-3">
                      <div className="col-4">
                        <span>Middle Initial</span>
                      </div>
                      <div className="col-7">
                        <span>{rbacDetails?.initial}</span>
                      </div>
                    </div>
                    <div className="row my-3">
                      <div className="col-4">
                        <span>Last Name</span>
                      </div>
                      <div className="col-7">
                        <span>{rbacDetails?.lastName}</span>
                      </div>
                    </div>
                    <div className="row my-3">
                      <div className="col-4">
                        <span>Postal Code</span>
                      </div>
                      <div className="col-7">
                        <span>{rbacDetails?.postalCode}</span>
                      </div>
                    </div>
                    <div className="row my-3">
                      <div className="col-4">
                        <span>Telephone No.</span>
                      </div>
                      <div className="col-7">
                        <span>{rbacDetails?.telephoneNumber}</span>
                      </div>
                    </div>
                    <div className="row my-3">
                      <div className="col-4">
                        <span>State</span>
                      </div>
                      <div className="col-7">
                        <span>{rbacDetails?.state}</span>
                      </div>
                    </div>
                    <div className='row my-3'>
                      <div className='col-4'>
                            <span>Permissions</span>
                      </div>
                      <div className='col-8'>
                        <div style={{maxHeight: '200px', overflow: 'auto', maxWidth: 'auto'}} id='rbac-detail-json-table'>
                        <JsonTable json={permissionsData}/>
                        </div>
                      </div> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-flex justify-content-end pr-4 pb-4">
                  <button
                    className="filterButton px-5"
                    onClick={() => {
                      navigate(-1)
                    }}
                    id="rbac-detail-back-btn"
                  >
                    Back
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {showContact && (
        <PermissionModal
          content={<JsonTable json={permissionsData} />}
          setClose={setShowContact}
        />
      )}
    </Container>
  );
};

export default RoleBasedAccessControlDetails;

import Container from '@app/components/CustomDash/Container';
import {ContentHeader} from '@app/components';
import {
  swaggerGetAgents,
  agentLocation,
  getAgentOsList
} from '@app/services/agents';
import React, {useEffect, useState} from 'react';
import {Button, Col, Form, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  setIsAdmin,
  setAllUserProfiles,
  setSelectedUser,
  setUserEditMode
} from '@app/store/reducers/auth';
import {
  setAgentsPageNumber,
  setAllAgentProfiles
} from '@app/store/reducers/agents';
import {formatDate, handleInputChange} from '@app/utils/helpers';
import {useNavigate} from 'react-router-dom';
import './Agents.css';
import ReactPaginate from 'react-paginate';
import InfoIcon from '@app/components/common/InfoIcon';
import Pagination from '@app/components/common/Pagination';
import NoOfRecords from '@app/components/common/NoOfRecords';
import {RECORDS_PER_PAGE} from '@app/services/constants/config';
import SearchDropDown from './SearchDropDown';
import {error} from 'console';

function AgentsNew() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onlineThresholdValueString: string | null = localStorage.getItem(
    'onlineThresholdValue'
  );
  const onlineThresholdValue: number = onlineThresholdValueString
    ? parseInt(onlineThresholdValueString)
    : 120;

  const [clearInput, setClearInput] = useState(false);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState(false);
  const [city, setCity] = useState('');
  const [agentId, setAgentId] = useState('');
  const [status, setStatus] = useState('');
  const [osType, setOsType] = useState('');
  const [osList, setList] = useState([]);
  const [numberOfRecords, setNumberOfRecords] = useState(RECORDS_PER_PAGE);

  const token = useSelector((state: any) => state.auth.token);
  const isLoggedIn = useSelector((state: any) => state.auth.isLoggedIn);
  const isAdmin = useSelector((state: any) => state.auth.isAdmin);

  const allAgentProfiles = useSelector(
    (state: any) => state.agents.allAgentProfiles
  );
  const agentsPageNumber = useSelector(
    (state: any) => state.agents.agentsPageNumber
  );

  // Perform array slicing based on the current page
  const startIndex = (agentsPageNumber - 1) * numberOfRecords;
  const endIndex = agentsPageNumber * numberOfRecords;
  const agentsPaginatedData = allAgentProfiles?.slice(startIndex, endIndex);
  const darkmode = useSelector((state: any) => state.account.darkmode);

  /**
   * Table Sorting
   * -------------
   */
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortColumn, setSortColumn] = useState(null);

  // TODO FIX BUGS
  const sortTableData = (column: any) => {
    const sortedData = [...allAgentProfiles];

    // *** NEW IMPLEMENTATION ***
    // If One column asc then next column also asc, if one dsc, then next also dsc..
    if (sortColumn === column) {
      sortedData.reverse();
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      if (sortOrder === 'asc' || sortOrder === null) {
        // sort aesc if the current order is asc or null
        sortedData.sort((a, b) => (a[column] < b[column] ? -1 : 1));
        setSortColumn(column);
        setSortOrder('asc');
      } else {
        sortedData.sort((a, b) => (a[column] > b[column] ? -1 : 1)); // sort desc if the current order is desc
        setSortColumn(column);
        setSortOrder('desc');
      }
    }

    dispatch(setAllAgentProfiles(sortedData));
  };

  const fetchInitialData = () => {
    const cachedData = localStorage.getItem('cachedAgentsData');
    if (cachedData) {
      dispatch(setAllAgentProfiles(JSON.parse(cachedData)));
      setLoading(false);
    } else {
      fetchAgentsList(); // Fetch data if not cached
    }
  };

  const fetchAgentsList = () => {
    agentLocation(token)
      .then((response) => {
        const {data} = response;
        dispatch(setAllAgentProfiles(data));
        setLoading(false);
        localStorage.setItem('cachedAgentsData', JSON.stringify(data));
      })
      .catch((err) => {
        dispatch(setAllAgentProfiles([]));
        setLoading(false);
      });
  };

  const fetchFilteredAgents = (token: string) => {
    swaggerGetAgents(token, city, agentId, status, osType)
      .then((response) => {
        const {data} = response;
        dispatch(setAllAgentProfiles(data));
        setLoading(false);
      })
      .catch((err) => {
        dispatch(setAllAgentProfiles([]));
        setLoading(false);
      });
  };

  const fetchOsList = async () => {
    try {
      const response = await getAgentOsList(token);
      setList(response.data);
    } catch (error) {
      console.error('Error fetching OS list:', error);
    }
  };

  const isOnline = (timestamp: any) => {
    const currentTime = new Date().getTime();
    const timeDifference = currentTime - timestamp;
    return timeDifference <= 60 * 1000 * onlineThresholdValue;
  };

  useEffect(() => {
    if (isLoggedIn && token) {
      fetchInitialData();
    }
  }, [isLoggedIn, token]);
  useEffect(() => {
    fetchFilteredAgents(token);
    fetchOsList();
  }, []);

  useEffect(() => {
    if (agentsPaginatedData?.length === 0) {
      dispatch(setAgentsPageNumber(1));
    }
  }, [agentsPaginatedData]);

  const handleSelect = (id: string) => {
    setAgentId(id);
  };

  const handleClear = () => {
    setClearInput(true);
  };

  const handleClearComplete = () => {
    setClearInput(false);
  };

  const renderAgentProfiles = agentsPaginatedData?.map((user: any) => (
    <tr
      key={user.id}
      // User Form Modal @ Bottom
      className=""
      style={{cursor: 'pointer'}}
    >
      <td
        className="tooltipDiv text-left pl-4"
        onClick={() => {
          navigate(`/agents/agent-details/${user.id}`);
        }}
      >
        {user.alias !== 'null' && user.alias}
        {(!user.alias || user.alias === 'null') &&
          user.id.slice(0, 16).toUpperCase()}{' '}
        {!user.alias && user.id.length > 16 ? '...' : ''}{' '}
        <p className="tooltipText">{user.id.toUpperCase()}</p>
      </td>
      <td
        className=" text-left p-2"
        onClick={() => {
          navigate(`/agents/agent-details/${user.id}`);
        }}
      >
        {user.city}
      </td>
      <td
        className=" text-left p-2"
        onClick={() => {
          navigate(`/agents/agent-details/${user.id}`);
        }}
      >
        {user.ip}
      </td>
      <td
        className=" text-left p-2"
        onClick={() => {
          navigate(`/agents/agent-details/${user.id}`);
        }}
      >
        {user.sessionUsername}
      </td>
      <td
        className=" text-left p-2"
        onClick={() => {
          navigate(`/agents/agent-details/${user.id}`);
        }}
      >
        {formatDate(user.lastSync)}
      </td>
      <td
        className=" text-left p-2"
        onClick={() => {
          navigate(`/agents/agent-details/${user.id}`);
        }}
      >
        {user.osFamily}
      </td>
      <td
        className=" text-left p-2"
        onClick={() => {
          navigate(`/agents/agent-details/${user.id}`);
        }}
      >
        {user.osVersion}
      </td>

      <td
        className=" text-center p-2"
        onClick={() => {
          navigate(`/agents/agent-details/${user.id}`);
        }}
      >
        {user.active ? (
          <i className="fa-regular fa-circle-check" />
        ) : (
          <i className=" fa-sharp fa-regular fa-circle-xmark" />
        )}
      </td>
      <td
        className=" text-center p-2"
        onClick={() => {
          navigate(`/agents/agent-details/${user.id}`);
        }}
      >
        {isOnline(user.lastSync) ? (
          <i className="fa-regular fa-circle-check" />
        ) : (
          <i className=" fa-sharp fa-regular fa-circle-xmark" />
        )}
      </td>
      <td
        className="text-center p-2 col-2 pr-4"
        onClick={() => {
          navigate(`/agents/agent-details/${user.id}`);
        }}
      >
        <i id="open-me-arrow" className="fa-solid fa-chevron-right" />
      </td>
      <td className="text-center p-2 col-2 pr-4">
        <OverlayTrigger
          overlay={<Tooltip id=""> Open in new tab</Tooltip>}
          placement="top"
          delay={200}
        >
          <a
            href={`/agents/agent-details/${user.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <i
              id="me-in-diff-window"
              className="fa-regular fa-window-restore"
            ></i>
          </a>
        </OverlayTrigger>
      </td>
    </tr>
  ));
  return (
    <Container>
      <div>
        {/* <ContentHeader title="Agents" /> */}

        <div className="container-fluid">
          <div className="row d-flex justify-content-center align-items-center">
            <Col xs={12}>
              <div>
                <div className="mt-2 FilterButtonContainer col-lg-10 col-md-12 mx-auto pt-2 mb-2 d-flex justify-content-between">
                  <h3 className="subHeaders"> </h3>
                  <div className="d-flex flex-wrap align-items-center justify-content-end col-10">
                    <NoOfRecords
                      value={numberOfRecords}
                      setValue={(value) => setNumberOfRecords(value)}
                      totalRecords={allAgentProfiles?.length}
                    />

                    {allAgentProfiles?.length !== 0 && (
                      <div className="paginationContainer">
                        <Pagination
                          className="mr-1"
                          currentPage={agentsPageNumber}
                          recordsPerPage={numberOfRecords}
                          totalRecords={allAgentProfiles?.length}
                          onPageChange={(page: number) =>
                            dispatch(setAgentsPageNumber(page))
                          }
                        />
                      </div>
                    )}

                    <button
                      type="button"
                      onClick={() => {
                        setFilter(!filter);
                      }}
                      className="m-1 filterButton mainFilterButton"
                      id="me-filter-btn"
                    >
                      <i className="fa-solid fa-filter" />
                      <span className="ml-2">Filter</span>
                    </button>
                  </div>

               
                </div>

                {filter && (
                  <section
                    className={`FilterOption ${
                      darkmode && 'darkSecondaryWrapper'
                    } col-lg-10 col-md-12 mx-auto mb-0 d-flex  flex-column`}
                  >
                    <Form className="d-flex flex-wrap  justify-content-between">
                      <Form.Group
                        controlId="role"
                        className="col-xl-3 col-lg-4 col-md-5 col-12"
                      >
                        <Form.Label>EndpointID/ Hostname/Alias </Form.Label>

                        <SearchDropDown
                          data={allAgentProfiles}
                          onSelect={handleSelect}
                          clearInput={clearInput}
                          onClearComplete={handleClearComplete}
                          isManagedEndPointPage={true}
                        />
                      </Form.Group>
                      <Form.Group
                        controlId="role"
                        className="col-xl-3 col-lg-4 col-md-5 col-12"
                      >
                        <Form.Label>City</Form.Label>
                        <Form.Control
                          type="search"
                          placeholder="Enter city"
                          value={city}
                          onChange={(e) => {
                            setCity(e.target.value);
                          }}
                          id="me-city-option"
                        />
                      </Form.Group>


                      <Form.Group
                        controlId="role"
                        className="col-xl-2 col-lg-3 col-md-5 col-12"
                      >
                        <Form.Label>OS Type</Form.Label>
                        <Form.Control
                          as="select"
                          value={osType}
                          onChange={(e) => {
                            setOsType(e.target.value);
                          }}
                          id="me-os-type-option"
                        >
                          <option value="">--Select--</option>
                          {osList.map((i) => (
                            <option value={i}>{i}</option>
                          ))}
                        </Form.Control>
                      </Form.Group>

                      <Form.Group
                        controlId="role"
                        className="col-xl-2 col-lg-3 col-md-5 col-12"
                      >
                        <Form.Label> Endpoint Status</Form.Label>
                        <Form.Control
                          as="select"
                          value={status}
                          onChange={(e) => {
                            setStatus(e.target.value);
                          }}
                          id="me-network-status-option"
                        >
                          <option value="">All</option>
                          <option value="Active">Active</option>
                          <option value="Inactive">Inactive</option>
                        </Form.Control>
                      </Form.Group>
                    </Form>

                    <div className="d-flex flex-wrap justify-content-center mt-4 pl-1">
                      <button
                        type="button"
                        className="m-1 pointer alertFilterButton"
                        onClick={() => {
                          fetchFilteredAgents(token);
                        }}
                        id="me-apply-btn"
                      >
                        Apply
                      </button>
                      <button
                        type="button"
                        className="m-1 pointer alertFilterButton"
                        onClick={() => {
                          setAgentId('');
                          setCity('');
                          setStatus('');
                          setOsType('');
                          handleClear();
                          setTimeout(() => {
                            fetchAgentsList();
                          }, 100);
                        }}
                        id="me-clear-btn"
                      >
                        Clear
                      </button>
                      <button
                        type="button"
                        className="Close m-1 pointer alertFilterButton"
                        onClick={() => {
                          setFilter(false);
                        }}
                        id="me-close-btn"
                      >
                        Close
                      </button>
                    </div>
                  </section>
                )}
                {allAgentProfiles.length !== 0 && (
                  <div className="mobilePaginationContainer">
                    <Pagination
                      className="mr-1"
                      currentPage={agentsPageNumber}
                      recordsPerPage={numberOfRecords}
                      totalRecords={allAgentProfiles?.length}
                      onPageChange={(page: number) =>
                        dispatch(setAgentsPageNumber(page))
                      }
                    />
                  </div>
                )}

                <div className="card-body col-lg-10 col-md-12 mx-auto px-0 pb-3">
                  <div className="d-flex tableContainer overflow-auto flex-column">
                    {/* <div className="rounded"> */}
                    <table
                      className={`customTable ${darkmode ? 'darkTable' : ''}`}
                    >
                      <thead className="">
                        <tr>
                          <th
                            className="p-2 col-2 text-left pl-4"
                            onClick={() => {
                              // handleTableHeaderSorting('userId')
                              sortTableData('id');
                            }}
                          >
                            EndpointID/ Hostname/Alias
                            {sortColumn === 'id' &&
                              (sortOrder === 'asc' ? (
                                <i className="fa fa-sort-up" />
                              ) : (
                                <i className="fa fa-sort-down" />
                              ))}
                          </th>
                          <th
                            className="p-2 col-2 text-left"
                            onClick={() => {
                              // handleTableHeaderSorting('userId')
                              sortTableData('city');
                            }}
                          >
                            City{' '}
                            {sortColumn === 'city' &&
                              (sortOrder === 'asc' ? (
                                <i className="fa fa-sort-up" />
                              ) : (
                                <i className="fa fa-sort-down" />
                              ))}
                          </th>
                          <th
                            className="p-2 col-2 text-left"
                            onClick={() => {
                              // handleTableHeaderSorting('userId')
                              sortTableData('ip');
                            }}
                          >
                            IP Address{' '}
                            {sortColumn === 'ip' &&
                              (sortOrder === 'asc' ? (
                                <i className="fa fa-sort-up" />
                              ) : (
                                <i className="fa fa-sort-down" />
                              ))}
                          </th>
                          <th
                            className="p-2 col-1 text-left"
                            onClick={() => {
                              // handleTableHeaderSorting('userId')
                              sortTableData('sessionUsername');
                            }}
                          >
                            Username
                            {sortColumn === 'sessionUsername' &&
                              (sortOrder === 'asc' ? (
                                <i className="fa fa-sort-up" />
                              ) : (
                                <i className="fa fa-sort-down" />
                              ))}
                          </th>
                          <th
                            className="p-2 col-2 text-left"
                            onClick={() => {
                              // handleTableHeaderSorting('userId')
                              sortTableData('lastSync');
                            }}
                          >
                            Last Synced{' '}
                            {sortColumn === 'lastSync' &&
                              (sortOrder === 'asc' ? (
                                <i className="fa fa-sort-up" />
                              ) : (
                                <i className="fa fa-sort-down" />
                              ))}
                          </th>
                          <th
                            className="p-2 col-2 text-left"
                            onClick={() => {
                              // handleTableHeaderSorting('userId')
                              sortTableData('osFamily');
                            }}
                          >
                            Operating System{' '}
                            {sortColumn === 'osFamily' &&
                              (sortOrder === 'asc' ? (
                                <i className="fa fa-sort-up" />
                              ) : (
                                <i className="fa fa-sort-down" />
                              ))}
                          </th>
                          <th
                            className="p-2 col-2 text-left"
                            onClick={() => {
                              // handleTableHeaderSorting('userId')
                              sortTableData('osVersion');
                            }}
                          >
                            Version{' '}
                            {sortColumn === 'osVersion' &&
                              (sortOrder === 'asc' ? (
                                <i className="fa fa-sort-up" />
                              ) : (
                                <i className="fa fa-sort-down" />
                              ))}
                          </th>
                          <th
                            className="p-2 col-1 text-left"
                            onClick={() => {
                              // handleTableHeaderSorting('userId')
                              sortTableData('active');
                            }}
                          >
                            Active{' '}
                            {sortColumn === 'active' &&
                              (sortOrder === 'asc' ? (
                                <i className="fa fa-sort-up" />
                              ) : (
                                <i className="fa fa-sort-down" />
                              ))}
                          </th>
                          <th
                            className="p-2 col-1 text-left"
                            onClick={() => {
                              // handleTableHeaderSorting('userId')
                              sortTableData('active');
                            }}
                          >
                            Status{' '}
                            {sortColumn === 'active' &&
                              (sortOrder === 'asc' ? (
                                <i className="fa fa-sort-up" />
                              ) : (
                                <i className="fa fa-sort-down" />
                              ))}
                          </th>
                          <th className="text-center p-2 col"> </th>
                          <th className="text-center p-2 col"> </th>
                        </tr>
                      </thead>
                      {!loading && agentsPaginatedData.length > 0 && (
                        <tbody className="">{renderAgentProfiles}</tbody>
                      )}
                    </table>
                    {/* </div> */}
                    {loading && (
                      <p
                        className={`d-flex justify-content-center align-items-center ${
                          darkmode ? 'noRecordsDark' : 'noRecords'
                        }`}
                        style={{height: '80px'}}
                      >
                        Loading...
                      </p>
                    )}
                    {!loading && agentsPaginatedData.length === 0 && (
                      <p
                        className={`d-flex justify-content-center align-items-center ${
                          darkmode ? 'noRecordsDark' : 'noRecords'
                        }`}
                        style={{height: '80px'}}
                      >
                        No Managed Endpoint Configured..
                      </p>
                    )}
                    {!loading && agentsPaginatedData === null && (
                      <p
                        className={`d-flex justify-content-center align-items-center ${
                          darkmode ? 'noRecordsDark' : 'noRecords'
                        }`}
                        style={{height: '80px'}}
                      >
                        Network Error...
                      </p>
                    )}
                    {/* <ReactPaginate
                      breakLabel="..."
                      nextLabel="Next >"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={pageCount}
                      previousLabel="< Previous"
                      renderOnZeroPageCount={null}
                      containerClassName={'pagination'}
                      pageLinkClassName={'page-num'}
                      previousLinkClassName={'page-num'}
                      nextLinkClassName={'page-num'}
                      activeLinkClassName={'active'}
                    /> */}
                  </div>
                </div>
              </div>
            </Col>
          </div>
        </div>

        {/* <UserFormModal
      show={showUserForm}
      onHide={() => setShowUserForm(false)}
    /> */}
      </div>
    </Container>
  );
}

export default AgentsNew;

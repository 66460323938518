import {ContentHeader} from '@app/components';
import Container from '@app/components/CustomDash/Container';
import InfoIcon from '@app/components/common/InfoIcon';
import Pagination from '@app/components/common/Pagination';
import {
  getAlertTypes,
  getAlertsCount,
  swaggerGetAlertAck,
  swaggerGetAlertList
} from '@app/services/alerts';
import React, {useEffect, useRef, useState} from 'react';
import {Button, Col, Form, OverlayTrigger, Tooltip} from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import {
  addTimeToTimestamp,
  convertInputDateToUTCAndMilliseconds,
  convertUTCToMilliseconds,
  convertUTCToMillisecondsWithTime,
  dateToMilliseconds,
  formatDate,
  formatDatetimeToDate,
  formatPayloadDate
} from '@app/utils/helpers';
import {getAgentEndpointIds} from '@app/services/agents';
import {RECORDS_PER_PAGE} from '@app/services/constants/config';
import NoOfRecords from '@app/components/common/NoOfRecords';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import useOutsideClick from '@app/hooks/useOutsideClick';
import SearchDropDown from '../agents/SearchDropDown';

// type Props = {};

function Alert() {
  const [loading, setLoading] = useState(true);
  const [alertLists, setAlertList] = useState<any>(null);
  const [agentsList, setAgentsList] = useState([]);
  const [filter, setFilter] = useState(false);
  const [alertsPageNumber, setAlertsPageNumber] = useState(1);
  const [roles, setRoles] = useState<any>([]);
  const [numberOfRecords, setNumberOfRecords] = useState(RECORDS_PER_PAGE);

  const [agentId, setAgentId] = useState('');
  const [type, setType] = useState('');
  const [acknowledge, setAcknowledge] = useState<any>(false);
  const [dateStart, setDateStart] = useState('');
  const [dateEnd, setDateEnd] = useState('');
  const [dateRange, setDateRange] = useState('');
  const [showDateStart, setShowDateStart] = useState(false);
  const [showDateEnd, setShowDateEnd] = useState(false);
  const [calendarDateStart, setCalendarDateStart] = useState<any>(new Date());
  const [calendarDateEnd, setCalendarDateEnd] = useState<any>(new Date());
  const [clearInput, setClearInput] = useState(false);

  const dateStartCalendarRef = useRef(null);
  const dateEndCalendarRef = useRef(null);

  const navigate = useNavigate();

  const token = useSelector((state: any) => state.auth.token);
  const darkmode = useSelector((state: any) => state.account.darkmode);

  // Perform array slicing based on the current page
  const startIndex = (alertsPageNumber - 1) * numberOfRecords;
  const endIndex = alertsPageNumber * numberOfRecords;
  const alertsPaginatedData = alertLists?.slice(startIndex, endIndex);

  /**
   * Table Sorting
   * -------------
   */
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortColumn, setSortColumn] = useState(null);

  const [alertsCount, setAlertsCount] = useState(100);
  const [emptyEndDate, setEmptyEndDate] = useState(false);


  const sortTableData = (column: any) => {
    const sortedData = [...alertLists];

    // *** NEW IMPLEMENTATION ***
    // If One column asc then next column also asc, if one dsc, then next also dsc..
    if (sortColumn === column) {
      sortedData.reverse();
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else if (sortOrder === 'asc' || sortOrder === null) {
      // sort aesc if the current order is asc or null
      sortedData.sort((a, b) => (a[column] < b[column] ? -1 : 1));
      setSortColumn(column);
      setSortOrder('asc');
    } else {
      sortedData.sort((a, b) => (a[column] > b[column] ? -1 : 1)); // sort desc if the current order is desc
      setSortColumn(column);
      setSortOrder('desc');
    }

    setAlertList(sortedData);
  };

  // const handleClose = () => {
  //   setAgentId('');
  //   setType('');
  //   setAcknowledge(false);
  //   setTimeout(() => {
  //     fetchAlerts();
  //   }, 100);
  // };

  const fetchInitialData = () => {
    const cachedData = localStorage.getItem('cachedAlertData');
    if (cachedData) {
      setAlertList(JSON.parse(cachedData));
      setLoading(false);
    } else {
      fetchAlerts();
    }
  };

  console.log(dateStart, dateEnd);

  const fetchAlerts = (clear = false) => {
    let tempAgentId = agentId;
    let tempType = type;
    let tempAcknowledge = acknowledge;
    let tempDateStart:any = dateStart
      ? convertUTCToMilliseconds(dateStart)
      : dateStart;
    let tempDateEnd:any = dateEnd
      ? convertUTCToMillisecondsWithTime(dateEnd)
      : dateEnd;

    let count = alertsCount;
    if (clear) {
      tempAgentId = '';
      tempType = '';
      tempAcknowledge = false;
      tempDateStart = '';
      tempDateEnd = '';
      count = 100;
    }
    setLoading(true);
    swaggerGetAlertList(
      token,
      tempAgentId,
      tempType,
      tempAcknowledge,
      tempDateStart,
      tempDateEnd,
      count
    )
      .then((res) => {
        setAlertList(res.data);
        setLoading(false);
        localStorage.setItem('cachedAlertData', JSON.stringify(res.data));
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const fetchAlertsCount = (clear = false) => {
    let tempAgentId = agentId;
    let tempType = type;
    let tempAcknowledge = acknowledge;
    let tempDateStart = dateStart
      ? convertUTCToMilliseconds(dateStart).toString()
      : dateStart;
    let tempDateEnd = dateEnd
      ? convertUTCToMilliseconds(dateEnd).toString()
      : dateEnd;
    if (clear) {
      tempAgentId = '';
      tempType = '';
      tempAcknowledge = false;
      tempDateStart = '';
      tempDateEnd = '';
    }
    getAlertsCount(
      token,
      tempAgentId,
      tempType,
      tempAcknowledge,
      tempDateStart,
      tempDateEnd
    )
      .then((response) => {
        setAlertsCount(response.data);
      })
      .catch(() => {});
  };
  const getAllAgents = () => {
    const cachedData = localStorage.getItem('cachedEndpoints');

    if (cachedData) {
      setAgentsList(JSON.parse(cachedData));
    } else {
      getAgentEndpointIds(token)
        .then((response) => {
          const {data} = response;
          setAgentsList(data);
          localStorage.setItem('cachedEndpoints', JSON.stringify(data));
        })
        .catch((err) => {
          setAgentsList([]);
        });
    }
  };

  const handleDateFilter = (value: any) => {
    const currentDate = new Date();
    let newDateStart = currentDate;
    let newDateEnd = currentDate;

    switch (value) {
      case 'today': {
        newDateStart = currentDate;
        newDateEnd = currentDate;
        break;
      }
      case 'yesterday': {
        const yesterday = new Date(currentDate);
        yesterday.setDate(currentDate.getDate() - 1);
        newDateStart = yesterday;
        newDateEnd = yesterday;
        break;
      }
      case 'last3Days': {
        const last3Days = new Date(currentDate);
        last3Days.setDate(currentDate.getDate() - 2);
        newDateStart = last3Days;
        newDateEnd = currentDate;
        break;
      }
      case 'thisWeek': {
        const firstDayOfWeek = new Date(
          currentDate.setDate(currentDate.getDate() - currentDate.getDay())
        );
        const lastDayOfWeek = new Date(
          currentDate.setDate(firstDayOfWeek.getDate() + 6)
        );
        newDateStart = firstDayOfWeek;
        newDateEnd = lastDayOfWeek;
        break;
      }
      case 'thisMonth': {
        const firstDayOfMonth = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        );
        const lastDayOfMonth = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          0
        );
        newDateStart = firstDayOfMonth;
        newDateEnd = lastDayOfMonth;
        break;
      }
      default:
        break;
    }

    setDateStart(formatDatetimeToDate(newDateStart));
    setCalendarDateStart(newDateStart);
    setDateEnd(formatDatetimeToDate(newDateEnd));
    setCalendarDateEnd(newDateEnd);
  };



  useOutsideClick(dateStartCalendarRef, 'customDateRangeCalendar', () =>
    setShowDateStart(false)
  );
  useOutsideClick(dateEndCalendarRef, 'customDateRangeCalendar', () =>
    setShowDateEnd(false)
  );

  useEffect(() => {
    getAlertTypes(token).then((res) => {
      setRoles(Object.entries(res.data).map(([key, value]) => ({key, value})));
    });
    getAllAgents();
    fetchAlertsCount();
  }, []);

  useEffect(() => {
    if (token) {
      fetchInitialData();
    }
  }, []);

  useEffect(() => {
    if (alertsPaginatedData?.length === 0) {
      setAlertsPageNumber(1);
    }
  }, [alertsPaginatedData]);

  useEffect(() => {
    if (!dateRange) {
      setDateStart('');
      setDateEnd('');
      setCalendarDateStart('');
      setCalendarDateEnd('');
    } else {
      handleDateFilter(dateRange);
    }
  }, [dateRange]);

  useEffect(() => {
    if (!alertsCount) {
      
      fetchAlerts();
    }
  }, [alertsCount]);

  const renderAlertLists = alertsPaginatedData?.map((alert: any) => (
    <tr key={alert.id} className="">
      {/* <td className="text-left pl-4">{alert.agentId}</td> */}
      <td className=" tooltipDiv text-left pl-4" aria-hidden="true">
        <a
          className="agentUnderline"
          href={`/agents/agent-details/${alert.agentId}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {' '}
          {alert.agentId.slice(0, 16).toUpperCase()}
        </a>
        {alert.agentId.length > 16 ? '...' : ''}{' '}
        <p className="tooltipText">{alert.agentId.toUpperCase()}</p>
      </td>
      <td
        className="text-left p-2"
        onClick={() => {
          navigate(`/alerts/alert-details/${alert.id}`);
        }}
        aria-hidden="true"
      >
        {alert.type}
      </td>
      <td
        className="text-left p-2"
        onClick={() => {
          navigate(`/alerts/alert-details/${alert.id}`);
        }}
        aria-hidden="true"
      >
        {alert.summary}
      </td>
      <td
        className="text-left"
        onClick={() => {
          navigate(`/alerts/alert-details/${alert.id}`);
        }}
        aria-hidden="true"
      >
        {formatDate(alert.createdAt)}
      </td>
      <td
        className="text-left p-2 col-1 pl-4"
        onClick={() => {
          navigate(`/alerts/alert-details/${alert.id}`);
        }}
        aria-hidden="true"
      >
        <i id="alerts-right-arrow" className="fa-solid fa-chevron-right" />
      </td>
      <td className="text-left p-2 col-1 pr-4 ">
        <OverlayTrigger
          overlay={<Tooltip id=""> Open in new tab</Tooltip>}
          placement="top"
          delay={200}
        >
          <a
            href={`/alerts/alert-details/${alert.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <i
              id="alerts-diff-window"
              className="fa-regular fa-window-restore"
            />
          </a>
        </OverlayTrigger>
      </td>
    </tr>
  ));

  const handleSelect = (id: string) => {
    setAgentId(id);
  };

  const handleClear = () => {
    setClearInput(true);
  };

  const handleClearComplete = () => {
    setClearInput(false);
  };

  return (
    <Container>
      <div>
        <div className="container-fluid">
          <div className="row d-flex justify-content-center align-items-center">
            <Col xs={12}>
              <div className="mt-2 FilterButtonContainer col-lg-10 col-md-12 mx-auto pt-2 mb-2 d-flex justify-content-between">
                <h3 className="subHeaders"> </h3>
                <div className="d-flex flex-wrap align-items-center justify-content-end">
                  <NoOfRecords
                    value={numberOfRecords}
                    setValue={(value) => setNumberOfRecords(value)}
                    totalRecords={alertLists?.length}
                  />

                  {alertLists?.length !== 0 && (
                    <div className="paginationContainer">
                      <Pagination
                        className="mr-1"
                        currentPage={alertsPageNumber}
                        recordsPerPage={numberOfRecords}
                        totalRecords={alertsCount}
                        onPageChange={(page: number) =>
                          setAlertsPageNumber(page)
                        }
                      />
                    </div>
                  )}

                  <button
                    type="button"
                    onClick={() => {
                      setFilter(!filter);
                      setDateStart(''); // Reset to empty string
                      setDateEnd(''); // Reset to empty string
                    }}
                    className="m-1 filterButton mainFilterButton"
                    id="alerts-filter-btn"
                  >
                    <i className="fa-solid fa-filter" />
                    <span className="ml-2">Filter</span>
                  </button>
                </div>
              </div>

              {filter && (
                <section
                  className={`FilterOption  ${
                    darkmode && 'darkSecondaryWrapper'
                  } col-lg-10 col-md-12 mx-auto mb-0 d-flex  flex-column`}
                >
                  <Form className="d-flex flex-wrap  justify-content-between">
                    <Form.Group
                      controlId="role"
                      className="col-xl-3 col-lg-4 col-sm-5 col-12 "
                    >
                      <Form.Label> Endpoint ID</Form.Label>
                      {/* <Form.Control
                        as="select"
                        value={agentId}
                        onChange={(e) => {
                          setAgentId(e.target.value);
                        }}
                        id='alerts-endpoint-id-option'
                      >
                        <option value="">--Select--</option>
                        {agentsList.map((agent: any) => (
                          <option value={agent}>{agent}</option>
                        ))}
                      </Form.Control> */}

                      <SearchDropDown
                        data={agentsList}
                        onSelect={handleSelect}
                        clearInput={clearInput}
                        onClearComplete={handleClearComplete}
                        isManagedEndPointPage={false}
                      />
                    </Form.Group>

                    <Form.Group
                      controlId="role"
                      className="col-xl-2 col-lg-4 col-sm-5 col-12 position-relative"
                    >
                      <Form.Label>Start Date</Form.Label>
                      <div className="position-relative">
                        <Form.Control
                          type="date"
                          value={dateStart}
                          onChange={(e) => {
                            setDateStart(e.target.value);
                            setCalendarDateStart(new Date(e.target.value));
                          }}
                          id="alerts-start-date-option"
                        />
                        <span
                          className="customCalenderPickerIcon"
                          onClick={() => setShowDateStart((state) => !state)}
                          aria-hidden
                          id="alerts-start-calendar"
                        />
                      </div>
                      {showDateStart && (
                        <div
                          className="px-0 d-flex flex-shrink-0 position-absolute customDateRangeCalendar no-select"
                          style={{width: '308px'}}
                          ref={dateStartCalendarRef}
                        >
                          <div className="dateRangeCard w-100 d-flex flex-column align-items-center justify-content-between pb-0">
                            <Form.Control
                              as="select"
                              value={dateRange}
                              onChange={(e) => setDateRange(e.target.value)}
                            >
                              {/* <option value="">--Select--</option> */}
                              <option value="today">Today</option>
                              <option value="yesterday">Yestereday</option>
                              <option value="last3Days">Last 3 Days</option>
                              <option value="thisWeek">This Week</option>
                              <option value="thisMonth">This Month</option>
                            </Form.Control>
                            <div className="mt-3">
                              <Calendar
                                value={calendarDateStart}
                                onChange={(value: any) => {
                                  setCalendarDateStart(value);
                                  setDateStart(formatDatetimeToDate(value));
                                  setShowDateStart(false);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </Form.Group>
                    <Form.Group
                      controlId="role"
                      className="col-xl-2 col-lg-4 col-sm-5 col-12"
                    >
                      <Form.Label>End Date</Form.Label>
                      <div className="position-relative">
                        <Form.Control
                          type="date"
                          value={dateEnd}
                          onChange={(e) => {
                            setDateEnd(e.target.value);
                            setCalendarDateEnd(new Date(e.target.value));
                          }}
                          id="alerts-end-date-option"
                        />
                        <span
                          className="customCalenderPickerIcon"
                          onClick={() => setShowDateEnd((state) => !state)}
                          aria-hidden
                          id="alerts-end-calendar"
                        />
                      </div>
                      {showDateEnd && (
                        <div
                          className="px-0 d-flex flex-shrink-0 position-absolute customDateRangeCalendar no-select"
                          style={{width: '308px'}}
                          ref={dateEndCalendarRef}
                        >
                          <div className="dateRangeCard w-100 d-flex flex-column align-items-center justify-content-between pb-0">
                            <Form.Control
                              as="select"
                              value={dateRange}
                              onChange={(e) => setDateRange(e.target.value)}
                            >
                              {/* <option value="">--Select--</option> */}
                              <option value="today">Today</option>
                              <option value="yesterday">Yestereday</option>
                              <option value="last3Days">Last 3 Days</option>
                              <option value="thisWeek">This Week</option>
                              <option value="thisMonth">This Month</option>
                            </Form.Control>
                            <div className="mt-3">
                              <Calendar
                                value={calendarDateEnd}
                                onChange={(value: any) => {
                                  setCalendarDateEnd(value);
                                  setDateEnd(formatDatetimeToDate(value));
                                  setShowDateEnd(false);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      {dateStart && !dateEnd ? (
                        <p className="text-danger pl-3">Enter End Date</p>
                      ) : (
                        ''
                      )}
                    </Form.Group>

                    <Form.Group
                      controlId="role"
                      className="col-xl-2 col-lg-4 col-sm-5 col-12"
                    >
                      <Form.Label>Alert Type</Form.Label>
                      <Form.Control
                        as="select"
                        value={type}
                        onChange={(e) => {
                          setType(e.target.value);
                        }}
                        id="alerts-alert-type-option"
                      >
                        <option value="">--Select--</option>
                        {roles?.map((i: any, index: number) => (
                          <option key={i.key} value={i.key}>
                            {i.value}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>

                    <Form.Group
                      controlId="role"
                      className="col-xl-2 col-lg-4 col-sm-5 col-12"
                    >
                      <Form.Label>Acknowledgement</Form.Label>
                      <Form.Control
                        as="select"
                        value={acknowledge}
                        onChange={(e) => {
                          setAcknowledge(e.target.value);
                        }}
                        id="alerts-acknowledgement-option"
                      >
                        <option value="">All</option>
                        <option value={true}>Acknowledged</option>
                        <option value={false}>Unacknowledged</option>
                      </Form.Control>
                    </Form.Group>
                  </Form>

                  <div className="d-flex flex-wrap justify-content-center mt-4 pl-1">
                    <button
                      type="button"
                      className="m-1 pointer alertFilterButton "
                      disabled={dateStart && !dateEnd ? true : false}
                      onClick={() => {
                        fetchAlerts();
                        fetchAlertsCount();
                      }}
                      id="alerts-apply-btn"
                    >
                      Apply
                    </button>
                    <button
                      type="button"
                      className="m-1 pointer alertFilterButton"
                      onClick={() => {
                        setAgentId('');
                        setType('');
                        setAcknowledge(false);
                        setDateRange('');
                        setDateEnd('');
                        setDateStart('');
                        setCalendarDateStart('');
                        setCalendarDateEnd('');
                        fetchAlertsCount(true);
                        fetchAlerts(true);
                        handleClear();
                      }}
                      id="alerts-clear-btn"
                    >
                      Clear
                    </button>
                    <button
                      type="button"
                      className="Close m-1 pointer alertFilterButton"
                      onClick={() => {
                        setFilter(false);
                      }}
                      id="alerts-close-btn"
                    >
                      Close
                    </button>
                  </div>
                </section>
              )}
              {alertLists?.length !== 0 && (
                <div className="mobilePaginationContainer">
                  <Pagination
                    className="mr-1"
                    currentPage={alertsPageNumber}
                    recordsPerPage={numberOfRecords}
                    totalRecords={alertLists?.length}
                    onPageChange={(page: number) => setAlertsPageNumber(page)}
                  />
                </div>
              )}

              {/* <div
                className=""
                style={{
                  borderRadius: '5px'
                  // background: '#cecece',
                }}
              > */}
              <div className="card-body col-lg-10 col-md-12 mx-auto px-0 pb-3">
                <div className="d-flex tableContainer overflow-auto flex-column">
                  <table
                    className={`customTable ${darkmode ? 'darkTable' : ''}`}
                  >
                    <thead className="">
                      <tr>
                        <th
                          className="p-2 text-left col-2 pl-4"
                          onClick={() => sortTableData('agentId')}
                        >
                          Endpoint ID{' '}
                          {sortColumn === 'agentId' &&
                            (sortOrder === 'asc' ? (
                              <i className="fa fa-sort-up" />
                            ) : (
                              <i className="fa fa-sort-down" />
                            ))}
                        </th>
                        <th
                          className="p-2 text-left col-2"
                          onClick={() => sortTableData('type')}
                        >
                          Alert Type{' '}
                          {sortColumn === 'type' &&
                            (sortOrder === 'asc' ? (
                              <i className="fa fa-sort-up" />
                            ) : (
                              <i className="fa fa-sort-down" />
                            ))}
                        </th>
                        <th
                          className="p-2 text-left col-4"
                          onClick={() => sortTableData('summary')}
                        >
                          Summary{' '}
                          {sortColumn === 'summary' &&
                            (sortOrder === 'asc' ? (
                              <i className="fa fa-sort-up" />
                            ) : (
                              <i className="fa fa-sort-down" />
                            ))}
                        </th>
                        <th
                          className="p-2 text-left col-1"
                          onClick={() => sortTableData('date')}
                        >
                          Date{' '}
                          {sortColumn === 'date' &&
                            (sortOrder === 'asc' ? (
                              <i className="fa fa-sort-up" />
                            ) : (
                              <i className="fa fa-sort-down" />
                            ))}
                        </th>
                        <th className="text-left p-2 col-1"> </th>
                        <th className="text-left p-2 col-1"> </th>
                      </tr>
                    </thead>
                    {!loading && alertsPaginatedData?.length > 0 && (
                      <tbody className="">{renderAlertLists}</tbody>
                    )}
                  </table>
                  {/* </div> */}
                  {loading && (
                    <p
                      className={`mt-0 ${
                        darkmode ? 'noRecordsDark' : 'noRecords'
                      }`}
                    >
                      Loading...
                    </p>
                  )}
                  {!loading && alertsPaginatedData?.length === 0 && (
                    <p
                      className={`mt-0 ${
                        darkmode ? 'noRecordsDark' : 'noRecords'
                      }`}
                    >
                      No Alerts Found
                    </p>
                  )}
                  {!loading && alertsPaginatedData === null && (
                    <p
                      className={`mt-0 ${
                        darkmode ? 'noRecordsDark' : 'noRecords'
                      }`}
                    >
                      Network Error...
                    </p>
                  )}
                </div>
              </div>
              {/* </div> */}
            </Col>
          </div>
        </div>

        {/* <UserFormModal
    show={showUserForm}
    onHide={() => setShowUserForm(false)}
  /> */}
      </div>
    </Container>
  );
}

export default Alert;

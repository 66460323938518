import {PfImage} from '@profabric/react-components';
import {useFormik} from 'formik';
import {Form, InputGroup} from 'react-bootstrap';
import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import * as Yup from 'yup';
import {toast} from 'react-toastify';
import {useSelector} from 'react-redux';
import {changePassword} from '@app/services/swaggerAuth';
import { ValidationResult, getStrengthColor, validatePassword } from '@app/utils/helpers';

const ChangePassword = () => {
  // const [showOld, setShowOld] = useState(false);
  const [showNew, setShowNew] = useState(false);
  const [showConfirmNew, setShowConfirmNew] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [validation, setValidation] = useState<ValidationResult | null>(null);
  // const currentUserPassword = useSelector((state: any) => state.auth.currentUserPassword);
  const token = useSelector((state: any) => state.auth.token);
  const [passwordFocused, setPasswordFocused] = useState(false);
  const [hasStartedTyping, setHasStartedTyping] = useState(false);

  const handlePasswordFocus = () => {
    setPasswordFocused(true);
    setHasStartedTyping(true); 
  };

  const handlePasswordBlur = (e: any) => {
    handleBlur(e);
    setPasswordFocused(false);
  };

  const {handleChange, handleBlur, values, handleSubmit, touched, errors} = useFormik({
    initialValues: {
      // oldPassword: '',
      newPassword,
      confirmNewPassword
    },
    validationSchema: Yup.object({
      // email: Yup.string().email('Invalid email address').required('Required')
      // oldPassword: Yup.string()
      //   .min(3, 'Must be 3 characters or more')
      //   .max(30, 'Must be 30 characters or less')
      //   .required('Required'),
      newPassword: Yup.string()
        .min(8, 'Must be 8 characters or more')
        .max(30, 'Must be 30 characters or less')
        .required('Required'),
      // .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/, 'Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number, and be at least 8 characters long.')
      confirmNewPassword: Yup.string()
        .min(8, 'Must be 8 characters or more')
        .max(30, 'Must be 30 characters or less')
        .required('Required')
      // .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/, 'Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number, and be at least 8 characters long.')
    }),
    onSubmit: (values) => {
      // toast.warn('Not yet functional');
      // eslint-disable-next-line no-console
      // console.log('currentUserPassword', currentUserPassword);
      // if (values.oldPassword !== currentUserPassword) {
      //   toast.warn('Enter Previous Password Correctly');
      // }
      if (newPassword !== confirmNewPassword) {
        toast.error("The Passwords doesn't match");
      } else if (newPassword === confirmNewPassword) {
        changePassword(token, newPassword, '')
          .then((response) => {
            if (response.status === 200) {
              toast.success('Password Changed Successfully');
              setNewPassword('');
              setConfirmNewPassword('');
            }
            // if (response.status === 403) {
            //   toast.error(response.data);
            // }
          })
          .catch((err) => {
            // if (err.status === 403) {
            toast.error(err.response.data);
            // }
            // toast.error('Something went wrong');
          });
      }
    }
  });

  return (
    <div className="col-lg-6 mx-auto changePAsswordContainer">
      <form className="form-horizontal" onSubmit={handleSubmit}>
        {/* <Form.Group className="mb-3">
          <Form.Label style={{fontWeight: '500'}}>Old Password</Form.Label>
          <InputGroup>
            <Form.Control
              type={showOld ? 'text' : 'password'}
              id="oldPassword"
              // placeholder="Enter old password"
              onChange={handleChange}
              value={values.oldPassword}
              isValid={touched.oldPassword && !errors.oldPassword}
              isInvalid={touched.oldPassword && !!errors.oldPassword}
            />
            <InputGroup.Append>
              <InputGroup.Text>
                <i
                  className={`fa ${showOld ? 'fa-eye' : 'fa-eye-slash'}`}
                  onClick={() => setShowOld((show) => !show)}
                  aria-hidden="true"
                />
              </InputGroup.Text>
            </InputGroup.Append>
            {touched.oldPassword && errors.oldPassword && (
              <div className="invalid-feedback">{errors.oldPassword}</div>
            )}
          </InputGroup>
        </Form.Group> */}

        <Form.Group className="mb-3 row">
          <Form.Label
            className="col-12 col-form-label ml-2"
            style={{fontWeight: '500'}}
          >
            Enter New Password
          </Form.Label>
          <InputGroup className="col-12">
            <Form.Control
              type={showNew ? 'text' : 'password'}
              id="newPassword"
              // placeholder="Enter new password"
              onChange={(e) => {
                handleChange(e);
                setNewPassword(e.target.value);
                setValidation(validatePassword(e.target.value));
                if (!hasStartedTyping) {
                  setHasStartedTyping(true);
                }
              }}
              onFocus={handlePasswordFocus}
              onBlur={handlePasswordBlur}
              value={newPassword}
              maxLength={25}
              // isValid={touched.newPassword && !errors.newPassword}
              isInvalid={touched.newPassword && !!errors.newPassword}
            />
            <InputGroup.Append>
              <InputGroup.Text>
                <i
                  className={`fa ${showNew ? 'fa-eye' : 'fa-eye-slash'}`}
                  onClick={() => setShowNew((show) => !show)}
                  aria-hidden="true"
                  id='profile-change-new-pass-eye'
                />
              </InputGroup.Text>
            </InputGroup.Append>
            {touched.newPassword && errors.newPassword && (
              <div className="invalid-feedback">{errors.newPassword}</div>
            )}
          </InputGroup>

          {(hasStartedTyping && passwordFocused) && (
  <>

          <div className="password-strength-bar">
        <div
          className="password-strength-indicator"
          style={{
            width: `${(validation?.score || 0) / 8 * 100}%`,
            backgroundColor: getStrengthColor(validation?.score || 0)
          }}
        ></div>
      </div>
      <div>
        <p>Password Strength:</p>
        <ul>
          <li style={{ color: validation?.minLength ? 'green' : 'red' }}>
            Minimum 8 characters
          </li>
          <li style={{ color: validation?.hasUpperCase ? 'green' : 'red' }}>
            Includes uppercase letters
          </li>
          <li style={{ color: validation?.hasLowerCase ? 'green' : 'red' }}>
            Includes lowercase letters
          </li>
          <li style={{ color: validation?.hasNumber ? 'green' : 'red' }}>
            Includes numbers
          </li>
          <li style={{ color: validation?.hasSpecialChar ? 'green' : 'red' }}>
            Includes special characters
          </li>
          <li style={{ color: validation?.noSequentialChars ? 'green' : 'red' }}>
            No sequential characters
          </li>
          <li style={{ color: validation?.noRepetitivePatterns ? 'green' : 'red' }}>
            No repetitive patterns
          </li>
          <li style={{ color: validation?.notCommonPassword ? 'green' : 'red' }}>
            Not a common password
          </li>
        </ul>
      </div>
      </>)}

        </Form.Group>

        <Form.Group className="mb-3 row">
          <Form.Label
            className="col-12 col-form-label ml-2"
            style={{fontWeight: '500'}}
          >
            Confirm New Password
          </Form.Label>
          <InputGroup className="col-12">
            <Form.Control
              type={showConfirmNew ? 'text' : 'password'}
              id="confirmNewPassword"
              // placeholder="Confirm new password"
              value={confirmNewPassword}
              maxLength={25}
              onChange={(e) => {
                handleChange(e);
                setConfirmNewPassword(e.target.value);
              }}
              // isValid={touched.confirmNewPassword && !errors.confirmNewPassword}
              isInvalid={
                touched.confirmNewPassword && !!errors.confirmNewPassword
              }
            />
            <InputGroup.Append>
              <InputGroup.Text>
                <i
                  className={`fa ${showConfirmNew ? 'fa-eye' : 'fa-eye-slash'}`}
                  onClick={() => setShowConfirmNew((show) => !show)}
                  aria-hidden="true"
                  id='profile-confirm-new-pass-eye'
                />
              </InputGroup.Text>
            </InputGroup.Append>
            {touched.confirmNewPassword && errors.confirmNewPassword && (
              <div className="invalid-feedback">
                {errors.confirmNewPassword}
              </div>
            )}
          </InputGroup>
        </Form.Group>

        <div className="form-group pl-1 mt-5 pt-5 d-flex">
          <button className="filterButton w-50 mr-4 me-4" type="submit" id='profile-password-save-btn'>
            Save
          </button>
          <button
            type="button"
            className="filterButton w-50 ml-4 ms-4"
            onClick={() => {
              setNewPassword('');
              setConfirmNewPassword('');
            }}
            id='profile-password-cancel-btn'
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default ChangePassword;

import axios from 'axios';
import {
  GET_SEVERITY,
  GET_THREATS_RISKS_LIST,
  GET_VULNERABILITY,
  GET_VULNERABILITY_COUNT
} from './constants/swaggerConfig';

export const getRiskModuleList = (token: string) => {
  return axios.get(`${GET_VULNERABILITY}/list/${token}`);
};
export const getSeverityOptions = (token: string) => {
  return axios.get(`${GET_SEVERITY}/${token}`);
};
export const getVulnerabilitySoftwares = (token: string) => {
  return axios.get(`${GET_VULNERABILITY}/softwarenames/${token}`);
};
export const getVulnerabilityList = (
  token: string,
  count?: number,
  agentId?: string,
  detectedDateStart?: string,
  detectedDateEnd?: string,
  acknowledged?: any,
  softwareName?: any,
  disposition?: string,
  severity?: string
) => {
  return axios.get(
    `${GET_VULNERABILITY}/list/${token}${count ? `?count=${count}` : ''}${
      agentId && `&agentId=${agentId}`
    }${detectedDateStart && `&detectedDateStart=${detectedDateStart}`}${detectedDateEnd && `&detectedDateEnd=${detectedDateEnd}`}${
      acknowledged !== 'undefined' ? `&acknowledged=${acknowledged}`:''
    }${softwareName && `&softwareName=${softwareName}`
    }${disposition && `&disposition=${disposition}`}${severity && `&severity=${severity}`}`
  );
};

export const getVulnerabilityCount = (token: string, acknowledged?: any) => {
  return axios.get(
    `${GET_VULNERABILITY_COUNT}/${token}${
      acknowledged !== undefined ? '?acknowledged=' + acknowledged : ''
    }`
  );
};

export const getVulnerabilityDetails = (
  token: string,
  id: string | undefined,
) => {
  return axios.get(
    `${GET_VULNERABILITY}/${id}/${token}`
  );
};

export const postAckVulnerability = (
  token: string,
  id: string | undefined,
  comment: string
) => {
  return axios.post(
    `${GET_VULNERABILITY}/ack/${id}/${token}?comment=${comment}`
  );
};
export const postAckVulnerabilityDisposition = (
  token: string,
  id: string | undefined,
  disposition: string,
  scheduledDate: string | number
) => {
  return axios.post(
    `${GET_VULNERABILITY}/disposition/${id}/${token}?disposition=${disposition}&scheduledDate=${scheduledDate}`
  );
};

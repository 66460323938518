import React from 'react'
import SlidingPane from 'react-sliding-pane';


type Props = {
    show: boolean;
    onHide: any;
  };

function UserManuel({show, onHide}: Props) {
  return (
    <SlidingPane
      isOpen={show}
      onRequestClose={() => {
        onHide()
       
      }}
      className="w-50"
      overlayClassName="custom-overlay-slide-pane"
      // title={<h3 className="text-center">{renderTitle()}</h3>}
      // closeIcon={<i className="fa-solid fa-xmark" />}
    >
      <div className="modalContentMain h-100">


      <iframe
    src="/img/pdf/GITM Manual v6.29.24.pdf"
    type="application/pdf"
    frameBorder="0"
    scrolling="auto"
    height="100%"
    width="100%"
></iframe>
        
        
      </div>
    </SlidingPane>
  )
}

export default UserManuel
import {postSetting} from '@app/services/settings';
import React, {ChangeEvent, useState} from 'react';
import {useSelector} from 'react-redux';
import {toast} from 'react-toastify';

type Props = {
  keyValue: string;
  description: string;
  value: string;
  uom: string;
};

function SettingCard({keyValue, description, value, uom}: Props) {
  const token = useSelector((state: any) => state.auth.token);


  const [settingValue, setSettingValue] = useState(value);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;

   
    if(keyValue === 'Ports'){
      if (/^[0-9,]{0,100}$/.test(input)) {
        setSettingValue(input); 
    }
    }else{
      if (/^\d{0,10}$/.test(input)) {
        setSettingValue(input); 
    }
    }
  
  };

  const saveSetting = () => {
    postSetting(token, keyValue, settingValue)
      .then((res) => {
        console.log(res);
        toast.success('setting updated');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <tr
      className={``}
    >
      <td className='col-1'>
        <p className="">{keyValue}</p>
      </td>

      <td className="col-3">
        <p className="">{description}</p>
      </td>

      <td>
        <p className="">{uom}</p>
      </td>

      <td className="col-4">
        <input
          type="text"
          className="w-100"
          value={settingValue}
          onChange={handleChange}
          name="value"
          id='settings-value-input'
        />
      </td>

      <td>
        <button
          onClick={saveSetting}
          className="btn btn-md btn-outline-primary"
          id='settings-save-btn'
        >
          Save
        </button>
      </td>
    </tr>
  );
}

export default SettingCard;

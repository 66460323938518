import Container from '@app/components/CustomDash/Container';
import React from 'react';
import {Col} from 'react-bootstrap';
import {useSelector} from 'react-redux';
import {NavLink, useNavigate} from 'react-router-dom';

type Props = {};

function Reports({}: Props) {
  const navigate = useNavigate();
  const darkmode = useSelector((state: any) => state.account.darkmode);

  return (
    <Container>
      <div className="container-fluid">
        <div className="row d-flex justify-content-center align-items-center">
          <Col xs={12}>
            <div className="mt-2 FilterButtonContainer col-lg-10 col-md-12 mx-auto pt-2 mb-2 d-flex justify-content-between">
              <h3 className="subHeaders">  </h3>
            </div>

            <div className="card-body col-lg-10 col-md-12 mx-auto px-0 pb-3">
              <div className="d-flex tableContainer overflow-auto flex-column">
                {/* <div className="rounded"> */}
                <table className={`customTable ${darkmode ? 'darkTable' : ''}`}>
                  <thead className="">
                    <tr>
                      <th className="p-2 col-2 text-left pl-4">Name</th>

                      <th className="text-left p-2 col-6">Description </th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody className="">
                    <tr
                      className="customPointer"
                      onClick={() => {
                        navigate(`/reports/agent-reports`);
                      }}
                    >
                      <td className="text-left p-2 pl-4">Managed Endpoints Report</td>
                      <td className="text-left p-2">List of all the managed endpoints.</td>
                      <td className="text-center p-2 col-1 pr-4">
                        <i id='me-reports-arrow' className="fa-solid fa-chevron-right" />
                      </td>
                    </tr>
                    <tr
                      className="customPointer"
                      onClick={() => {
                        navigate(`/reports/software-inventory-reports`);
                      }}
                    >
                      <td className="text-left p-2 pl-4">
                        Software Inventory Report
                      </td>
                      <td className="text-left p-2">
                        List of all software inventory based on the managed endpoints.
                      </td>
                      <td className="text-center p-2 col-1 pr-4">
                        <i id='si-reports-arrow' className="fa-solid fa-chevron-right" />
                      </td>
                    </tr>
                    <tr
                      className="customPointer"
                      onClick={() => {
                        navigate(`/reports/unacknowledged-alerts-reports`);
                      }}
                    >
                      <td className="text-left p-2 pl-4">
                        Unacknowledged Alerts Report
                      </td>
                      <td className="text-left p-2">
                        List of all unacknowledged alerts based on the managed endpoints.
                      </td>
                      <td className="text-center p-2 col-1 pr-4">
                        <i id='una-reports-arrow' className="fa-solid fa-chevron-right" />
                      </td>
                    </tr>
                    <tr
                      className="customPointer"
                      onClick={() => {
                        navigate(`/reports/acknowledged-alerts-reports`);
                      }}
                    >
                      <td className="text-left p-2 pl-4">
                        Acknowledged Alerts Report
                      </td>
                      <td className="text-left p-2">
                        List of all acknowledged alerts based on the managed endpoints.
                      </td>
                      <td className="text-center p-2 col-1 pr-4">
                        <i id='a-reports-arrow' className="fa-solid fa-chevron-right" />
                      </td>
                    </tr>
                    <tr
                      className="customPointer"
                      onClick={() => {
                        navigate(`/reports/unacknowledged-vulnerability-reports`);
                      }}
                    >
                      <td className="text-left p-2 pl-4">
                       Unacknowledged Vulnerability Report
                      </td>
                      <td className="text-left p-2">
                      List of all unacknowledged software vulnerabilities.
                      </td>
                      <td className="text-center p-2 col-1 pr-4">
                        <i id='u-v-reports-arrow' className="fa-solid fa-chevron-right" />
                      </td>
                    </tr>
                    <tr
                      className="customPointer"
                      onClick={() => {
                        navigate(`/reports/acknowledged-vulnerability-reports`);
                      }}
                    >
                      <td className="text-left p-2 pl-4">
                      Acknowledged Vulnerability Report
                      </td>
                      <td className="text-left p-2">
                      List of all acknowledged software vulnerabilities.
                      </td>
                      <td className="text-center p-2 col-1 pr-4">
                        <i id='a-v-reports-arrow' className="fa-solid fa-chevron-right" />
                      </td>
                    </tr>
                    <tr
                      className="customPointer"
                      onClick={() => {
                        navigate(`/reports/service-report`);
                      }}
                    >
                      <td className="text-left p-2 pl-4">
                      Unique Services report
                      </td>
                      <td className="text-left p-2">
                      List of all Unique Service Report
                      </td>
                      <td className="text-center p-2 col-1 pr-4">
                        <i id='a-v-reports-arrow' className="fa-solid fa-chevron-right" />
                      </td>
                    </tr>
                    <tr
                      className="customPointer"
                      onClick={() => {
                        navigate(`/reports/unique-vulnerability-report`);
                      }}
                    >
                      <td className="text-left p-2 pl-4">
                      Unique Vulnerability report
                      </td>
                      <td className="text-left p-2">
                      List of all Unique Vulnerability Report
                      </td>
                      <td className="text-center p-2 col-1 pr-4">
                        <i id='a-v-reports-arrow' className="fa-solid fa-chevron-right" />
                      </td>
                    </tr>
                    <tr
                      className="customPointer"
                      onClick={() => {
                        navigate(`/reports/open-ports-report`);
                      }}
                    >
                      <td className="text-left p-2 pl-4">
                      Unique Open Ports report
                      </td>
                      <td className="text-left p-2">
                      List of all Unique Open Ports Report
                      </td>
                      <td className="text-center p-2 col-1 pr-4">
                        <i id='a-v-reports-arrow' className="fa-solid fa-chevron-right" />
                      </td>
                    </tr>
                    {/* <tr></tr> */}
                  </tbody>
                </table>
                {/* </div> */}
              </div>
            </div>
          </Col>
        </div>
      </div>
    </Container>
  );
}

export default Reports;

import {getDebugServer} from '@app/services/debug';
import {formatBytes} from '@app/utils/helpers';
import React, {useEffect, useState} from 'react';

const SystemHealth = () => {
  const [responseData, setResponseData] = useState<any>(null);

  useEffect(() => {
    getDebugServer().then((res) => {
      console.log('Disk space response', res.data);
      setResponseData(res.data);
    });
  }, []);

  return (
    <div className="col-lg-8 mx-auto d-flex flex-column mt-5">
      <div className="d-flex">
        <div className="col-3 borderRight tableKey2">
          <span>Disc Capacity</span>
        </div>
        <div className="tableValue">
          <span>
            {responseData?.totalSpace && formatBytes(responseData?.totalSpace)}
          </span>
        </div>
      </div>
      <div className="d-flex">
        <div className="col-3 borderRight tableKey2">
          <span>Disc Free Space</span>
        </div>
        <div className="tableValue">
          <span>
            {' '}
            {responseData?.freeSpace && formatBytes(responseData?.freeSpace)}
          </span>
        </div>
      </div>
      <div className="d-flex">
        <div className="col-3 borderRight tableKey2">
          <span>ID</span>
        </div>
        <div className="tableValue">
          <span>{responseData?.collectorId}</span>
        </div>
      </div>
      <div className="d-flex">
        <div className="col-3 borderRight tableKey2">
          <span>Collector Version</span>
        </div>
        <div className="tableValue">
          <span>{responseData?.version}</span>
        </div>
      </div>
    </div>
  );
};

export default SystemHealth;

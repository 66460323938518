import Container from '@app/components/CustomDash/Container';
import UserFormSlideIn from '@app/pages/users/UserFormSlideIn';
import {
  getCSVSchema,
  getTrustedSoftware,
  getXmlSchema,
  uploadTrustedSoftware
} from '@app/services/trustedSoftware';
import React, {useEffect, useState} from 'react';
import {Col, Dropdown, Form} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import UploadFormSlideIn from './UploadFormSlideIn';
import {setIsAdmin} from '@app/store/reducers/auth';
import Pagination from '@app/components/common/Pagination';
import {Link} from 'react-router-dom';
import {handleInputChange} from '@app/utils/helpers';
import NoOfRecords from '@app/components/common/NoOfRecords';
import {RECORDS_PER_PAGE} from '@app/services/constants/config';
import DownloadSchema from './DownloadSchema';

function TrustedSoftware() {
  const dispatch = useDispatch();

  const [openFilter, setOpenFilter] = useState(false);
  const [showUserForm, setShowUserForm] = useState(false);
  const [listData, setListData] = useState<any>();
  const [file, setFile] = useState<any>();
  const [softwareData, setSoftwareData] = useState<any>('');
  const [alertsPageNumber, setAlertsPageNumber] = useState(1);
  const [numberOfRecords, setNumberOfRecords] = useState(RECORDS_PER_PAGE);
  const [loading, setLoading] = useState(true);

  const [name, setName] = useState<string>('');
  const [XMLSchema, setXMLSchema] = useState<string>('');
  const [version, setVersion] = useState<string>('');
  const [publisher, setPublisher] = useState<string>('');
  const [license, setLicense] = useState<string>('');
  const isAdmin = useSelector((state: any) => state.auth.isAdmin);

  const token = useSelector((state: any) => state.auth.token);
  const currentUser = useSelector((state: any) => state.auth.currentUser);

  // Perform array slicing based on the current page
  const startIndex = (alertsPageNumber - 1) * numberOfRecords;
  const endIndex = alertsPageNumber * numberOfRecords;
  const alertsPaginatedData = listData?.slice(startIndex, endIndex);
  const darkmode = useSelector((state: any) => state.account.darkmode);

  const [sortOrder, setSortOrder] = useState('asc');
  const [sortColumn, setSortColumn] = useState(null);

  const sortTableData = (column: any) => {
    const sortedData = [...listData];

    // *** NEW IMPLEMENTATION ***
    // If One column asc then next column also asc, if one dsc, then next also dsc..
    if (sortColumn === column) {
      sortedData.reverse();
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      if (sortOrder === 'asc' || sortOrder === null) {
        // sort aesc if the current order is asc or null
        sortedData.sort((a, b) => (a[column] < b[column] ? -1 : 1));
        setSortColumn(column);
        setSortOrder('asc');
      } else {
        sortedData.sort((a, b) => (a[column] > b[column] ? -1 : 1)); // sort desc if the current order is desc
        setSortColumn(column);
        setSortOrder('desc');
      }
    }

    setListData(sortedData);
  };

  const fetchTrustedSoftware = () => {
    getTrustedSoftware(token, name, version, publisher, license)
      .then((res) => {
        setListData(res.data);
        localStorage.setItem(
          'cachedValidatedSoftwareData',
          JSON.stringify(res.data)
        );
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchXMLSchema = () => {
    getXmlSchema(token)
      .then((res) => {
        const url = URL.createObjectURL(new Blob([res.data]));

        // Create a hidden anchor element
        const a = document.createElement('a');
        a.href = url;
        a.download = 'software.xml'; // Replace with the desired filename and extension
        a.click();

        // Release the temporary URL
        URL.revokeObjectURL(url);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchCSVSchema = () => {
    getCSVSchema(token)
      .then((res) => {
        const url = URL.createObjectURL(new Blob([res.data]));

        // Create a hidden anchor element
        const a = document.createElement('a');
        a.href = url;
        a.download = 'software.csv'; // Replace with the desired filename and extension
        a.click();

        // Release the temporary URL
        URL.revokeObjectURL(url);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (!showUserForm) {
      fetchTrustedSoftware();
    }
  }, [showUserForm]);

  const filterSearchHandler = () => {
    fetchTrustedSoftware();
  };

  useEffect(() => {
    if (alertsPaginatedData?.length === 0) {
      setAlertsPageNumber(1);
    }
  }, [alertsPaginatedData]);

  const renderList = alertsPaginatedData?.map((i: any, index: number) => (
    <tr
      key={index}
      onClick={() => {
        setShowUserForm(true);
        setSoftwareData(i);
      }}
    >
      <td className="text-left pl-4">{i.name}</td>
      <td className="text-left pl-2">{i.version}</td>
      <td className="text-left pl-2">{i.publisher}</td>
      <td className=" text-left pl-2">{i.license}</td>
      <td className=" text-left pl-2">{i.licenseType}</td>
      <td className="text-left pl-2">{i.licenseDeviceCount}</td>
      <td className="text-center p-2 col-2 pr-4">
        <i id="vs-arrow" className="fa-solid fa-chevron-right" />
      </td>
    </tr>
  ));

  const handleUpload = (e: any) => {
    setFile(e.target.files[0]);
  };

  const checkIfAdmin = (user: any) => {
    // console.log('user.roles', user);
    // use when array
    // const isAdmin = user.roles.find((role: string) => role === 'ADMIN');
    // if (isAdmin !== undefined) {
    //   dispatch(setIsAdmin(true));
    // } else {
    //   dispatch(setIsAdmin(false));
    // }
    // use when string
    if (user.roles === 'ADMIN') {
      dispatch(setIsAdmin(true));
    } else {
      dispatch(setIsAdmin(false));
    }
  };

  useEffect(() => {
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      setTimeout(() => {
        uploadTrustedSoftware(token, formData)
          .then((res) => {
            console.log(res);
            toast.success(res.data);
          })
          .catch((err) => {
            console.log(err);
            // toast.error(err.response.data);
            toast.error(
              'Failed to upload due to either invalid format or duplicate records'
            );
          });
      }, 500);
    }
  }, [file]);

  const handleAddSoftware = () => {
    setShowUserForm(true);
    setSoftwareData('');
  };

  const fetchInitialData = () => {
    const cachedData = localStorage.getItem('cachedValidatedSoftwareData');
    if (cachedData) {
      setListData(JSON.parse(cachedData));
      // setLoading(false);
    } else {
      fetchTrustedSoftware(); // Fetch data if not cached
    }
  };

  useEffect(() => {
    if (currentUser) {
      checkIfAdmin(currentUser);
    }
  }, [currentUser]);

  useEffect(() => {
    if (name == '' && version == '' && publisher == '' && license == '') {
      setTimeout(() => {
        fetchInitialData();
      }, 100);
    }
  }, [name, version, publisher, license]);

  return (
    <Container>
      <div>
        <div className="container-fluid">
          <div className="row d-flex justify-content-center align-items-center">
            <Col xs={12}>
              <div>
                {/* Buttons Container */}
                <div className="mt-2 FilterButtonContainer col-lg-10 col-md-12 mx-auto pt-2 mb-2 d-flex justify-content-between">
                  <h3 className="subHeaders"> </h3>
                  <div className="d-flex flex-wrap align-items-center justify-content-end col-10">
                    {/* {allUserProfiles?.length !== 0 && (
                    <div className="paginationContainer">
                      <Pagination
                        className="mr-1"
                        currentPage={usersPageNumber}
                        recordsPerPage={USERS_RECORDS_PER_PAGE}
                        totalRecords={allUserProfiles?.length}
                        onPageChange={(page: number) =>
                          dispatch(setUsersPageNumber(page))
                        }
                      />
                    </div>
                  )} */}

                    <NoOfRecords
                      value={numberOfRecords}
                      setValue={(value) => setNumberOfRecords(value)}
                      totalRecords={listData?.length}
                    />

                    {listData?.length !== 0 && (
                      <div className="paginationContainer">
                        <Pagination
                          className="mr-1"
                          currentPage={alertsPageNumber}
                          recordsPerPage={numberOfRecords}
                          totalRecords={listData?.length}
                          onPageChange={(page: number) =>
                            setAlertsPageNumber(page)
                          }
                        />
                      </div>
                    )}

                    {isAdmin && (
                      <button className="m-1 filterButton position-relative mainFilterButton">
                        <img src="/img/upload_icon copy 1.png" alt="" />
                        <span className="pl-1">Upload</span>
                        <input
                          type="file"
                          className="inputFileUpload"
                          onChange={handleUpload}
                          accept=".csv,.xml"
                          id="vs-upload-btn"
                        />
                      </button>
                    )}

                    {isAdmin && (
                      <button
                        className="m-1 filterButton mainFilterButton"
                        onClick={handleAddSoftware}
                        id="vs-add-user-btn"
                      >
                        <i className="fa-solid fa-plus" />
                        <span className="pl-1">Add </span>
                      </button>
                    )}

                    <button
                      className="m-1 filterButton mainFilterButton"
                      onClick={() => setOpenFilter(!openFilter)}
                      aria-expanded={openFilter}
                      aria-controls="example-collapse-text"
                      id="vs-filter-btn"
                    >
                      <i className="fa-solid fa-filter" />
                      <span className="pl-1">Filter</span>
                    </button>
                  </div>
                </div>

                {/* <div className="d-flex flex-column mt-3 px-2 pb-3"> */}
                {/* Filter Container */}
                {openFilter && (
                  <section
                    className={`FilterOption ${
                      darkmode && 'darkSecondaryWrapper'
                    } col-lg-10 col-md-12 mx-auto mb-0 d-flex  flex-column`}
                  >
                    <Form className="d-flex flex-wrap justify-content-between">
                      <Form.Group
                        controlId="role"
                        className="col-lg-3 col-md-3 col-12"
                      >
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="search"
                          placeholder="Enter name"
                          value={name}
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                          id="vs-name-option"
                        />
                      </Form.Group>
                      <Form.Group
                        controlId="role"
                        className="col-lg-3 col-md-3 col-12"
                      >
                        <Form.Label>Version</Form.Label>
                        <Form.Control
                          type="search"
                          placeholder="Enter version"
                          value={version}
                          onChange={(e) => {
                            setVersion(e.target.value);
                          }}
                          id="vs-version-option"
                        />
                      </Form.Group>
                      <Form.Group
                        controlId="role"
                        className="col-lg-3 col-md-3 col-12"
                      >
                        <Form.Label>Publisher</Form.Label>
                        <Form.Control
                          type="search"
                          placeholder="Enter publisher"
                          value={publisher}
                          onChange={(e) => {
                            setPublisher(e.target.value);
                          }}
                          id="vs-publisher-option"
                        />
                      </Form.Group>
                      <Form.Group
                        controlId="role"
                        className="col-lg-3 col-md-3 col-12"
                      >
                        <Form.Label>License Name</Form.Label>
                        <Form.Control
                          type="search"
                          placeholder="Enter license name"
                          value={license}
                          onChange={(e) => {
                            setLicense(e.target.value);
                          }}
                          id="vs-license-option"
                        />
                      </Form.Group>
                    </Form>

                    <div className="d-flex flex-wrap justify-content-center mt-4 pl-1">
                      <button
                        type="button"
                        className="m-1 pointer alertFilterButton"
                        onClick={filterSearchHandler}
                        id="vs-apply-btn"
                      >
                        Apply
                      </button>
                      <button
                        type="button"
                        className="m-1 pointer alertFilterButton"
                        onClick={() => {
                          setName('');
                          setVersion('');
                          setPublisher('');
                          setLicense('');
                        }}
                        id="vs-clear-btn"
                      >
                        Clear
                      </button>
                      <button
                        type="button"
                        className="Close m-1 pointer alertFilterButton"
                        onClick={() => {
                          setOpenFilter(false);
                        }}
                        id="vs-close-btn"
                      >
                        Close
                      </button>
                      {/* <div className="btn text-white  m-1 apllyBTn">Apply</div>
                      <div className="btn  m-1 clearBtn">Clear</div>
                      <div className="btn btn-danger m-1">Close</div> */}
                    </div>
                  </section>
                )}

                {/* {allUserProfiles?.length !== 0 && (
                <div className="mobilePaginationContainer">
                  <Pagination
                    className="mr-1"
                    currentPage={usersPageNumber}
                    recordsPerPage={USERS_RECORDS_PER_PAGE}
                    totalRecords={allUserProfiles?.length}
                    onPageChange={(page: number) =>
                      dispatch(setUsersPageNumber(page))
                    }
                  />
                </div>
              )} */}

                {/* Table */}
                <div className="card-body col-lg-10 col-md-12 mx-auto px-0 pb-3">
                  <div className="d-flex tableContainer overflow-auto flex-column">
                    <table
                      className={`customTable ${darkmode ? 'darkTable' : ''}`}
                    >
                      <thead className="">
                        <tr>
                          <th
                            className="p-2 col-2 text-left pl-4"
                            onClick={() => {
                              // handleTableHeaderSorting('userId')
                              sortTableData('name');
                            }}
                          >
                            Name
                            {sortColumn === 'name' &&
                              (sortOrder === 'asc' ? (
                                <i className="fa fa-sort-up" />
                              ) : (
                                <i className="fa fa-sort-down" />
                              ))}
                          </th>
                          <th
                            className="p-2 col-2 text-left"
                            onClick={() => {
                              // handleTableHeaderSorting('userId')
                              sortTableData('version');
                            }}
                          >
                            Version{' '}
                            {sortColumn === 'version' &&
                              (sortOrder === 'asc' ? (
                                <i className="fa fa-sort-up" />
                              ) : (
                                <i className="fa fa-sort-down" />
                              ))}
                          </th>
                          <th
                            className="p-2 col-2 text-left"
                            onClick={() => {
                              // handleTableHeaderSorting('userId')
                              sortTableData('publisher');
                            }}
                          >
                            Publisher{' '}
                            {sortColumn === 'publisher' &&
                              (sortOrder === 'asc' ? (
                                <i className="fa fa-sort-up" />
                              ) : (
                                <i className="fa fa-sort-down" />
                              ))}
                          </th>
                          {/* <th className="p-2 col-2">Email Id</th> */}
                          {/* <th className="p-2">Locked</th> */}
                          <th
                            className="p-2 text-left col-2"
                            onClick={() => {
                              // handleTableHeaderSorting('userId')
                              sortTableData('license');
                            }}
                          >
                            License Name{' '}
                            {sortColumn === 'license' &&
                              (sortOrder === 'asc' ? (
                                <i className="fa fa-sort-up" />
                              ) : (
                                <i className="fa fa-sort-down" />
                              ))}
                          </th>
                          <th
                            className="p-2 text-left col-2"
                            onClick={() => {
                              // handleTableHeaderSorting('userId')
                              sortTableData('licenseType');
                            }}
                          >
                            License Type{' '}
                            {sortColumn === 'licenseType' &&
                              (sortOrder === 'asc' ? (
                                <i className="fa fa-sort-up" />
                              ) : (
                                <i className="fa fa-sort-down" />
                              ))}
                          </th>
                          <th
                            className="p-2 text-left col-2"
                            onClick={() => {
                              // handleTableHeaderSorting('userId')
                              sortTableData('licenseDeviceCount');
                            }}
                          >
                            License Count{' '}
                            {sortColumn === 'licenseDeviceCount' &&
                              (sortOrder === 'asc' ? (
                                <i className="fa fa-sort-up" />
                              ) : (
                                <i className="fa fa-sort-down" />
                              ))}
                          </th>
                          <th className="text-center p-2 col-2"> </th>
                        </tr>
                      </thead>

                      <tbody>{renderList}</tbody>
                    </table>
                  </div>
                </div>

                {loading && (
                  <p
                    className={`${
                      darkmode ? 'noRecordsDark' : 'noRecords'
                    } col-lg-10 col-md-12 mx-auto d-flex justify-content-center align-items-center`}
                    style={{height: '80px'}}
                  >
                    Loading...
                  </p>
                )}
                {!loading && listData.length === 0 && (
                  <p
                    className={`${
                      darkmode ? 'noRecordsDark' : 'noRecords'
                    } col-lg-10 col-md-12 mx-auto d-flex justify-content-center align-items-center`}
                    style={{height: '80px'}}
                  >
                    No Validated Software Found..
                  </p>
                )}
                {!loading && listData === null && (
                  <p
                    className={`${
                      darkmode ? 'noRecordsDark' : 'noRecords'
                    } col-lg-10 col-md-12 mx-auto d-flex justify-content-center align-items-center`}
                    style={{height: '80px'}}
                  >
                    Network Error...
                  </p>
                )}

                <div className="col-lg-10 col-md-12 mx-auto d-flex justify-content-between">
                  <div className="col-4"></div>
                  <div className="col-8 mt-1 text-right">
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="secondary"
                        id="vs-dropdown-download-schema"
                      >
                        Download Schema
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item onClick={fetchXMLSchema}>
                          Download XML Schema
                        </Dropdown.Item>
                        <Dropdown.Item onClick={fetchCSVSchema}>
                          Download CSV Schema
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>

                <UploadFormSlideIn
                  show={showUserForm}
                  onHide={() => setShowUserForm(false)}
                  softwareData={softwareData}
                />
              </div>
            </Col>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default TrustedSoftware;

import React, {useState} from 'react';
import {Form} from 'react-bootstrap';

interface NoOfRecordsProps {
  value: number;
  setValue: (value: number) => void;
  totalRecords?: number;
  minValue?: number;
}

const NoOfRecords = ({
  value,
  setValue,
  totalRecords = 100,
  minValue = 1
}: NoOfRecordsProps) => {
  const handleNumberOfRecordsChange = (inputValue: any) => {
    const numericValue = Math.max(
      minValue,
      Math.min(Number(inputValue), totalRecords)
    );
    return numericValue;
  };

  return (
    <div className="d-flex align-items-center">
      <div className="pr-2 pe-2 noOfRecords">No. of Records</div>
      <div className="pr-3 pe-3">
        <Form.Control
          type="number"
          className="w-80px"
          placeholder="no of records"
          value={value}
          onChange={(e) => {
            const numericValue = handleNumberOfRecordsChange(e.target.value);
            setValue(numericValue);
          }}
          id='no-of-records-input'
        />
      </div>
    </div>
  );
};

export default NoOfRecords;

import React from 'react';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
import {useTranslation} from 'react-i18next';
import {setWindowClass} from '@app/utils/helpers';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import {Col, Form, InputGroup, Row} from 'react-bootstrap';
import {PfButton} from '@profabric/react-components';
import {forgotPassword} from '../../services/swaggerAuth';

const ForgotPassword = () => {
  const [t] = useTranslation();

  const {handleChange, values, handleSubmit, touched, errors} = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Required')
    }),
    onSubmit: (values) => {
      toast.warn('Not yet functional');
      console.log('values', values);
      forgotPassword(values.email);
    }
  });

  setWindowClass('hold-transition login-page');

  return (
    <div className="login-root container d-flex align-items-center justify-content-center">
      <Row className="login-container">
        <Col
          className="left-side d-flex align-items-center justify-content-center"
          xs={12}
          md={6}
        >
          <img
            className="logoImg"
            src="/img/forgot_password_icon.png"
            alt=""
            style={{width: '180px'}}
          />
        </Col>
        <Col className="right-side">
          <div className="wrapper-container p-">
            <div className="mt-5">
              <h3>Forgot</h3>
              <h3>your password?</h3>
            </div>

            <form className="form-container mt-5" onSubmit={handleSubmit}>
              <div className="mt-3">
                <Form.Label>Email</Form.Label>
                <div className="mb-3">
                  <InputGroup className="mb-3">
                    <Form.Control
                      id="email"
                      name="email"
                      type="email"
                      placeholder="username@cyberzek.com"
                      onChange={handleChange}
                      value={values.email}
                      isInvalid={touched.email && !!errors.email}
                    />
                    {touched.email && errors.email ? (
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    ) : ''}
                  </InputGroup>
                </div>
              </div>

              <div className="mt-4">
                <button
                  className="filterButton w-100"
                  type="submit">
                  Reset Password
                </button>
              </div>
            </form>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ForgotPassword;

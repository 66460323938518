import React, {useEffect, useState} from 'react';
import {Button, Form, InputGroup, Modal} from 'react-bootstrap';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import {useDispatch, useSelector} from 'react-redux';
import {swaggerPostProfile, swaggerPutProfile} from '@app/services/swaggerAuth';
import {toast} from 'react-toastify';
import {setSelectedUser} from '@app/store/reducers/auth';
import {H} from 'chart.js/dist/chunks/helpers.core';
import {ValidationResult, formatDate, getStrengthColor, validatePassword} from '@app/utils/helpers';

const UserFormSlideIn = ({
  show,
  onHide,
  roleslist,
  fetchUsers
}: {
  show: boolean;
  onHide: any;
  roleslist: any;
  fetchUsers: any;
}) => {
  const [role, setRole] = useState('');
  const [name, setName] = useState('');
  const [userId, setUserId] = useState('');
  const [password, setPassword] = useState('');
  const [userToken, setUserToken] = useState('');
  const [active, setActive] = useState(true);
  const [invalidEmailError, setInvalidEmailError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [notes, setNotes] = useState('');
  const [validation, setValidation] = useState<ValidationResult | null>(null);

  const userEditMode = useSelector((state: any) => state.auth.userEditMode);
  const isAdmin = useSelector((state: any) => state.auth.isAdmin);
  const token = useSelector((state: any) => state.auth.token);
  const selectedUser = useSelector((state: any) => state.auth.selectedUser);
  const currentUser = useSelector((state: any) => state.auth.currentUser);
  const [passwordFocused, setPasswordFocused] = useState(false);
  const [hasStartedTyping, setHasStartedTyping] = useState(false);



  const userData = {
    name,
    userId,
    // password,
    roles: role,
    active,
    notes
    // token: userToken
  };

  const dispatch = useDispatch();

  const clearForm = () => {
    setRole('');
    setName('');
    setUserId('');
    setPassword('');
    setNotes('');
    setUserToken('');
    setActive(true);
  };
  const darkmode = useSelector((state: any) => state.account.darkmode);

  // console.log(selectedUser)

  const createOrUpdateUser = () => {
    // swaggerPostProfile(token, name, userId, password, role, active)
    if (selectedUser === null) {
      // new user
      swaggerPostProfile(token, password, userData)
        .then((response) => {
          toast.success('Form Submission Successful');
          clearForm();
          dispatch(setSelectedUser(null));
          onHide();
          fetchUsers();
        })
        .catch((err) => {
          toast.error('Form Submission Failed');
          clearForm();
        });
    } else {
      // update user
      swaggerPutProfile(token, userData)
        .then((response) => {
          toast.success('Form Submission Successful');
          clearForm();
          dispatch(setSelectedUser(null));
          onHide();
          fetchUsers();
        })
        .catch((err) => {
          toast.error('Form Submission Failed');
          // clearForm();
        });
    }
  };

  // Reset Formik errors upon close/inital render
  useEffect(() => {
    setTouched({}, false);
    setErrors({});
  }, [show]);

  useEffect(() => {
    if (selectedUser !== null) {
      // setRole(selectedUser.roles[0]);
      setRole(selectedUser.roles);
      setActive(selectedUser.active);
      setName(selectedUser.name);
      setNotes(selectedUser.notes);
      setUserId(selectedUser.userId);
      setPassword(selectedUser.password || '');
      setUserToken(selectedUser.token);
    } else {
      clearForm();
    }
  }, [selectedUser]);


  const {
    handleChange,
    handleBlur,
    values,
    handleSubmit,
    touched,
    errors,
    setTouched,
    setErrors
  } = useFormik({
    initialValues: {
      role,
      name,
      userId,
      password
    },
    validationSchema: Yup.object({
      role: Yup.string().required('Required'),
      name: Yup.string().required('Required'),
      userId: Yup.string().email('Invalid email address').required('Required'),
      password: Yup.string()
        .min(3, 'Must be 3 characters or more')
        .max(30, 'Must be 30 characters or less')
        .required('Required')
    }),
    onSubmit: () => {}
  });

  const handlePasswordFocus = () => {
    setPasswordFocused(true);
    setHasStartedTyping(true); 
  };

  const handlePasswordBlur = (e: any) => {
    handleBlur(e);
    setPasswordFocused(false);
  };

  const renderTitle = () => {
    let title = '';
    console.log('selectedUser', selectedUser);
    if (userEditMode) {
      if (selectedUser === null) {
        title = 'Add User';
      } else {
        title = 'Edit User';
      }
    } else if (!userEditMode || !isAdmin) {
      title = 'User Details';
    }
    return title;
  };

  const [isValid, setIsValid] = useState(true);

  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValid(emailRegex.test(userId));
  };
  // console.log('role', role);
  // console.log('name', name);
  // console.log('userId', userId);
  // console.log('password', password);
  // console.log('active', active);

  // useEffect(() => {
  //   console.log('formik touched', touched);
  //   console.log('formik errors', errors);
  // }, [touched, errors]);

  return (
    <SlidingPane
      isOpen={show}
      onRequestClose={onHide}
      className={`custom-slide-pane ${darkmode && 'darkSecondaryWrapper'}`}
      overlayClassName="custom-overlay-slide-pane"
      // title={<h3 className="text-center">{renderTitle()}</h3>}
      // closeIcon={<i className="fa-solid fa-xmark" />}
    >
      <div className="modalContentMain">
        <div className="modalHeader">
          <h3>{renderTitle()}</h3>

          <i
            onClick={() => {
              setTouched({}, false);
              setErrors({});
              onHide();
              clearForm();
              dispatch(setSelectedUser(null));
            }}
            className="fa-regular fa-circle-xmark"
            id='user-form-close-btn'
          ></i>
        </div>
        <div className="modalContent d-flex flex-column justify-content-between">
          <Form>
            <Form.Group className="mb-4" controlId="userId">
              <Form.Label>User ID</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                id='users-form-email-input'
                value={userId}
                onChange={(e) => {
                  setUserId(e.target.value);
                  handleChange(e);
                }}
                onBlur={(e: any) => {
                  handleBlur(e);

                  validateEmail()

                  // if (userId && !userId.includes('@')) {
                  //   setInvalidEmailError(`User ID should include '@' symbol`);
                  // } else {
                  //   setInvalidEmailError('');
                  // }

                }}
                disabled={!userEditMode || !isAdmin}
                // disabled
              />
              {!isValid && <div className='text-danger pl-2 pt-1'>User ID  is not valid!</div>}
              {/* {invalidEmailError && (
                <div className="text-danger pl-2 pt-1">{invalidEmailError}</div>
              )} */}
              {!invalidEmailError &&
                touched.userId &&
                errors.userId &&
                userId === '' && (
                  <div className="text-danger pl-2 pt-1">{errors.userId}</div>
                )}
            </Form.Group>
            <Form.Group className="mb-4" controlId="role">
              <Form.Label>Role</Form.Label>
              <Form.Control
                as="select"
                value={role}
                onChange={(e) => {
                  setRole(e.target.value);
                  handleChange(e);
                }}
                onBlur={handleBlur}
                disabled={!isAdmin}
                id='users-form-role-option'
              >
                <option value="">--Select--</option>
                {roleslist?.map((i: any, index: number) => (
                  <option key={index} value={i.key}>
                    {i.value}
                  </option>
                ))}
              </Form.Control>
              {touched.role && errors.role && role === '' && (
                <div className="text-danger pl-2 pt-1">{errors.role}</div>
              )}
            </Form.Group>
            <Form.Group
              className="mb-4 activeLabel"
              controlId="formBasicCheckbox"
            >
              <Form.Check
                type="checkbox"
                label="Active"
                checked={active}
                onChange={(e) => setActive(e.target.checked)}
                disabled={
                  !isAdmin || selectedUser?.userId === currentUser?.userId
                }
              />
            </Form.Group>
            <Form.Group className="mb-4" controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="name"
                placeholder="Enter name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  handleChange(e);
                }}
                onBlur={handleBlur}
                disabled={!isAdmin}
                id='users-form-name-input'
              />
              {touched.name && errors.name && name === '' && (
                <div className="text-danger pl-2 pt-1">{errors.name}</div>
              )}
            </Form.Group>

            {/* {console.log('password', password)} */}
            {isAdmin && (
              <>
                

                <InputGroup className="mb-1">
                  <Form.Label className="col-12 px-0">Password</Form.Label>
                  <Form.Control
                    id="user-form-password"
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Password"
                    autoComplete="off"
                    value={password}
                    maxLength={25}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      handleChange(e);
                      setValidation(validatePassword(e.target.value));
                      if (!hasStartedTyping) {
                        setHasStartedTyping(true);
                      }
                    }}
                    onFocus={handlePasswordFocus}
                    onBlur={handlePasswordBlur}
                    disabled={!userEditMode || !isAdmin}
                  />
                  {touched.password && errors.password ? (
                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                  ) : (
                    <InputGroup.Append>
                      <InputGroup.Text>
                        <i
                          className={`fa ${
                            showPassword ? 'fa-eye' : 'fa-eye-slash'
                          }`}
                          onClick={() => setShowPassword((show) => !show)}
                          aria-hidden="true"
                          id='users-form-eye'
                        />
                      </InputGroup.Text>
                    </InputGroup.Append>
                  )}


                </InputGroup>
                {touched.password && errors.password && password === '' && (
                <div className="text-danger pl-2 mb-3 pt-1">{errors.password}</div>
              )}
{(hasStartedTyping && passwordFocused) && (
  <>

<div className="password-strength-bar">
        <div
          className="password-strength-indicator"
          style={{
            width: `${(validation?.score || 0) / 8 * 100}%`,
            backgroundColor: getStrengthColor(validation?.score || 0)
          }}
        ></div>
      </div>
      <div>
        <p>Password Strength:</p>
        <ul>
          <li style={{ color: validation?.minLength ? 'green' : 'red' }}>
            Minimum 8 characters
          </li>
          <li style={{ color: validation?.hasUpperCase ? 'green' : 'red' }}>
            Includes uppercase letters
          </li>
          <li style={{ color: validation?.hasLowerCase ? 'green' : 'red' }}>
            Includes lowercase letters
          </li>
          <li style={{ color: validation?.hasNumber ? 'green' : 'red' }}>
            Includes numbers
          </li>
          <li style={{ color: validation?.hasSpecialChar ? 'green' : 'red' }}>
            Includes special characters
          </li>
          <li style={{ color: validation?.noSequentialChars ? 'green' : 'red' }}>
            No sequential characters
          </li>
          <li style={{ color: validation?.noRepetitivePatterns ? 'green' : 'red' }}>
            No repetitive patterns
          </li>
          <li style={{ color: validation?.notCommonPassword ? 'green' : 'red' }}>
            Not a common password
          </li>
        </ul>
      </div>
      </>)}
              </>
            )}

            <Form.Group className="mb-3" controlId="password">
              <Form.Label>Date Created</Form.Label>
              <Form.Control
                value={formatDate(selectedUser?.createdAt)}
                onBlur={handleBlur}
                disabled
                // disabled
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="password">
              <Form.Label>Notes</Form.Label>
              <textarea
                className="form-control"
                name=""
                id="users-form-notes-input"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                cols={30}
                rows={2}
                maxLength={1000}
                disabled={selectedUser}
              />
              {/* <p
                className={`mt-2 ${
                  notes.length >= 1000 ? 'visible' : 'invisible'
                }`}
                style={{color: 'red'}}
              >
                Warning: Exceeded 1000 words limit!
              </p> */}
            </Form.Group>
          </Form>

          <div
            className="d-flex"
            style={{marginTop: '50px'}}
            /* slide-pane-footer - class remvoed for fix */
          >
            <button
              type="button"
              className="mr-2 closeButton"
              onClick={() => {
                setTouched({}, false);
                setErrors({});
                onHide();
                clearForm();
                setInvalidEmailError('');
                dispatch(setSelectedUser(null));
              }}
              id='users-form-close-btn'
            >
              Close
            </button>
            {isAdmin && (
              <button
                type="button"
                className="applyButton"
                onClick={() => {
                  // clearForm();
                  // onHide();
                  createOrUpdateUser();
                }}
                disabled={ (userEditMode ? !userId ||
                  !name ||
                  !password ||
                  !role :
                  !userId ||
                  !name ||
                  !role )
                }
                id='users-form-submit-btn'
              >
                Submit
              </button>
            )}
          </div>
        </div>
      </div>
    </SlidingPane>
  );
};

export default UserFormSlideIn;

import Container from '@app/components/CustomDash/Container';
import {getConfigureDebugX} from '@app/services/debug';
import React, {useState} from 'react';
import {Form, Button, Col} from 'react-bootstrap';
import {toast} from 'react-toastify';

const ConfigureDebug = () => {
  const [inputValue, setInputValue] = useState('');
  const [outputValue, setOutputValue] = useState('');

  const handleSubmit = (e: any) => {
    e.preventDefault();
    getConfigureDebugX(inputValue)
      .then((res) => {
        setOutputValue(JSON.stringify(res.data));
      })
      .catch((err) => {
        toast.error('Error executing query');
        setOutputValue('');
      });
  };

  return (
    <Container>
      <div className="container-fluid ml-3">
        <div className="row d-flex justify-content-center align-items-center">
          <Col xs={10} className="mt-5">
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="inputField" className="row">
                <Form.Label className="col-2">Input</Form.Label>
                <div className="col-8">
                  <Form.Control
                    type="text"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                  />
                </div>
              </Form.Group>

              <Form.Group controlId="outputField" className="row">
                <Form.Label className="col-2">Output</Form.Label>
                <div className="col-8">
                  <Form.Control
                    as="textarea"
                    rows={13}
                    value={outputValue}
                    // onChange={(e) => setOutputValue(e.target.value)}
                    disabled
                  />
                </div>
              </Form.Group>

              <div className="d-flex row">
                <div className="col-2" />
                <div className="col-8 d-flex justify-content-between">
                  <button type="submit" className="filterButton mt-3 w-47">
                    Submit
                  </button>
                  <button
                    type="button"
                    className="filterButton mt-3 w-47"
                    onClick={() => {
                      setInputValue('');
                      setOutputValue('');
                    }}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </Form>
          </Col>
        </div>
      </div>
    </Container>
  );
};

export default ConfigureDebug;

import {createSlice} from '@reduxjs/toolkit';

interface iniState {
  
  image: string
  insight:boolean
  productivity:boolean
  rbac:boolean
  threats:boolean
  trustedSoftware:boolean
  darkmode:boolean | null
}

const initialState: iniState = {
  image:"",
  insight:false,
  productivity:false,
  rbac:false,
  threats:false,
  trustedSoftware:false,
  darkmode: null
};

export const AccountSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    setImage: (state, {payload}) => {
      state.image = payload;
    },
    setInsights: (state, {payload}) => {
      state.insight = payload;
    },
    setProductivity: (state, {payload}) => {
      state.productivity = payload;
    },
    setRBAC: (state, {payload}) => {
      state.rbac = payload;
    },
    setThreats: (state, {payload}) => {
      state.threats = payload;
    },
    setTrustedSoftware: (state, {payload}) => {
      state.trustedSoftware = payload;
    },
    setDarkMode : (state,{payload}) => {
      state.darkmode = payload
    }
    
  }
});

export const {setImage,setInsights,setProductivity,setRBAC,setThreats,setTrustedSoftware,setDarkMode} = AccountSlice.actions;

export default AccountSlice.reducer;

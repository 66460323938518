import {
  addTrustedSoftware,
  putTrustedSoftware
} from '@app/services/trustedSoftware';
import {listenerCount} from 'process';
import React, {useEffect, useState} from 'react';
import {Form} from 'react-bootstrap';
import {useSelector} from 'react-redux';
import SlidingPane from 'react-sliding-pane';
import {toast} from 'react-toastify';

type Props = {
  show: boolean;
  onHide: any;
  softwareData: any;
};

function UploadFormSlideIn({show, onHide, softwareData}: Props) {
  const [softwareId, setSoftwareId] = useState<number | undefined>();
  const [name, setName] = useState<string>('');
  const [active, setActive] = useState<boolean>(true);
  const [version, setVersion] = useState<string>('');
  const [publisher, setPublisher] = useState<string>('');
  const [license, setLicense] = useState<string>('');
  const [licenseType, setLicenseType] = useState<string>('');
  const [licenseDeviceCount, setLicenseCount] = useState<number | undefined>();
  const [comment, setComment] = useState<string>('');
  const isAdmin = useSelector((state: any) => state.auth.isAdmin);
  const token = useSelector((state: any) => state.auth.token);
  const darkmode = useSelector((state: any) => state.account.darkmode);


  console.log(softwareData);

  const putData = {
    id: softwareId,
    name,
    active,
    version,
    publisher,
    license,
    licenseType,
    licenseDeviceCount,
    comment
  };

  const userData = {
    name,
    active,
    version,
    publisher,
    license,
    licenseType,
    licenseDeviceCount,
    comment
  };

  const numberValidation = (value:any) => {
    if (!value) return value;
    
    const number = value.replace(/[^\d:.\d{1}]/g, '');

    // Make sure the number contains only 10 digits
    const numberWithTenDigits = number.slice(0, 10);

    return numberWithTenDigits;
}


  const handleSubmit = () => {
    if (name && version && publisher) {
      if (softwareData) {
        putTrustedSoftware(token, putData)
          .then((res) => {
            console.log(res);
            onHide();
            setSoftwareId(undefined);
            setName('');
            setVersion('');
            setPublisher('');
            setLicense('');
            setLicenseType('');
            setLicenseCount(undefined);
            setComment("")
            setActive(false)

            toast.success(res.data);
          })
          .catch((err) => {
            toast.error(err.response.data);
          });
      } else {
        addTrustedSoftware(token, userData)
          .then((res) => {
            console.log(res);
            onHide();
            setName('');
            setVersion('');
            setPublisher('');
            setLicense('');
            setLicenseType('');
            setLicenseCount(undefined);
            setComment("")
            setActive(false)

            toast.success(res.data);
          })
          .catch((err) => {
            toast.error(err.response.data);
          });
      }
    } else {
      toast.warning('name , version and publish field is required');
    }
  };
  useEffect(() => {
    if (softwareData) {
      setSoftwareId(softwareData.id);
      setActive(softwareData.active)
      setName(softwareData.name);
      setVersion(softwareData.version);
      setPublisher(softwareData.publisher);
      setLicense(softwareData.license);
      setLicenseType(softwareData.licenseType);
      setLicenseCount(softwareData.licenseDeviceCount);
      setComment(softwareData.comment)

    }else{
      setName('');
      setVersion('');
      setPublisher('');
      setLicense('');
      setLicenseType('');
      setLicenseCount(undefined);
      setComment("")
      setActive(false)
    }
  }, [softwareData]);

  return (
    <SlidingPane
      isOpen={show}
      onRequestClose={() => {
        onHide()
        setLicenseCount(undefined);
        setComment("")
      }}
      className={`custom-slide-pane ${darkmode && 'darkSecondaryWrapper'}`}
      overlayClassName="custom-overlay-slide-pane"
      // title={<h3 className="text-center">{renderTitle()}</h3>}
      // closeIcon={<i className="fa-solid fa-xmark" />}
    >
      <div className="modalContentMain">
        <div className="modalHeader">
          <h3>Edit Validated Software</h3>

          <i
            onClick={() => {
              onHide();
            }}
            className="fa-regular fa-circle-xmark"
            id='vs-form-close-btn'
          ></i>
        </div>
        <div className={`modalContent modalContentSoftware `}>
          <Form>
            <Form.Group className="mb-4" controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="name"
                required
                value={name}
                disabled={!isAdmin}
                id='vs-form-name-field'
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
              {/* {touched.name && errors.name && name === '' && (
                <div className="text-danger pl-2 pt-1">{errors.name}</div>
              )} */}
            </Form.Group>
            <Form.Group
              className="mb-4 activeLabel"
              controlId="formBasicCheckbox"
            >
              <Form.Check
                type="checkbox"
                label="Active"
                checked={active}
                disabled={!isAdmin}

                onChange={(e) => setActive(e.target.checked)}
            
                
              />
            </Form.Group>
            <Form.Group className="mb-4" controlId="userId">
              <Form.Label>Version</Form.Label>
              <Form.Control
                value={version}
                type="text"
                disabled={!isAdmin}
                id='vs-form-version-input'
                onChange={(e) => {
                  setVersion(e.target.value);
                }}

                // disabled
              />
              {/* {touched.userId && errors.userId && userId === '' && (
            <div className="text-danger pl-2 pt-1">{errors.userId}</div>
          )} */}
            </Form.Group>
            {/* {console.log('password', password)} */}
            <Form.Group className="mb-3" controlId="password">
              <Form.Label>Publisher</Form.Label>
              <Form.Control
                value={publisher}
                type="text"
                disabled={!isAdmin}
                id='vs-form-publisher-input'
                onChange={(e) => {
                  setPublisher(e.target.value);
                }}
              />
              {/* {touched.password && errors.password && password === '' && (
            <div className="text-danger pl-2 pt-1">{errors.password}</div>
          )} */}
            </Form.Group>
            <Form.Group className="mb-3" controlId="password">
              <Form.Label>License Name</Form.Label>
              <Form.Control
                type="text"
                value={license}
                disabled={!isAdmin}
                id='vs-form-license-input'
                onChange={(e) => {
                  setLicense(e.target.value);
                }}
              />
              {/* {touched.password && errors.password && password === '' && (
            <div className="text-danger pl-2 pt-1">{errors.password}</div>
          )} */}
            </Form.Group>
            <Form.Group className="mb-3" controlId="password">
              <Form.Label>License Type</Form.Label>
              <Form.Control
                type="text"
                disabled={!isAdmin}
                id='vs-form-license-type-input'
                value={licenseType}
                onChange={(e) => {
                  setLicenseType(e.target.value);
                }}
              />
              {/* {touched.password && errors.password && password === '' && (
            <div className="text-danger pl-2 pt-1">{errors.password}</div>
          )} */}
            </Form.Group>
            <Form.Group className="mb-3" controlId="password">
              <Form.Label>License Count</Form.Label>
              <Form.Control
                type="number"
                value={licenseDeviceCount}
                disabled={!isAdmin}
                min="0"
                onChange={(e) => {
                  setLicenseCount(parseInt(numberValidation(e.target.value)));
                }}
                id='vs-form-license-count-option'
              />
              {/* {touched.password && errors.password && password === '' && (
            <div className="text-danger pl-2 pt-1">{errors.password}</div>
              )} */}
            </Form.Group>
            <Form.Group className="mb-3" controlId="password">
              <Form.Label>Comment</Form.Label>
              <Form.Control
                type="text"
                value={comment}
                disabled={!isAdmin}
                onChange={(e) => {
                  setComment(e.target.value);
                }}
                id='vs-form-comment-input'
              />
              {/* {touched.password && errors.password && password === '' && (
            <div className="text-danger pl-2 pt-1">{errors.password}</div>
              )} */}
            </Form.Group>
          </Form>

          <div className="d-flex ">
            <button
              type="button"
              className="mr-2 closeButton"
              onClick={() => {
                onHide();
                setLicenseCount(undefined);

              }}
              id='vs-form-close-btn'
            >
              Close
            </button>
            {isAdmin && (
              <button
                type="button"
                className="applyButton"
                onClick={handleSubmit}
                // disabled = {name && version && publisher ? false : true}
                id='vs-form-submit-btn'
              >
                Submit
              </button>
            )}
          </div>
        </div>
      </div>
    </SlidingPane>
  );
}

export default UploadFormSlideIn;

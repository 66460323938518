import {createSlice} from '@reduxjs/toolkit';

interface iniState {
  allAgentProfiles: Array<any> | null;
  agentsPageNumber: number;
}

const initialState: iniState = {
  allAgentProfiles: [],
  agentsPageNumber: 1
};

export const agentsSlice = createSlice({
  name: 'agents',
  initialState,
  reducers: {
    setAllAgentProfiles: (state, {payload}) => {
      state.allAgentProfiles = payload;
    },
    setAgentsPageNumber: (state, {payload}) => {
      state.agentsPageNumber = payload;
    }
  }
});

export const {setAllAgentProfiles, setAgentsPageNumber} = agentsSlice.actions;

export default agentsSlice.reducer;

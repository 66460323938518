import Container from '@app/components/CustomDash/Container';
import React, {useState} from 'react';
import VulnerabilityModule from './VulnerabilityModule';
import RiskModule from './RiskModule';
import ControlsModule from './ControlsModule';
import { useSelector } from 'react-redux';
import { Col } from 'react-bootstrap';

const Threats = () => {
  const [activeTab, setActiveTab] = useState('vulnerabilityModule');

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };
  const darkmode = useSelector((state: any) => state.account.darkmode);


  return (
    <Container>
      <div>
      <div className="container-fluid">
          <div className="row d-flex justify-content-center align-items-center">
            <Col xs={12}>
              <div className='col-lg-10 col-md-12 mx-auto px-0 mt-3'>

            <VulnerabilityModule />
              </div>
            </Col>
          </div>
        </div>
             

                  {/* {activeTab === 'vulnerabilityModule' && ( */}
                    {/* <VulnerabilityModule /> */}
                  
                  {/* {activeTab === 'riskModule' && <RiskModule />} */}
                  {/* {activeTab === 'controlsModule' && <ControlsModule />} */}
               
            
      </div>
    </Container>
  );
};

export default Threats;

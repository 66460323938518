import {createSlice} from '@reduxjs/toolkit';

export interface AuthState {
  isLoggedIn: boolean;
  token: string | null;
  currentUser: any;
  isAdmin: boolean;
  allUserProfiles: Array<any> | null;
  usersPageNumber: number;
  selectedUser: any | null;
  currentUserPassword: string;
  userEditMode: boolean;
  clientIp: string;
  rbacDetails: any | null;
}

const initialState: AuthState = {
  isLoggedIn: !!localStorage.getItem('token'),
  token: localStorage.getItem('token'),
  currentUser: {
    email: 'mail@example.com',
    picture: null
  },
  isAdmin: false,
  allUserProfiles: null,
  usersPageNumber: 1,
  selectedUser: null,
  currentUserPassword: '',
  userEditMode: false,
  clientIp: '',
  rbacDetails: null
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginUser: (state, {payload}) => {
      localStorage.setItem('token', payload);
      state.isLoggedIn = true;
      state.token = payload;
    },
    logoutUser: (state) => {
      localStorage.removeItem('token');
      localStorage.removeItem('role');
      localStorage.removeItem('userName');
      state.currentUser = {};
      state.isLoggedIn = false;
      state.token = null;
    },
    loadUser: (state, {payload}) => {
      localStorage.setItem('role', payload.roles);
      localStorage.setItem('userName', payload.name);
      state.currentUser = payload;
    },
    setIsAdmin: (state, {payload}) => {
      state.isAdmin = payload;
    },
    setAllUserProfiles: (state, {payload}) => {
      state.allUserProfiles = payload;
    },
    setUsersPageNumber: (state, {payload}) => {
      state.usersPageNumber = payload;
    },
    setSelectedUser: (state, {payload}) => {
      state.selectedUser = payload;
    },
    setCurrentUserPassword: (state, {payload}) => {
      state.currentUserPassword = payload;
    },
    setUserEditMode: (state, {payload}) => {
      state.userEditMode = payload;
    },
    setClientIp: (state, action) => {
      state.clientIp = action.payload;
    },
    setRbacDetails: (state, action) => {
      state.rbacDetails = action.payload;
    }
  }
});

export const {
  loginUser,
  logoutUser,
  loadUser,
  setIsAdmin,
  setAllUserProfiles,
  setUsersPageNumber,
  setSelectedUser,
  setCurrentUserPassword,
  setUserEditMode,
  setClientIp,
  setRbacDetails
} = authSlice.actions;

export default authSlice.reducer;

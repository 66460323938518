/* eslint-disable prettier/prettier */
import React, {useEffect, useState} from 'react';

interface PaginationProps {
  currentPage: number;
  recordsPerPage: number;
  totalRecords: number;
  onPageChange: (page: number) => void;
  className?: string;
}

const Pagination = ({
  currentPage,
  recordsPerPage,
  totalRecords,
  onPageChange,
  className
}: PaginationProps) => {

  const totalPages = Math.ceil(totalRecords / recordsPerPage);
  const startRecord = (currentPage - 1) * recordsPerPage + 1;
  // const endRecord = currentPage * recordsPerPage;
  const endRecord = Math.min(currentPage * recordsPerPage, totalRecords);

  const handlePrevPage = () => onPageChange(currentPage - 1);
  const handleNextPage = () => onPageChange(currentPage + 1);

  return (
    <div className={`${className} paginationComponent`}>
      <button
        type="button"
        onClick={handlePrevPage}
        disabled={currentPage === 1}
      >
        <i id='pagination-left-btn' className="fa-solid fa-chevron-left" />
      </button>
      <span>
        Showing {`${startRecord}-${endRecord || 0}`} of {totalRecords || 0}
      </span>
      <button
        type="button"
        onClick={handleNextPage}
        disabled={currentPage === totalPages}
      >
        <i id='pagination-right-btn' className="fa-solid fa-chevron-right" />
      </button>
    </div>
  );
};

export default Pagination;

import { formatDate } from '@app/utils/helpers';
import React, { useState } from 'react'
import { useSelector } from 'react-redux';

type Props = {
  HistoryList:any
  setHistoryList:any
  historyPaginatedData:any
}

function BrowserHistory({HistoryList,setHistoryList,historyPaginatedData}: Props) {
  const darkmode = useSelector((state: any) => state.account.darkmode);

  console.log(historyPaginatedData)

  const [sortOrder, setSortOrder] = useState('asc');
  const [sortColumn, setSortColumn] = useState(null);

  const sortTableData = (column: any) => {
    const sortedData = [...HistoryList];

    // *** NEW IMPLEMENTATION ***
    if (sortColumn === column) {
      sortedData.reverse();
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      if (sortOrder === 'asc' || sortOrder === null) {
        // sort aesc if the current order is asc or null
        sortedData.sort((a, b) => (a[column] < b[column] ? -1 : 1));
        setSortColumn(column);
        setSortOrder('asc');
      } else {
        sortedData.sort((a, b) => (a[column] > b[column] ? -1 : 1)); // sort desc if the current order is desc
        setSortColumn(column);
        setSortOrder('desc');
      }
    }

    setHistoryList(sortedData);
  };
  return (
    <div className="agentAppUsage">
      {HistoryList?.length === 0 ? (
        <div className={` ${darkmode ? 'noRecordsDark' : 'noRecords'}`}>
          No History Found
        </div>
      ) : (
        <table
          className={`w-100 customTable   ${
            darkmode ? 'darkTable darkDetailsCustomTable' : ' lighttable'
          }`}
        >
          <thead>
            <tr>
              <th
                className="col-4 text-left py-3 pl-4"
                onClick={() => {
                  // handleTableHeaderSorting('userId')
                  sortTableData('appName');
                }}
              >
                URI
                {sortColumn === 'appName' &&
                  (sortOrder === 'asc' ? (
                    <i className="fa fa-sort-up" />
                  ) : (
                    <i className="fa fa-sort-down" />
                  ))}
              </th>
                <th
                  className="col-3 text-left py-2 pl-4"
                  onClick={() => {
                    // handleTableHeaderSorting('userId')
                    sortTableData('windowTitle');
                  }}
                >
                  Title
                  {sortColumn === 'windowTitle' &&
                    (sortOrder === 'asc' ? (
                      <i className="fa fa-sort-up" />
                    ) : (
                      <i className="fa fa-sort-down" />
                    ))}
                </th>
           
              <th
                className="col-2 text-left pl-4"
                onClick={() => {
                  // handleTableHeaderSorting('userId')
                  sortTableData('usageDate');
                }}
              >
                Domain
                {sortColumn === 'usageDate' &&
                  (sortOrder === 'asc' ? (
                    <i className="fa fa-sort-up" />
                  ) : (
                    <i className="fa fa-sort-down" />
                  ))}
              </th>
              <th
                className="col-2 text-left pl-4"
                onClick={() => {
                  // handleTableHeaderSorting('userId')
                  sortTableData('durationSecs');
                }}
              >
                Last Visited
                {sortColumn === 'durationSecs' &&
                  (sortOrder === 'asc' ? (
                    <i className="fa fa-sort-up" />
                  ) : (
                    <i className="fa fa-sort-down" />
                  ))}
              </th>
           
            </tr>
          </thead>
          <tbody className="mb-3">
            {historyPaginatedData?.map((data: any) => (
              <tr key={data.id}>
                {/* <td>{data.id}</td> */}
                <td className="tooltipDiv text-left pl-4">
                  {data.url.length>40 ?data.url.slice(0,40)+'...' : data.url}
                  <p className="tooltipText" style={{"overflowWrap":"break-word"}}>{data.url}</p>
                  </td>
                <td className=" text-left pl-4">{data.title}</td>
                <td className=" text-left pl-4">{data.domain}</td>
               
                <td className=" text-left pl-4">
                  {formatDate(data.lastVisited)}
                </td>
               
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  )
}

export default BrowserHistory
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {useEffect, useState} from 'react';
import {ContentHeader} from '@app/components';
import {useDispatch, useSelector} from 'react-redux';
import {
  getfilteredUsers,
  getUserRoles,
  swaggerGetAllUsers
} from '@app/services/swaggerAuth';
import {
  setIsAdmin,
  setAllUserProfiles,
  setSelectedUser,
  setUserEditMode,
  setUsersPageNumber
} from '@app/store/reducers/auth';
import {
  Button,
  Col,
  Collapse,
  Form,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import {formatDate} from '@app/utils/helpers';
import Container from '@app/components/CustomDash/Container';
import ReactPaginate from 'react-paginate';
import {USERS_RECORDS_PER_PAGE} from '@app/services/constants/config';
import InfoIcon from '@app/components/common/InfoIcon';
import Pagination from '@app/components/common/Pagination';
import {useNavigate} from 'react-router-dom';

const Productivity = () => {
  const navigate = useNavigate();

  const token = useSelector((state: any) => state.auth.token);
  const isLoggedIn = useSelector((state: any) => state.auth.isLoggedIn);
  const currentUser = useSelector((state: any) => state.auth.currentUser);
  const isAdmin = useSelector((state: any) => state.auth.isAdmin);
  const allUserProfiles = useSelector(
    (state: any) => state.auth.allUserProfiles
  );
  const usersPageNumber = useSelector(
    (state: any) => state.auth.usersPageNumber
  );
  const [showUserForm, setShowUserForm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [openFilter, setOpenFilter] = useState(false);

  // localstates for filtering
  const [userId, setUserId] = useState('');
  const [name, setName] = useState('');
  const [role, setRole] = useState('');
  const [active, setActive] = useState('');
  const [roles, setRoles] = useState<any>([]);

  // Perform array slicing based on the current page
  const startIndex = (usersPageNumber - 1) * USERS_RECORDS_PER_PAGE;
  const endIndex = usersPageNumber * USERS_RECORDS_PER_PAGE;
  const userPaginatedData = allUserProfiles?.slice(startIndex, endIndex);
  const darkmode = useSelector((state: any) => state.account.darkmode);

  /**
   * Table Sorting
   * -------------
   */
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortColumn, setSortColumn] = useState(null);

  const sortTableData = (column: any) => {
    const sortedData = [...allUserProfiles];

    // *** NEW IMPLEMENTATION ***
    // If One column asc then next column also asc, if one dsc, then next also dsc..
    if (sortColumn === column) {
      sortedData.reverse();
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else if (sortOrder === 'asc' || sortOrder === null) {
      // sort aesc if the current order is asc or null
      sortedData.sort((a, b) => (a[column] < b[column] ? -1 : 1));
      setSortColumn(column);
      setSortOrder('asc');
    } else {
      sortedData.sort((a, b) => (a[column] > b[column] ? -1 : 1)); // sort desc if the current order is desc
      setSortColumn(column);
      setSortOrder('desc');
    }

    dispatch(setAllUserProfiles(sortedData));
  };

  const dispatch = useDispatch();

  const checkIfAdmin = (user: any) => {
    if (user.roles === 'ADMIN') {
      dispatch(setIsAdmin(true));
    } else {
      dispatch(setIsAdmin(false));
    }
  };

  const fetchAllUsers = (token: string) => {
    swaggerGetAllUsers(token)
      .then((response) => {
        const {data} = response;
        console.log('allUsers response', data);
        dispatch(setAllUserProfiles(data));
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        dispatch(setAllUserProfiles([]));
        setLoading(false);
      });
  };

  const fetchFilteredUsers = () => {
    getfilteredUsers(token, userId, name, role, active)
      .then((response) => {
        const {data} = response;
        console.log('Filtered users response', data);
        dispatch(setAllUserProfiles(data));
        setLoading(false);
      })
      .catch((err) => {
        dispatch(setAllUserProfiles([]));
        setLoading(false);
      });
  };

  const handleFilterApply = () => {
    fetchFilteredUsers();
  };

  const clearFilterForm = () => {
    setUserId('');
    setName('');
    setRole('');
    setActive('');
    setTimeout(() => {
      fetchAllUsers(token);
    }, 100);
  };

  const handleFilterClose = () => {};

  const handleAddUsers = () => {
    dispatch(setUserEditMode(true));
    dispatch(setSelectedUser(null));
    setShowUserForm(true);
  };

  const handleUserOnClick = (user: any, e: any | null) => {
    dispatch(setSelectedUser(user));
    setShowUserForm(true);
    dispatch(setUserEditMode(false));
  };

  // Handler function for page change
  const handlePageChange = (page: number) => {
    dispatch(setUsersPageNumber(page));
  };

  useEffect(() => {
    if (currentUser) {
      checkIfAdmin(currentUser);
    }
  }, [currentUser]);

  // removed unness API call
  useEffect(() => {
    if (isLoggedIn && token) {
      fetchAllUsers(token);
    }
  }, [isLoggedIn, token]);

  useEffect(() => {
    getUserRoles(token)
      .then((res) => {
        setRoles(
          Object.entries(res.data).map(([key, value]) => ({key, value}))
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (userPaginatedData?.length === 0) {
      dispatch(setUsersPageNumber(1));
    }
  }, [userPaginatedData]);

  const renderUserProfiles = userPaginatedData?.map((user: any) => (
    <tr
      key={user.userId}
      // User Form Modal @ Bottom
      className=""
      style={{cursor: 'pointer'}}
    >
      <td
        className="text-left pl-4"
        onClick={(e) => navigate('/productivity-details')}
      >
        {user.name}
      </td>
      <td
        className="text-left pl-4"
        onClick={(e) => navigate('/productivity-details')}
      ></td>
      <td
        className="text-center"
        onClick={(e) => navigate('/productivity-details')}
      ></td>
      <td className="text-center">{/* <span>{user.roles}</span> */}</td>
      <td
        className="text-center"
        onClick={(e) => navigate('/productivity-details')}
      ></td>
      <td className="text-center">
        {/* {user.active ? (
          <i className="fa-regular fa-circle-check" />
        ) : (
          <i className="fa-sharp fa-regular fa-circle-xmark" />
        )} */}
      </td>
      <td
        className="text-center p-2 col-2 pr-4"
        onClick={(e) => navigate('/productivity-details')}
      >
        <i className="fa-solid fa-chevron-right" />
      </td>
      <td className="text-center p-2 col-2 pr-4">
        <OverlayTrigger
          overlay={<Tooltip id=""> Open in new tab</Tooltip>}
          placement="top"
          delay={200}
        >
          <a
            href={`/productivity-details`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa-regular fa-window-restore"></i>
          </a>
        </OverlayTrigger>
      </td>
    </tr>
  ));

  return (
    <Container>
      <div>
        <div className="container-fluid">
          <div className="row d-flex justify-content-center align-items-center">
            <Col xs={12}>
              <div>
                {/* Buttons Container */}
                <div className="mt-2 FilterButtonContainer col-lg-10 col-md-12 mx-auto pt-2 mb-2 d-flex justify-content-between">
                  <h3 className="subHeaders">  </h3>
                  {/* Filter & Pagination */}
                  {/* <div className="d-flex align-items-center">
                    {allUserProfiles?.length !== 0 && (
                      <div className="paginationContainer">
                        <Pagination
                          className="mr-1"
                          currentPage={usersPageNumber}
                          recordsPerPage={USERS_RECORDS_PER_PAGE}
                          totalRecords={allUserProfiles?.length}
                          onPageChange={(page: number) =>
                            dispatch(setUsersPageNumber(page))
                          }
                        />
                      </div>
                    )}

                    <button
                      type="button"
                      className="m-1 filterButton"
                      onClick={() => setOpenFilter(!openFilter)}
                      aria-expanded={openFilter}
                      aria-controls="example-collapse-text"
                    >
                      <i className="fa-solid fa-filter" />
                      <span className="pl-1">Filter</span>
                    </button>
                  </div> */}
                </div>

                {/* <div className="d-flex flex-column mt-3 px-2 pb-3"> */}
                {/* Filter Container */}
                {/* {openFilter && (
                  <section className="FilterOption col-lg-10 col-md-12 mx-auto mb-0 d-flex  flex-column">
                    <Form className="d-flex flex-wrap justify-content-between">
                      <Form.Group
                        controlId="role"
                        className="col-lg-3 col-md-3 col-12"
                      >
                        <Form.Label>User Id</Form.Label>
                        <Form.Control
                          type="search"
                          placeholder="Enter email"
                          value={userId}
                          onChange={(e) => {
                            setUserId(e.target.value);
                            // handleChange(e);
                          }}
                        />
                      </Form.Group>
                      <Form.Group
                        controlId="role"
                        className="col-lg-3 col-md-3 col-12"
                      >
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="search"
                          placeholder="Enter name"
                          value={name}
                          onChange={(e) => {
                            setName(e.target.value);
                            // handleChange(e);
                          }}
                        />
                      </Form.Group>
                      <Form.Group
                        controlId="role"
                        className="col-lg-3 col-md-3 col-12"
                      >
                        <Form.Label>Role</Form.Label>
                        <Form.Control
                          as="select"
                          value={role}
                          onChange={(e) => {
                            setRole(e.target.value);
                            // handleChange(e);
                          }}
                        >
                          <option value="">--Select--</option>
                          {roles?.map((i: any, index: number) => (
                            <option key={index} value={i.key}>
                              {i.value}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                      <Form.Group
                        controlId="role"
                        className="col-lg-3 col-md-3 col-12"
                      >
                        <Form.Label>Status</Form.Label>
                        <Form.Control
                          as="select"
                          value={active}
                          onChange={(e) => {
                            setActive(e.target.value);
                          }}
                        >
                          <option value="">--Select--</option>
                          <option value="Active">Active</option>
                          <option value="Inactive">Inactive</option>
                        </Form.Control>
                      </Form.Group>
                    </Form>

                    <div className="d-flex justify-content-start mt-4 pl-1">
                      <button
                        type="button"
                        className="m-1 pointer"
                        onClick={(e) => handleFilterApply()}
                      >
                        Apply
                      </button>
                      <button
                        type="button"
                        className="m-1 pointer"
                        onClick={() => clearFilterForm()}
                      >
                        Clear
                      </button>
                      <button
                        type="button"
                        className="Close m-1 pointer"
                        onClick={() => setOpenFilter(false)}
                      >
                        Close
                      </button>
                    </div>
                  </section>
                )} */}

                {/*  */}
                {/* {allUserProfiles?.length !== 0 && (
                  <div className="mobilePaginationContainer">
                    <Pagination
                      className="mr-1"
                      currentPage={usersPageNumber}
                      recordsPerPage={USERS_RECORDS_PER_PAGE}
                      totalRecords={allUserProfiles?.length}
                      onPageChange={(page: number) =>
                        dispatch(setUsersPageNumber(page))
                      }
                    />
                  </div>
                )} */}

                {/* Table */}
                <div className="card-body col-lg-10 col-md-12 mx-auto px-0 pb-3">
                  <div className="d-flex tableContainer overflow-auto flex-column">
                    <table
                      className={`customTable ${darkmode ? 'darkTable' : ''}`}
                    >
                      <thead className="">
                        <tr>
                          <th
                            className="p-2 col-2 text-left pl-4"
                            onClick={() => {
                              // sortTableData('userId');
                            }}
                          >
                            Managed Endpoint ID
                            {/* {sortColumn === 'userId' &&
                              (sortOrder === 'asc' ? (
                                <i className="fa fa-sort-up" />
                              ) : (
                                <i className="fa fa-sort-down" />
                              ))} */}
                          </th>
                          <th
                            className="p-2 col-2 text-left pl-4"
                            onClick={() => {
                              // sortTableData('userId');
                            }}
                          >
                            User Name
                            {/* {sortColumn === 'userId' &&
                              (sortOrder === 'asc' ? (
                                <i className="fa fa-sort-up" />
                              ) : (
                                <i className="fa fa-sort-down" />
                              ))} */}
                          </th>
                          <th
                            className="p-2 col-3"
                            onClick={() => {
                              // sortTableData('name');
                            }}
                          >
                            Uptime{' '}
                            {/* {sortColumn === 'name' &&
                              (sortOrder === 'asc' ? (
                                <i className="fa fa-sort-up" />
                              ) : (
                                <i className="fa fa-sort-down" />
                              ))} */}
                          </th>
                          <th
                            className="p-2 col-2"
                            onClick={() => {
                              // handleTableHeaderSorting('role')
                              // sortTableData('roles');
                            }}
                          >
                            Process Count
                            {/* {sortColumn === 'roles' &&
                              (sortOrder === 'asc' ? (
                                <i className="fa fa-sort-up" />
                              ) : (
                                <i className="fa fa-sort-down" />
                              ))} */}
                          </th>
                          {/* <th className="p-2 col-2">Email Id</th> */}
                          {/* <th className="p-2">Locked</th> */}
                          <th
                            className="p-2 col-2"
                            onClick={() => {
                              // handleTableHeaderSorting('lastLogin')
                              // sortTableData('lastLogin');
                            }}
                          >
                            Boot Time
                            {/* {sortColumn === 'lastLogin' &&
                              (sortOrder === 'asc' ? (
                                <i className="fa fa-sort-up" />
                              ) : (
                                <i className="fa fa-sort-down" />
                              ))} */}
                          </th>
                          <th
                            className="p-2 col-2"
                            onClick={() => {
                              // handleTableHeaderSorting('active')
                              // sortTableData('active');
                            }}
                          >
                            Login
                            {/* {sortColumn === 'active' &&
                              (sortOrder === 'asc' ? (
                                <i className="fa fa-sort-up" />
                              ) : (
                                <i className="fa fa-sort-down" />
                              ))} */}
                          </th>
                          <th className="text-center p-2 col-2"> </th>
                          <th className="text-center p-2 col-2"> </th>
                        </tr>
                      </thead>
                      {!loading && userPaginatedData.length > 0 && (
                        <tbody className="">{renderUserProfiles}</tbody>
                      )}
                    </table>
                  </div>
                </div>
                {/* </div> */}
                {loading && (
                  <p
                    className="d-flex justify-content-center align-items-center"
                    style={{height: '80px'}}
                  >
                    Loading...
                  </p>
                )}
                {!loading && userPaginatedData.length === 0 && (
                  <p
                    className="d-flex justify-content-center align-items-center"
                    style={{height: '80px'}}
                  >
                    No Productivity Found..
                  </p>
                )}
                {!loading && userPaginatedData === null && (
                  <p
                    className="d-flex justify-content-center align-items-center"
                    style={{height: '80px'}}
                  >
                    Network Error...
                  </p>
                )}
              </div>
            </Col>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Productivity;

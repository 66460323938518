/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {useEffect, useState} from 'react';
import {ContentHeader} from '@app/components';
import {useDispatch, useSelector} from 'react-redux';
import {
  getfilteredUsers,
  getUserRoles,
  swaggerGetAllUsers
} from '@app/services/swaggerAuth';
import {
  setAllUserProfiles,
  setSelectedUser,
  setUserEditMode,
  setUsersPageNumber
} from '@app/store/reducers/auth';
import UserFormSlideIn from '@app/pages/users/UserFormSlideIn';
import {Button, Col, Collapse, Form} from 'react-bootstrap';
import './Users.css';
import {formatDate, handleInputChange} from '@app/utils/helpers';
import Container from '@app/components/CustomDash/Container';
import ReactPaginate from 'react-paginate';
import Pagination from '@app/components/common/Pagination';
import NoOfRecords from '@app/components/common/NoOfRecords';
import {RECORDS_PER_PAGE} from '@app/services/constants/config';

const Users = () => {
  const dispatch = useDispatch();

  const token = useSelector((state: any) => state.auth.token);
  const isLoggedIn = useSelector((state: any) => state.auth.isLoggedIn);
  const isAdmin = useSelector((state: any) => state.auth.isAdmin);
  const allUserProfiles = useSelector(
    (state: any) => state.auth.allUserProfiles
  );
  const usersPageNumber = useSelector(
    (state: any) => state.auth.usersPageNumber
  );
  const darkmode = useSelector((state: any) => state.account.darkmode);
  const [showUserForm, setShowUserForm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [openFilter, setOpenFilter] = useState(false);
  const [numberOfRecords, setNumberOfRecords] = useState(RECORDS_PER_PAGE);

  // localstates for filtering
  const [userId, setUserId] = useState('');
  const [name, setName] = useState('');
  const [role, setRole] = useState('');
  const [active, setActive] = useState('');
  const [roles, setRoles] = useState<any>([]);

  // Perform array slicing based on the current page
  const startIndex = (usersPageNumber - 1) * numberOfRecords;
  const endIndex = usersPageNumber * numberOfRecords;
  const userPaginatedData = allUserProfiles?.slice(startIndex, endIndex);

  /**
   * Table Sorting
   * -------------
   */
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortColumn, setSortColumn] = useState(null);

  const sortTableData = (column: any) => {
    const sortedData = [...allUserProfiles];

    // *** NEW IMPLEMENTATION ***
    // If One column asc then next column also asc, if one dsc, then next also dsc..
    if (sortColumn === column) {
      sortedData.reverse();
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else if (sortOrder === 'asc' || sortOrder === null) {
      // sort aesc if the current order is asc or null
      sortedData.sort((a, b) => (a[column] < b[column] ? -1 : 1));
      setSortColumn(column);
      setSortOrder('asc');
    } else {
      sortedData.sort((a, b) => (a[column] > b[column] ? -1 : 1)); // sort desc if the current order is desc
      setSortColumn(column);
      setSortOrder('desc');
    }

    dispatch(setAllUserProfiles(sortedData));
  };

  

  const fetchAllUsers = (token: string) => {
    swaggerGetAllUsers(token)
      .then((response) => {
        const {data} = response;
        dispatch(setAllUserProfiles(data));
        localStorage.setItem('cachedUsersData', JSON.stringify(data));
        setLoading(false);
      })
      .catch((err) => {
        dispatch(setAllUserProfiles([]));
        setLoading(false);
      });
  };

  const fetchFilteredUsers = () => {
    getfilteredUsers(token, userId, name, role, active)
      .then((response) => {
        const {data} = response;
        dispatch(setAllUserProfiles(data));
        setLoading(false);
      })
      .catch((err) => {
        dispatch(setAllUserProfiles([]));
        setLoading(false);
      });
  };

  const handleFilterApply = () => {
    fetchFilteredUsers();
  };

  const clearFilterForm = () => {
    setUserId('');
    setName('');
    setRole('');
    setActive('');
    setTimeout(() => {
      fetchAllUsers(token);
    }, 100);
  };

  const handleFilterClose = () => {};

  const handleAddUsers = () => {
    dispatch(setUserEditMode(true));
    dispatch(setSelectedUser(null));
    setShowUserForm(true);
  };

  const handleUserOnClick = (user: any, e: any | null) => {
    dispatch(setSelectedUser(user));
    setShowUserForm(true);
    dispatch(setUserEditMode(false));
  };

  // Handler function for page change
  const handlePageChange = (page: number) => {
    dispatch(setUsersPageNumber(page));
  };

  const fetchInitialData = (token: string) => {
    const cachedData = localStorage.getItem('cachedUsersData');
    if (cachedData) {
      dispatch(setAllUserProfiles(JSON.parse(cachedData)));
      setLoading(false);
    } else {
      fetchAllUsers(token); // Fetch data if not cached
    }
  };



  // removed unness API call
  useEffect(() => {
    if (isLoggedIn && token) {
      fetchInitialData(token);
    }
  }, [isLoggedIn, token]);

  useEffect(() => {
    getUserRoles(token)
      .then((res) => {
        setRoles(
          Object.entries(res.data).map(([key, value]) => ({key, value}))
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // Pagination Bugfix
  useEffect(() => {
    if (userPaginatedData?.length === 0) {
      dispatch(setUsersPageNumber(1));
    }
  }, [userPaginatedData]);

  const renderUserProfiles = userPaginatedData?.map((user: any) => (
    <tr
      key={user.userId}
      onClick={(e) => handleUserOnClick(user, e)}
      // User Form Modal @ Bottom
      className=""
      style={{cursor: 'pointer'}}
    >
      <td className="text-left pl-4">{user.userId}</td>
      <td className="text-center">{user.name}</td>
      <td className="text-center">
        {/* for roles array */}
        {/* {user.roles.map((role: string) => (
          <span key={role}>{role} </span>
        ))} */}
        <span>{user.roles}</span>
      </td>
      {/* <td className=" ">{user.userId}</td> */}
      {/* <td className=" "> </td> */}
      <td className="text-center">{formatDate(user.lastLogin)}</td>
      <td className="text-center">
        {user.active ? (
          <i className="fa-regular fa-circle-check" />
        ) : (
          <i className="fa-sharp fa-regular fa-circle-xmark" />
        )}
      </td>
      <td className="text-center p-2 col-2 pr-4">
        <i id='users-arrow' className="fa-solid fa-chevron-right" />
      </td>
    </tr>
  ));

  return (
    <Container>
      <div>
        <div className="container-fluid">
          <div className="row d-flex justify-content-center align-items-center">
            <Col xs={12}>
              <div>
                {/* Buttons Container */}
                <div className="mt-2 FilterButtonContainer col-lg-10 col-md-12 mx-auto pt-2 mb-2 d-flex justify-content-between">
                  <h3 className="subHeaders">  </h3>
                  <div className="d-flex flex-wrap align-items-center justify-content-end col-10">
                    <NoOfRecords
                      value={numberOfRecords}
                      setValue={(value) => setNumberOfRecords(value)}
                      totalRecords={allUserProfiles?.length}
                    />

                    {allUserProfiles?.length !== 0 && (
                      <div className="paginationContainer">
                        <Pagination
                          className="mr-1"
                          currentPage={usersPageNumber}
                          recordsPerPage={numberOfRecords}
                          totalRecords={allUserProfiles?.length}
                          onPageChange={(page: number) =>
                            dispatch(setUsersPageNumber(page))
                          }
                        />
                      </div>
                    )}

                    {isAdmin && (
                      <button
                        type="button"
                        className="m-1 filterButton mainFilterButton"
                        onClick={handleAddUsers}
                        id='users-add-btn'
                      >
                        <i className="fa-solid fa-plus" />
                        <span className="pl-1">Add Users</span>
                      </button>
                    )}
                    <button
                      type="button"
                      className="m-1 filterButton mainFilterButton"
                      onClick={() => setOpenFilter(!openFilter)}
                      aria-expanded={openFilter}
                      aria-controls="example-collapse-text"
                      id='users-filter-btn'
                    >
                      <i className="fa-solid fa-filter" />
                      <span className="pl-1">Filter</span>
                    </button>
                  </div>
                </div>

                {/* <div className="d-flex flex-column mt-3 px-2 pb-3"> */}
                {/* Filter Container */}
                {openFilter && (
                  <section
                    className={`FilterOption ${
                      darkmode && 'darkSecondaryWrapper'
                    } col-lg-10 col-md-12 mx-auto mb-0 d-flex  flex-column`}
                  >
                    <Form className="d-flex flex-wrap justify-content-between">
                      <Form.Group
                        controlId="role"
                        className="col-lg-3 col-md-3 col-12"
                      >
                        <Form.Label>User ID</Form.Label>
                        <Form.Control
                          type="search"
                          placeholder="Enter email"
                          value={userId}
                          onChange={(e) => {
                            setUserId(e.target.value);
                            // handleChange(e);
                          }}
                          id='users-user-id-input'
                        />
                      </Form.Group>
                      <Form.Group
                        controlId="role"
                        className="col-lg-3 col-md-3 col-12"
                      >
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="search"
                          placeholder="Enter name"
                          value={name}
                          onChange={(e) => {
                            setName(e.target.value);
                            // handleChange(e);
                          }}
                          id='users-name-input'
                        />
                      </Form.Group>
                      <Form.Group
                        controlId="role"
                        className="col-lg-2 col-md-3 col-12"
                      >
                        <Form.Label>Role</Form.Label>
                        <Form.Control
                          as="select"
                          value={role}
                          onChange={(e) => {
                            setRole(e.target.value);
                            // handleChange(e);
                          }}
                          id='users-role-option'
                        >
                          <option value="">--Select--</option>
                          {roles?.map((i: any, index: number) => (
                            <option key={index} value={i.key}>
                              {i.value}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                      <Form.Group
                        controlId="role"
                        className="col-lg-2 col-md-3 col-12"
                      >
                        <Form.Label>Status</Form.Label>
                        <Form.Control
                          as="select"
                          value={active}
                          onChange={(e) => {
                            setActive(e.target.value);
                            // handleChange(e);
                          }}
                          id='users-status-option'
                        >
                          <option value="">--Select--</option>
                          <option value="Active">Active</option>
                          <option value="Inactive">Inactive</option>
                        </Form.Control>
                      </Form.Group>
                      {/* <Form.Group
                        controlId="role"
                        className="col-lg-2 col-md-3 col-sm-12 col-12"
                      >
                        <Form.Label className="text-nowrap">
                          No of Records
                        </Form.Label>
                        <Form.Control
                          type="number"
                          className="w-80px"
                          placeholder="set page number"
                          value={numberOfRecords}
                          onChange={(e) => {
                            setNumberOfRecords(
                              handleInputChange(e.target.value)
                            );
                          }}
                        />
                      </Form.Group> */}
                    </Form>

                    <div className="d-flex flex-wrap justify-content-center mt-4 pl-1">
                      <button
                        type="button"
                        className="m-1 pointer alertFilterButton"
                        onClick={(e) => handleFilterApply()}
                        id='users-apply-btn'
                      >
                        Apply
                      </button>
                      <button
                        type="button"
                        className="m-1 pointer alertFilterButton"
                        onClick={() => clearFilterForm()}
                        id='users-clear-btn'
                      >
                        Clear
                      </button>
                      <button
                        type="button"
                        className="Close m-1 pointer alertFilterButton"
                        onClick={() => setOpenFilter(false)}
                        id='users-close-btn'
                      >
                        Close
                      </button>
                      {/* <div className="btn text-white  m-1 apllyBTn">Apply</div>
                        <div className="btn  m-1 clearBtn">Clear</div>
                        <div className="btn btn-danger m-1">Close</div> */}
                    </div>
                  </section>
                )}

                {allUserProfiles?.length !== 0 && (
                  <div className="mobilePaginationContainer">
                    <Pagination
                      className="mr-1"
                      currentPage={usersPageNumber}
                      recordsPerPage={numberOfRecords}
                      totalRecords={allUserProfiles?.length}
                      onPageChange={(page: number) =>
                        dispatch(setUsersPageNumber(page))
                      }
                    />
                  </div>
                )}

                {/* Table */}
                <div className="card-body col-lg-10 col-md-12 mx-auto px-0 pb-3">
                  <div className="d-flex tableContainer overflow-auto flex-column">
                    <table
                      className={`customTable ${darkmode ? 'darkTable' : ''}`}
                    >
                      <thead className="">
                        <tr>
                          <th
                            className="p-2 col-2 text-left pl-4"
                            onClick={() => {
                              // handleTableHeaderSorting('userId')
                              sortTableData('userId');
                            }}
                          >
                            User ID{' '}
                            {sortColumn === 'userId' &&
                              (sortOrder === 'asc' ? (
                                <i className="fa fa-sort-up" />
                              ) : (
                                <i className="fa fa-sort-down" />
                              ))}
                          </th>
                          <th
                            className="p-2 col-3"
                            onClick={() => {
                              // handleTableHeaderSorting('userName')
                              sortTableData('name');
                            }}
                          >
                            Name{' '}
                            {sortColumn === 'name' &&
                              (sortOrder === 'asc' ? (
                                <i className="fa fa-sort-up" />
                              ) : (
                                <i className="fa fa-sort-down" />
                              ))}
                          </th>
                          <th
                            className="p-2 col-2"
                            onClick={() => {
                              // handleTableHeaderSorting('role')
                              sortTableData('roles');
                            }}
                          >
                            Role{' '}
                            {sortColumn === 'roles' &&
                              (sortOrder === 'asc' ? (
                                <i className="fa fa-sort-up" />
                              ) : (
                                <i className="fa fa-sort-down" />
                              ))}
                          </th>
                          {/* <th className="p-2 col-2">Email Id</th> */}
                          {/* <th className="p-2">Locked</th> */}
                          <th
                            className="p-2 col-2"
                            onClick={() => {
                              // handleTableHeaderSorting('lastLogin')
                              sortTableData('lastLogin');
                            }}
                          >
                            Last Login{' '}
                            {sortColumn === 'lastLogin' &&
                              (sortOrder === 'asc' ? (
                                <i className="fa fa-sort-up" />
                              ) : (
                                <i className="fa fa-sort-down" />
                              ))}
                          </th>
                          <th
                            className="p-2 col-2"
                            onClick={() => {
                              // handleTableHeaderSorting('active')
                              sortTableData('active');
                            }}
                          >
                            Status{' '}
                            {sortColumn === 'active' &&
                              (sortOrder === 'asc' ? (
                                <i className="fa fa-sort-up" />
                              ) : (
                                <i className="fa fa-sort-down" />
                              ))}
                          </th>
                          <th className="text-center p-2 col-2"> </th>
                        </tr>
                      </thead>
                      {!loading && userPaginatedData.length > 0 && (
                        <tbody className="">{renderUserProfiles}</tbody>
                      )}
                    </table>
                  </div>
                </div>
                {/* </div> */}
                {loading && (
                  <p
                    className={`${
                      darkmode ? 'noRecordsDark' : 'noRecords'
                    } col-lg-10 col-md-12 mx-auto d-flex justify-content-center align-items-center`}
                    style={{height: '80px'}}
                  >
                    Loading...
                  </p>
                )}
                {!loading && userPaginatedData.length === 0 && (
                  <p
                    className={`${
                      darkmode ? 'noRecordsDark' : 'noRecords'
                    } col-lg-10 col-md-12 mx-auto d-flex justify-content-center align-items-center`}
                    style={{height: '80px'}}
                  >
                    No Users Found..
                  </p>
                )}
                {!loading && userPaginatedData === null && (
                  <p
                    className={`${
                      darkmode ? 'noRecordsDark' : 'noRecords'
                    } col-lg-10 col-md-12 mx-auto d-flex justify-content-center align-items-center`}
                    style={{height: '80px'}}
                  >
                    Network Error...
                  </p>
                )}
                {/* <ReactPaginate
                    breakLabel="..."
                    nextLabel="Next >"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel="< Previous"
                    renderOnZeroPageCount={null}
                    containerClassName={'pagination'}
                    pageLinkClassName={'page-num'}
                    previousLinkClassName={'page-num'}
                    nextLinkClassName={'page-num'}
                    activeLinkClassName={'active'}
                  /> */}
                {/* </div> */}

                <UserFormSlideIn
                  show={showUserForm}
                  onHide={() => setShowUserForm(false)}
                  roleslist={roles}
                  fetchUsers={() => fetchAllUsers(token)}
                />
              </div>
            </Col>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Users;

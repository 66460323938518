import React, {useState} from 'react';
import {formatBytes, formatDate, formatTime, timeStampToDate} from '@app/utils/helpers';
import {useSelector} from 'react-redux';

type Props = {
  agentDetails: any;
  appUsagePaginatedData: any;
  appUsageList: any;
  setAppUsageList: any;
};

function AppUsageTable({
  agentDetails,
  appUsagePaginatedData,
  appUsageList,
  setAppUsageList
}: Props) {
  const darkmode = useSelector((state: any) => state.account.darkmode);

  const [sortOrder, setSortOrder] = useState('asc');
  const [sortColumn, setSortColumn] = useState(null);

  // TODO FIX BUGS
  const sortTableData = (column: any) => {
    const sortedData = [...appUsageList];

    // *** NEW IMPLEMENTATION ***
    // If One column asc then next column also asc, if one dsc, then next also dsc..
    if (sortColumn === column) {
      sortedData.reverse();
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      if (sortOrder === 'asc' || sortOrder === null) {
        // sort aesc if the current order is asc or null
        sortedData.sort((a, b) => (a[column] < b[column] ? -1 : 1));
        setSortColumn(column);
        setSortOrder('asc');
      } else {
        sortedData.sort((a, b) => (a[column] > b[column] ? -1 : 1)); // sort desc if the current order is desc
        setSortColumn(column);
        setSortOrder('desc');
      }
    }

    setAppUsageList(sortedData);
  };

  return (
    <div className="agentAppUsage">
      {appUsageList?.length === 0 ? (
        <div className={` ${darkmode ? 'noRecordsDark' : 'noRecords'}`}>
          No Apps Found
        </div>
      ) : (
        <table
          className={`w-100 customTable   ${
            darkmode ? 'darkTable darkDetailsCustomTable' : ' lighttable'
          }`}
        >
          <thead>
            <tr>
              <th
                className="col-3 text-left py-3 pl-4"
                onClick={() => {
                  // handleTableHeaderSorting('userId')
                  sortTableData('appName');
                }}
              >
                Application Name{' '}
                {sortColumn === 'appName' &&
                  (sortOrder === 'asc' ? (
                    <i className="fa fa-sort-up" />
                  ) : (
                    <i className="fa fa-sort-down" />
                  ))}
              </th>
              {agentDetails.osFamily !== 'macOS' && (
                <th
                  className="col-5 text-left py-2 pl-4"
                  onClick={() => {
                    // handleTableHeaderSorting('userId')
                    sortTableData('windowTitle');
                  }}
                >
                  Title{' '}
                  {sortColumn === 'windowTitle' &&
                    (sortOrder === 'asc' ? (
                      <i className="fa fa-sort-up" />
                    ) : (
                      <i className="fa fa-sort-down" />
                    ))}
                </th>
              )}
              <th
                className="col-2 text-left pl-4"
                onClick={() => {
                  // handleTableHeaderSorting('userId')
                  sortTableData('usageDate');
                }}
              >
                Date{' '}
                {sortColumn === 'usageDate' &&
                  (sortOrder === 'asc' ? (
                    <i className="fa fa-sort-up" />
                  ) : (
                    <i className="fa fa-sort-down" />
                  ))}
              </th>
              <th
                className="col-2 text-left pl-4"
                onClick={() => {
                  // handleTableHeaderSorting('userId')
                  sortTableData('durationSecs');
                }}
              >
                Usage Time{' '}
                {sortColumn === 'durationSecs' &&
                  (sortOrder === 'asc' ? (
                    <i className="fa fa-sort-up" />
                  ) : (
                    <i className="fa fa-sort-down" />
                  ))}
              </th>
              <th
                className="col-1 text-left pl-4 pr-4"
                onClick={() => {
                  // handleTableHeaderSorting('userId')
                  sortTableData('percentage');
                }}
              >
                Percentage{' '}
                {sortColumn === 'percentage' &&
                  (sortOrder === 'asc' ? (
                    <i className="fa fa-sort-up" />
                  ) : (
                    <i className="fa fa-sort-down" />
                  ))}
              </th>
            </tr>
          </thead>
          <tbody className="mb-3">
            {appUsagePaginatedData?.map((data: any) => (
              <tr key={data.id}>
                {/* <td>{data.id}</td> */}
                <td className=" text-left pl-4">{data.appName}</td>
                {agentDetails.osFamily !== 'macOS' && (
                  <td className="tooltipDiv text-left pl-4">
                    {data?.windowTitle?.slice(0, 40)?.toUpperCase()}{' '}
                    {data?.windowTitle.length > 40 ? '...' : ''}{' '}
                    <p
                      className="tooltipText"
                      // style={{marginLeft: '-200px'}}
                    >
                      {data?.windowTitle.toUpperCase()}
                    </p>
                  </td>
                )}
                <td className=" text-left pl-4">
                  {formatDate(data.startTime)}
                </td>
                <td className=" text-left pl-4">
                  {formatTime(data.durationSecs)}
                </td>
                <td className=" text-left pl-4">{data.percentage}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default AppUsageTable;

import axios from 'axios';
import {
  SWAGGER_GET_AGENTS,
  SWAGGER_GET_AGENT_DETAILS,
  GET_AGENT_SOFTWARE,
  GET_AGENT_APP_USAGE,
  GET_AGENT_COUNT,
  GET_AGENT_REPORTS,
  AGENT_ALIAS,
  DEACTIVATE_AGENT,
  AGENT_FUNCTION_VALUES,
  PATCH_AGENT_FUNCTION,
  SWAGGER_GET_AGENTS_ENDPOINTS,
  SWAGGER_GET_AGENTS_OS_LISTS,
  GET_AGENT_SERVICE,
  BROWSER_HISTORY
} from './constants/swaggerConfig';

export const agentLocation = (token: string) => {
  return axios.get(`${SWAGGER_GET_AGENTS}/${token}`);
};
export const getAgentEndpointIds = (token: string) => {
  return axios.get(`${SWAGGER_GET_AGENTS_ENDPOINTS}/${token}`);
};
export const getAgentOsList = (token: string) => {
  return axios.get(`${SWAGGER_GET_AGENTS_OS_LISTS}/${token}`);
};

export const swaggerGetAgents = (
  token: string,
  city: string,
  agentId: string,
  status: string,
  osType: string
) => {
  return axios.get(
    `${SWAGGER_GET_AGENTS}/${token}?agentId=${agentId}${
      city ? `&city=${city}` : ``
    }&status=${status}&os=${osType}`
  );
};

export const swaggerGetAgentDetails = (token: string, agentId: any) => {
  return axios.get(`${SWAGGER_GET_AGENT_DETAILS}/${token}?id=${agentId}`);
};

export const getAgentSoftwareDetails = (
  token: string,
  agentId: any,
  name: string,
  publisher: string,
  state: string,
  version: string
) => {
  return axios.get(
    `${GET_AGENT_SOFTWARE}/${token}?id=${agentId}&name=${
      name || ''
    }&publisher=${publisher || ''}&state=${state || ''}&version=${
      version || ''
    }`
  );
};

export const getAgentService = (token: string, agentId: any) => {
  return axios.get(`${GET_AGENT_SERVICE}/${token}?agentId=${agentId}`);
};
export const getAgentAppUsage = (
  token: string,
  agentId: any,
  appName: string = ''
) => {
  return axios.get(
    `${GET_AGENT_APP_USAGE}/${token}?appName=${appName}&agentId=${agentId}`
  );
};

export const swaggerGetAgentCount = (token: string) => {
  return axios.get(`${GET_AGENT_COUNT}/${token}`);
};
export const swaggerGetAgentBrowseHistoryCount = (
  token: string,
  agentId: any
) => {
  return axios.get(`${BROWSER_HISTORY}/count/${token}?agentId=${agentId}`);
};
export const swaggerGetAgentBrowseHistoryList = (
  token: string,
  agentId: any,
  count: number,
  browserType: string,
  domain: string,
  startDate:string,
  endDate:string
) => {
  return axios.get(
    `${BROWSER_HISTORY}/list/${token}?agentId=${agentId}${
      count ? `&count=${count}` : ''
    }${browserType && `&browserType=${browserType}`}${
      domain && `&domain=${domain}`
    }${
      startDate && `&startDate=${startDate}`
    } ${
      endDate && `&endDate=${endDate}`
    } `
  );
};
export const swaggerGetAgentReport = (token: string) => {
  return axios.get(`${GET_AGENT_REPORTS}/${token}`);
};
export const swaggerGetAgentReportDownload = (token: string) => {
  return axios.get(`${GET_AGENT_REPORTS}/${token}?download=true`);
};
export const patchAgentAlias = (
  token: string,
  agentId: string,
  alias: string
) => {
  return axios.patch(
    `${AGENT_ALIAS}/${token}?agentId=${agentId}&alias=${alias}`
  );
};

export const deactivateAgent = (
  token: string,
  deactivatedBy: string,
  deactivatedAt: string,
  agentId: string,
  comment: string = ''
) => {
  return axios.post(
    `${DEACTIVATE_AGENT}/${token}?deactivatedBy=${deactivatedBy}&comment=${comment}&deactivatedAt=${deactivatedAt}&agentId=${agentId}`
  );
};

export const agentFunctionValues = (token: string) => {
  return axios.get(`${AGENT_FUNCTION_VALUES}/${token}`);
};

export const patchAgentFunction = (
  token: string,
  agentId: string,
  func: string
) => {
  return axios.patch(
    `${PATCH_AGENT_FUNCTION}/${token}?agentId=${agentId}&function=${func}`
  );
};

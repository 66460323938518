import NoOfRecords from '@app/components/common/NoOfRecords';
import Pagination from '@app/components/common/Pagination';
import useOutsideClick from '@app/hooks/useOutsideClick';
import {getAgentEndpointIds} from '@app/services/agents';
import {RECORDS_PER_PAGE} from '@app/services/constants/config';
import {
  getRiskModuleList,
  getSeverityOptions,
  getVulnerabilityCount,
  getVulnerabilityList,
  getVulnerabilitySoftwares
} from '@app/services/threats';
import {
  convertUTCToMilliseconds,
  convertUTCToMillisecondsWithTime,
  dateToMilliseconds,
  formatDate,
  formatDateToMonthDayYear,
  formatDatetimeToDate,
  formatPayloadDate,
  handleInputChange,
  timeStampToDate
} from '@app/utils/helpers';
import React, {useEffect, useRef, useState} from 'react';
import {Form, OverlayTrigger, Tooltip} from 'react-bootstrap';
import Calendar from 'react-calendar';
import {useSelector} from 'react-redux';
import {string} from 'yup';
import SearchDropDown from '../agents/SearchDropDown';

interface SeverityOption {
  key: string;
  value: string;
}
interface ResData {
  [key: string]: string;
}

const VulnerabilityModule = () => {
  const [listingData, setListingData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [vulnerabilityPageNumber, setVulnerabilityPageNumber] = useState(1);
  const [numberOfRecords, setNumberOfRecords] = useState(RECORDS_PER_PAGE);
  const [count, setVulnerabilityCount] = useState(0);
  const [filter, setFilter] = useState(false);
  const [agentsList, setAgentsList] = useState([]);
  const [agentId, setAgentId] = useState('');
  const [acknowledge, setAcknowledge] = useState<any>(false);
  const [type, setType] = useState('');
  const [severity, setSeverity] = useState('');
  const [disposition, setDisposition] = useState('');
  const [showDateStart, setShowDateStart] = useState(false);
  const [calendarDateStart, setCalendarDateStart] = useState<any>(new Date());
  const [dateRange, setDateRange] = useState('today');
  const [dateStart, setDateStart] = useState('');
  const [softwareList, setSoftwareList] = useState([]);
  const [severityOptions, setSeverityOptions] = useState<SeverityOption[]>([]);
  const [clearInput, setClearInput] = useState(false);
  const dateStartCalendarRef = useRef(null);

  const token = useSelector((state: any) => state.auth.token);
  const darkmode = useSelector((state: any) => state.account.darkmode);

  const startIndex = (vulnerabilityPageNumber - 1) * numberOfRecords;
  const endIndex = vulnerabilityPageNumber * numberOfRecords;
  const vulnerabilityPaginatedData = listingData?.slice(startIndex, endIndex);

  const [sortOrder, setSortOrder] = useState('asc');
  const [sortColumn, setSortColumn] = useState(null);

  const sortTableData = (column: any) => {
    const sortedData = [...listingData];

    // *** NEW IMPLEMENTATION ***
    // If One column asc then next column also asc, if one dsc, then next also dsc..
    if (sortColumn === column) {
      sortedData.reverse();
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      if (sortOrder === 'asc' || sortOrder === null) {
        // sort aesc if the current order is asc or null
        sortedData.sort((a, b) => (a[column] < b[column] ? -1 : 1));
        setSortColumn(column);
        setSortOrder('asc');
      } else {
        sortedData.sort((a, b) => (a[column] > b[column] ? -1 : 1)); // sort desc if the current order is desc
        setSortColumn(column);
        setSortOrder('desc');
      }
    }

    setListingData(sortedData);
  };

  const fetchVulnerabilityModuleList = (data?: boolean) => {
    setLoading(true);

    const acknowledgeValue = acknowledge === 'All' ? '' : acknowledge;
    if (data) {
      setAgentId('');
      setType('');
      setAcknowledge(false);
      setDateRange('');
      setDateStart('');
      setCalendarDateStart('');
    }

    getVulnerabilityList(
      token,
      count,
      agentId,
      dateStart ? convertUTCToMilliseconds(dateStart).toString() : dateStart,
      dateStart
        ? convertUTCToMillisecondsWithTime(dateStart).toString()
        : dateStart,
      acknowledgeValue,
      type,
      disposition,
      severity
    )
      .then((response) => {
        const {data} = response;
        setListingData(data);
        setLoading(false);
        const softwareNames: any = new Set(
          data.map((obj: any) => obj.softwareName)
        );
      })
      .catch(() => setLoading(false));
  };

  const fetchSeverity = async () => {
    try {
      const res = await getSeverityOptions(token); // Ensure getSeverityOptions returns a Promise that resolves to an object with a status property and a data property

      if (res.status === 200) {
        // Cast res.data to ResData to satisfy TypeScript's type checking
        const transformedOptions = Object.entries(res.data as ResData).map(
          ([key, value]) => ({
            key,
            value
          })
        );
        setSeverityOptions(transformedOptions);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const fetchSoftwareNames = async () => {
    try {
      const res = await getVulnerabilitySoftwares(token); // Ensure getSeverityOptions returns a Promise that resolves to an object with a status property and a data property

      console.log(res);
      if (res.status === 200) {
        setSoftwareList(res.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getVulnarabilityCount = async () => {
    getVulnerabilityCount(token)
      .then((res) => {
        setVulnerabilityCount(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllAgents = () => {
    getAgentEndpointIds(token)
      .then((response) => {
        const {data} = response;
        setAgentsList(data);
      })
      .catch((err) => {
        setAgentsList([]);
      });
  };

  useOutsideClick(dateStartCalendarRef, 'customDateRangeCalendar', () =>
    setShowDateStart(false)
  );

  useEffect(() => {
    console.log('Effect triggered with count:', count);
    getVulnarabilityCount();
    getAllAgents();
    fetchSeverity();
    fetchSoftwareNames();
  }, []);
  useEffect(() => {
    
      fetchVulnerabilityModuleList();
    
  }, [count]);

  useEffect(() => {
    if (vulnerabilityPaginatedData?.length === 0) {
      setVulnerabilityPageNumber(1);
    }
  }, [vulnerabilityPaginatedData]);

  const handleSelect = (id: string) => {
    setAgentId(id);
  };

  const handleClear = () => {
    setClearInput(true);
  };

  const handleClearComplete = () => {
    setClearInput(false);
  };

  console.log(dateStart);

  return (
    <div>
      <div className="d-flex flex-wrap align-items-center justify-content-end mb-2">
        <NoOfRecords
          value={numberOfRecords}
          setValue={(value) => setNumberOfRecords(value)}
          totalRecords={listingData?.length}
        />
        {listingData?.length !== 0 && (
          <div className="paginationContainer  d-flex justify-content-end">
            <Pagination
              className="mr-1"
              currentPage={vulnerabilityPageNumber}
              recordsPerPage={numberOfRecords}
              totalRecords={listingData?.length}
              onPageChange={(page: number) => setVulnerabilityPageNumber(page)}
            />
          </div>
        )}

        <button
          type="button"
          onClick={() => {
            setFilter(!filter);
          }}
          className="m-1 filterButton mainFilterButton"
          id="vul-filter-btn"
        >
          <i className="fa-solid fa-filter" />
          <span className="ml-2">Filter</span>
        </button>
      </div>
      {filter && (
        <section
          className={`FilterOption mb-3 ${
            darkmode && 'darkSecondaryWrapper vulnerabilityBorder'
          } col-lg-12 col-md-12 mx-auto mb-0 d-flex  flex-column`}
        >
          <Form className="d-flex flex-wrap  justify-content-between">
            <Form.Group
              controlId="role"
              className="col-xl-3 col-lg-4 col-sm-5 col-12 "
            >
              <Form.Label> Endpoint ID</Form.Label>
              {/* <Form.Control
                as="select"
                value={agentId}
                onChange={(e) => {
                  setAgentId(e.target.value);
                }}
                id="vul-endpoint-id-option"
              >
                <option value="">--Select--</option>
                {agentsList.map((agent: any) => (
                  <option value={agent}>{agent}</option>
                ))}
              </Form.Control> */}
              <SearchDropDown
                data={agentsList}
                onSelect={handleSelect}
                clearInput={clearInput}
                onClearComplete={handleClearComplete}
                isManagedEndPointPage={false}
              />
            </Form.Group>

            <Form.Group
              controlId="role"
              className="col-xl-2 col-lg-4 col-sm-5 col-12 position-relative"
            >
              <Form.Label>Start Date</Form.Label>
              <div className="position-relative">
                <Form.Control
                  type="date"
                  value={dateStart}
                  onChange={(e) => {
                    setDateStart(e.target.value);
                    setCalendarDateStart(new Date(e.target.value));
                  }}
                  id="vul-start-date-option"
                />
                <span
                  className="customCalenderPickerIcon"
                  onClick={() => setShowDateStart((state) => !state)}
                  aria-hidden
                  id="vul-calendar-icon"
                />
              </div>
              {showDateStart && (
                <div
                  className="px-0 d-flex flex-shrink-0 position-absolute customDateRangeCalendar no-select"
                  style={{width: '308px'}}
                  ref={dateStartCalendarRef}
                >
                  <div className="dateRangeCard w-100 d-flex flex-column align-items-center justify-content-between pb-0">
                    <Form.Control
                      as="select"
                      value={dateRange}
                      onChange={(e) => setDateRange(e.target.value)}
                      id="vul-start-date-selector"
                    >
                      {/* <option value="">--Select--</option> */}
                      <option value="today">Today</option>
                      <option value="yesterday">Yestereday</option>
                      <option value="last3Days">Last 3 Days</option>
                      <option value="thisWeek">This Week</option>
                      <option value="thisMonth">This Month</option>
                    </Form.Control>
                    <div className="mt-3">
                      <Calendar
                        value={calendarDateStart}
                        onChange={(value: any) => {
                          setCalendarDateStart(value);
                          setDateStart(formatDatetimeToDate(value));
                          setShowDateStart(false);
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </Form.Group>

            <Form.Group
              controlId="role"
              className="col-xl-2 col-lg-4 col-sm-5 col-12"
            >
              <Form.Label>Software Name</Form.Label>
              <Form.Control
                as="select"
                value={type}
                onChange={(e) => {
                  setType(e.target.value);
                }}
                id="vul-software-name-option"
              >
                <option value="">--Select--</option>
                {softwareList?.map((i: any, index: number) => (
                  <option key={index} value={i}>
                    {i}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group
              controlId="role"
              className="col-xl-2 col-lg-4 col-sm-5 col-12"
            >
              <Form.Label>Severity</Form.Label>
              <Form.Control
                as="select"
                value={severity}
                onChange={(e) => {
                  setSeverity(e.target.value);
                }}
                id="vul-software-severity-option"
              >
                <option value="">--Select--</option>
                {severityOptions.map((i, index) => (
                  <option value={i.value}>{i.value}</option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group
              controlId="role"
              className="col-xl-2 col-lg-4 col-sm-5 col-12"
            >
              <Form.Label>Disposition</Form.Label>
              <Form.Control
                as="select"
                value={disposition}
                onChange={(e) => {
                  setDisposition(e.target.value);
                }}
                id="vul-software-disposition-option"
              >
                  <option value="">Select Disposition</option>
                      <option value="Risk Accepted">Risk accepted</option>
                      <option value="Mitigation">Mitigated</option>
                      <option value="Scheduled">Scheduled</option>
              
              </Form.Control>
            </Form.Group>

            <Form.Group
              controlId="role"
              className="col-xl-2 col-lg-4 col-sm-5 col-12"
            >
              <Form.Label>Acknowledgement</Form.Label>
              <Form.Control
                as="select"
                value={acknowledge}
                onChange={(e) => {
                  setAcknowledge(e.target.value);
                }}
                id="vul-acknowledgment-option"
              >
                <option value="">All</option>
                <option value={true}>Acknowledged</option>
                <option value={false}>Unacknowledged</option>
              </Form.Control>
            </Form.Group>
          </Form>

          <div className="d-flex flex-wrap justify-content-center mt-4 pl-1">
            <button
              type="button"
              className="m-1 pointer  alertFilterButton "
              onClick={() => {
                fetchVulnerabilityModuleList();
                getVulnarabilityCount();
              }}
              id="vul-apply-btn"
            >
              Apply
            </button>
            <button
              type="button"
              className="m-1 pointer alertFilterButton"
              onClick={() => {
                setAgentId('');
                setType('');
                setAcknowledge(false);
                setDateRange('');
                setDateStart('');
                setCalendarDateStart('');
                setDisposition('');
                setSeverity('');
                handleClear();
                getVulnerabilityList(
                  token,
                  count,
                  '',
                  '',
                  '',
                  false,
                  '',
                  '',
                  ''
                )
                  .then((res) => {
                    const {data} = res;
                    setListingData(data);
                    setLoading(false);
                    const softwareNames: any = new Set(
                      data.map((obj: any) => obj.softwareName)
                    );
                  })
                  .catch((err) => {});
              }}
              id="vul-clear-btn"
            >
              Clear
            </button>
            <button
              type="button"
              className="Close m-1 pointer alertFilterButton"
              onClick={() => {
                setFilter(false);
              }}
              id="vul-close-btn"
            >
              Close
            </button>
          </div>
        </section>
      )}
      <div className="overflow-auto">
        {!loading && (
          <table className={`customTable  ${darkmode ? 'darkTable' : ''}`}>
            <thead>
              <tr>
                <th
                  className="p-2 col-2 text-left pl-3"
                  onClick={() => {
                    sortTableData('agentId');
                  }}
                >
                  Endpoint ID{' '}
                  {sortColumn === 'agentId' &&
                    (sortOrder === 'asc' ? (
                      <i className="fa fa-sort-up" />
                    ) : (
                      <i className="fa fa-sort-down" />
                    ))}
                </th>
                <th
                  className="p-2 text-left"
                  onClick={() => {
                    sortTableData('source');
                  }}
                >
                  Source
                  {sortColumn === 'source' &&
                    (sortOrder === 'asc' ? (
                      <i className="fa fa-sort-up" />
                    ) : (
                      <i className="fa fa-sort-down" />
                    ))}
                </th>
                <th
                  className="p-2 text-left"
                  onClick={() => {
                    sortTableData('softwareName');
                  }}
                >
                  Software Name
                  {sortColumn === 'softwareName' &&
                    (sortOrder === 'asc' ? (
                      <i className="fa fa-sort-up" />
                    ) : (
                      <i className="fa fa-sort-down" />
                    ))}
                </th>
                <th
                  className="p-2 col-1 text-left"
                  onClick={() => {
                    sortTableData('version');
                  }}
                >
                  Version{' '}
                  {sortColumn === 'version' &&
                    (sortOrder === 'asc' ? (
                      <i className="fa fa-sort-up" />
                    ) : (
                      <i className="fa fa-sort-down" />
                    ))}
                </th>
                <th
                  className="p-2 text-left"
                  onClick={() => {
                    sortTableData('createdAt');
                  }}
                >
                  Date Detected
                  {sortColumn === 'createdAt' &&
                    (sortOrder === 'asc' ? (
                      <i className="fa fa-sort-up" />
                    ) : (
                      <i className="fa fa-sort-down" />
                    ))}
                </th>
                <th
                  className="p-2 text-left"
                  onClick={() => {
                    sortTableData('acknowledgedAt');
                  }}
                >
                  Acknowledgement Date{' '}
                  {sortColumn === 'acknowledgedAt' &&
                    (sortOrder === 'asc' ? (
                      <i className="fa fa-sort-up" />
                    ) : (
                      <i className="fa fa-sort-down" />
                    ))}
                </th>
                <th className="p-2 col-1 text-left"    onClick={() => {
                    sortTableData('severity');
                  }}>Severity  {sortColumn === 'severity' &&
                    (sortOrder === 'asc' ? (
                      <i className="fa fa-sort-up" />
                    ) : (
                      <i className="fa fa-sort-down" />
                    ))}</th>
                <th style={{width: '50px'}} className="p-2 "></th>
                <th style={{width: '50px'}} className="p-2 "></th>
              </tr>
            </thead>
            <tbody className="mb-3">
              {vulnerabilityPaginatedData?.map((data: any) => (
                <tr key={data.id}>
                  <td className="text-left tooltipDiv pl-3">
                    <a
                      className="agentUnderline"
                      href={`/agents/agent-details/${data.agentId}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {' '}
                      {data.agentId.slice(0, 25)}
                    </a>
                    {data.agentId.length > 25 ? '...' : ''}
                    <p className="tooltipText">{data.agentId}</p>
                  </td>
                  <td className="text-left pl-2">{data.source}</td>
                  <td className="text-left pl-2 tooltipDiv">
                    {data.softwareName.slice(0, 25)}
                    {data.softwareName.length > 25 ? '...' : ''}
                    <p className="tooltipText">{data.softwareName}</p>
                  </td>
                  <td className="text-left pl-2">{data.version}</td>

                  <td className="text-left pl-2">
                    {timeStampToDate(data.createdAt)}
                  </td>
                  <td className="text-left pl-2">
                    {timeStampToDate(data.acknowledgedAt)}
                  </td>

                  <td className="text-left pl-2">{data.severity}</td>
                  <td
                    style={{width: '50px'}}
                    className="text-center p-2 col-1 "
                  >
                    <a href={`/vulnerability/vulnerability-details/${data.id}`}>
                      {' '}
                      <i
                        id="vul-details-arrow"
                        className="fa-solid fa-chevron-right"
                      />
                    </a>
                  </td>
                  <td
                    style={{width: '50px'}}
                    className="text-center p-2 col-1 "
                  >
                    <OverlayTrigger
                      overlay={<Tooltip id=""> Open in new tab</Tooltip>}
                      placement="top"
                      delay={200}
                    >
                      <a
                        href={`/vulnerability/vulnerability-details/${data.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i
                          id="vul-view-another-window"
                          className="fa-regular fa-window-restore"
                        ></i>
                      </a>
                    </OverlayTrigger>{' '}
                  </td>
                </tr>
              ))}

              {/* <tr>
              <td className="">id</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
             
            </tr> */}
            </tbody>
          </table>
        )}
      </div>
      {loading ? (
        <p className={` ${darkmode ? 'noRecordsDark' : 'noRecords'}`}>
          Loading...
        </p>
      ) : listingData.length === 0 ? (
        <p className={`${darkmode ? 'noRecordsDark' : 'noRecords'} mt-0`}>
          No Vulnerability Records
        </p>
      ) : (
        ''
      )}
    </div>
  );
};

export default VulnerabilityModule;

import axios from 'axios';
import {GET_SETTINGS, GET_SETTINGS_DOWNLOAD, SWAGGER_DOWNLOAD_AGENTS_LICENSE} from "./constants/swaggerConfig"


export const getSettings = (token:string) => {
    return axios.get(`${GET_SETTINGS}/${token}`)

}
export const getSettingsDownload = (token:string) => {
    return axios.get(`${SWAGGER_DOWNLOAD_AGENTS_LICENSE}/${token}`)

}

export const postSetting = (token:string,setting:string,value:string) => {
    return axios.post(`${GET_SETTINGS}/${token}?setting=${setting}&value=${value}`)

}


/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {useEffect, useState} from 'react';
import {ContentHeader} from '@app/components';
import {useDispatch, useSelector} from 'react-redux';
import {
  getfilteredUsers,
  getRbacData,
  getUserRoles,
  swaggerGetAllUsers
} from '@app/services/swaggerAuth';
import {
  setIsAdmin,
  setAllUserProfiles,
  setSelectedUser,
  setUserEditMode,
  setUsersPageNumber,
  setRbacDetails
} from '@app/store/reducers/auth';
import UserFormSlideIn from '@app/pages/users/UserFormSlideIn';
import {
  Button,
  Col,
  Collapse,
  Form,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
// import './Users.css';
import {formatDate, handleInputChange} from '@app/utils/helpers';
import Container from '@app/components/CustomDash/Container';
import ReactPaginate from 'react-paginate';
import InfoIcon from '@app/components/common/InfoIcon';
import Pagination from '@app/components/common/Pagination';
import {useNavigate} from 'react-router-dom';
import NoOfRecords from '@app/components/common/NoOfRecords';
import {RECORDS_PER_PAGE} from '@app/services/constants/config';
import { getRbacDetailValue } from '@app/utils/helpers';

const RoleBasedAccessControl = () => {
  const token = useSelector((state: any) => state.auth.token);
  const isLoggedIn = useSelector((state: any) => state.auth.isLoggedIn);
  const currentUser = useSelector((state: any) => state.auth.currentUser);
  const isAdmin = useSelector((state: any) => state.auth.isAdmin);
  const [openFilter, setOpenFilter] = useState(false);

  const [listingData, setListingData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);

  const [loading, setLoading] = useState(true);

  // localstates for filtering
  const [userId, setUserId] = useState('');
  const [name, setName] = useState('');
  const [role, setRole] = useState('');
  const [active, setActive] = useState('');
  const [roles, setRoles] = useState<any>([]);
  const [numberOfRecords, setNumberOfRecords] = useState(RECORDS_PER_PAGE);

  // Perform array slicing based on the current page
  const startIndex = (pageNumber - 1) * numberOfRecords;
  const endIndex = pageNumber * numberOfRecords;
  const paginatedData = listingData?.slice(startIndex, endIndex);
  const darkmode = useSelector((state: any) => state.account.darkmode);

  /**
   * Table Sorting
   * -------------
   */
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortColumn, setSortColumn] = useState(null);

  // TODO FIX BUGS
  const sortTableData = (column: any) => {
    const sortedData = [...listingData];

    // *** PREVIOUS IMPLEMENTATION REFERENCE ***
    // Toggling asc & dsc irrespective of same column or different column
    // if (sortColumn === column) {
    //   sortedData.reverse();
    //   setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    // } else {
    //   sortedData.sort((a, b) => {
    //     if (a[column] < b[column]) {
    //       return sortOrder === 'asc' ? -1 : 1;
    //     } else if (a[column] > b[column]) {
    //       return sortOrder === 'asc' ? 1 : -1;
    //     } else {
    //       return 0;
    //     }
    //   });
    //   setSortColumn(column);
    //   setSortOrder('asc');
    // }

    // *** NEW IMPLEMENTATION ***
    // If One column asc then next column also asc, if one dsc, then next also dsc..
    if (sortColumn === column) {
      sortedData.reverse();
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else if (sortOrder === 'asc' || sortOrder === null) {
      // sort aesc if the current order is asc or null
      sortedData.sort((a, b) => (a[column] < b[column] ? -1 : 1));
      setSortColumn(column);
      setSortOrder('asc');
    } else {
      sortedData.sort((a, b) => (a[column] > b[column] ? -1 : 1)); // sort desc if the current order is desc
      setSortColumn(column);
      setSortOrder('desc');
    }

    // TODO
    // If one column asc or desc, next column should be asc when at first click and should toggle from the second click
    // setTableData(sortedData);

   setListingData(sortedData);
  };

  /**
   * Pagination
   * ----------
   */
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  // const [itemOffset, setItemOffset] = useState<number>(0);
  // const usersList = listingData;

  // // Simulate fetching items from another resources.
  // // (This could be items from props; or items loaded in a local state
  // // from an API endpoint with useEffect and useState)
  // const endOffset = itemOffset + numberOfRecords;
  // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  // const currentUsersList = usersList?.slice(itemOffset, endOffset);
  // const pageCount = Math.ceil(usersList?.length / numberOfRecords);

  // // Invoke when user click to request another page.
  // const handlePageClick = (event: any) => {
  //   const newOffset =
  //     (event.selected * numberOfRecords) % usersList.length;
  //   console.log(
  //     `User requested page number ${event.selected}, which is offset ${newOffset}`
  //   );
  //   setItemOffset(newOffset);
  // };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const checkIfAdmin = (user: any) => {
    // console.log('user.roles', user);
    // use when array
    // const isAdmin = user.roles.find((role: string) => role === 'ADMIN');
    // if (isAdmin !== undefined) {
    //   dispatch(setIsAdmin(true));
    // } else {
    //   dispatch(setIsAdmin(false));
    // }
    // use when string
    if (user.roles === 'ADMIN') {
      dispatch(setIsAdmin(true));
    } else {
      dispatch(setIsAdmin(false));
    }
  };

  const fetchRbacData = (token: string) => {
    getRbacData(token)
      .then((response) => {
        const {data} = response;
        setListingData(data);
        setLoading(false);
        localStorage.setItem('cachedRbacData', JSON.stringify(data));
      })
      .catch((err) => {
        setListingData([]);
        setLoading(false);
      });
  };

  const fetchFilteredUsers = () => {
    getfilteredUsers(token, userId, name, role, active)
      .then((response) => {
        const {data} = response;
        dispatch(setAllUserProfiles(data));
        setLoading(false);
      })
      .catch((err) => {
        dispatch(setAllUserProfiles([]));
        setLoading(false);
      });
  };

  const handleFilterApply = () => {
    fetchFilteredUsers();
  };

  const clearFilterForm = () => {
    setUserId('');
    setName('');
    setRole('');
    setActive('');
    setTimeout(() => {
      fetchRbacData(token);
    }, 100);
  };

  const handleFilterClose = () => {};

  const fetchInitialData = (token: string) => {
    const cachedData = localStorage.getItem('cachedRbacData');
    if (cachedData) {
      setListingData(JSON.parse(cachedData));
      setLoading(false);
    } else {
      fetchRbacData(token); // Fetch data if not cached
    }
  };

  useEffect(() => {
    if (currentUser) {
      checkIfAdmin(currentUser);
    }
  }, [currentUser]);

  useEffect(() => {
    if (isLoggedIn && token) {
      fetchInitialData(token);
    }
  }, [isLoggedIn, token]);

  useEffect(() => {
    getUserRoles(token)
      .then((res) => {
        setRoles(
          Object.entries(res.data).map(([key, value]) => ({key, value}))
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // Pagination Bugfix
  useEffect(() => {
    if (paginatedData?.length === 0) {
      setPageNumber(1);
    }
  }, [paginatedData]);

  const renderListingData = paginatedData?.map((data: any) => (
    <tr key={data.id} className="" style={{cursor: 'pointer'}}>
      <td
        className="text-left pl-4"
        onClick={(e) => {
          navigate(`/role-based-access-control-details/${data.id}`);
          dispatch(setRbacDetails(data));
        }}
      >
        {data.username}
      </td>
      <td
        className="text-left pl-4"
        onClick={(e) => {
          navigate(`/role-based-access-control-details/${data.id}`);
          dispatch(setRbacDetails(data));
        }}
      >
        {getRbacDetailValue(data.displayName)}
      </td>
      <td
        className="text-left pl-4"
        onClick={(e) => {
          navigate(`/role-based-access-control-details/${data.id}`);
          dispatch(setRbacDetails(data));
        }}
      >
        {data.domain}
      </td>
      <td
        className="text-left pl-4"
        onClick={(e) => {
          navigate(`/role-based-access-control-details/${data.id}`);
          dispatch(setRbacDetails(data));
        }}
      >
        {data.groups}
      </td>
      <td /* className="p-2 pr-4" */
        onClick={(e) => {
          navigate(`/role-based-access-control-details/${data.id}`);
          dispatch(setRbacDetails(data));
        }}
      >
        <i id='rbac-arrow' className="fa-solid fa-chevron-right" />
      </td>
      <td className="text-left pr-4">
        <OverlayTrigger
          overlay={<Tooltip id=""> Open in new tab</Tooltip>}
          placement="top"
          delay={300}
        >
          <a
            href={`/role-based-access-control-details/${data.id}`}
            onClick={(e) => {
              dispatch(setRbacDetails(data));
            }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <i id='rbac-open-diff-window' className="fa-regular fa-window-restore"></i>
          </a>
        </OverlayTrigger>
      </td>
    </tr>
  ));

  return (
    <Container>
      <div>
        <div className="container-fluid">
          <div className="row d-flex justify-content-center align-items-center">
            <Col xs={12}>
              <div>
                {/* Buttons Container */}
                <div className="mt-2 FilterButtonContainer col-lg-10 col-md-12 mx-auto pt-2 mb-2 d-flex justify-content-between">
                  <h3 className="subHeaders">  </h3>
                  <div className="d-flex flex-wrap align-items-center justify-content-end col-10">
                    <NoOfRecords
                      value={numberOfRecords}
                      setValue={(value) => setNumberOfRecords(value)}
                      totalRecords={listingData?.length}
                    />
                    {listingData?.length !== 0 && (
                      <div className="paginationContainer d-flex justify-content-end mb-2">
                        <Pagination
                          className="mr-1"
                          currentPage={pageNumber}
                          recordsPerPage={numberOfRecords}
                          totalRecords={listingData?.length}
                          onPageChange={(page: number) => setPageNumber(page)}
                        />
                      </div>
                    )}

                    {/* <button
                      type="button"
                      className="m-1 filterButton"
                      onClick={() => setOpenFilter(!openFilter)}
                      aria-expanded={openFilter}
                      aria-controls="example-collapse-text"
                    >
                      <i className="fa-solid fa-filter" />
                      <span className="pl-1">Filter</span>
                    </button> */}
                  </div>
                </div>

                {/* <div className="d-flex flex-column mt-3 px-2 pb-3"> */}
                {/* Filter Container */}
                {openFilter && (
                  <section className="FilterOption col-lg-10 col-md-12 mx-auto mb-0 d-flex  flex-column">
                    <Form className="d-flex flex-wrap justify-content-between">
                      <Form.Group
                        controlId="role"
                        className="col-lg-3 col-md-3 col-12"
                      >
                        <Form.Label>User Id</Form.Label>
                        <Form.Control
                          type="search"
                          placeholder="Enter email"
                          value={userId}
                          onChange={(e) => {
                            setUserId(e.target.value);
                            // handleChange(e);
                          }}
                        />
                      </Form.Group>
                      <Form.Group
                        controlId="role"
                        className="col-lg-3 col-md-3 col-12"
                      >
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="search"
                          placeholder="Enter name"
                          value={name}
                          onChange={(e) => {
                            setName(e.target.value);
                            // handleChange(e);
                          }}
                        />
                      </Form.Group>
                      <Form.Group
                        controlId="role"
                        className="col-lg-3 col-md-3 col-12"
                      >
                        <Form.Label>Role</Form.Label>
                        <Form.Control
                          as="select"
                          value={role}
                          onChange={(e) => {
                            setRole(e.target.value);
                            // handleChange(e);
                          }}
                        >
                          <option value="">--Select--</option>
                          {roles?.map((i: any, index: number) => (
                            <option key={index} value={i.key}>
                              {i.value}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                      <Form.Group
                        controlId="role"
                        className="col-lg-3 col-md-3 col-12"
                      >
                        <Form.Label>Status</Form.Label>
                        <Form.Control
                          as="select"
                          value={active}
                          onChange={(e) => {
                            setActive(e.target.value);
                            // handleChange(e);
                          }}
                        >
                          <option value="">--Select--</option>
                          <option value="Active">Active</option>
                          <option value="Inactive">Inactive</option>
                        </Form.Control>
                      </Form.Group>
                    </Form>

                    <div className="d-flex justify-content-start mt-4 pl-1">
                      <button
                        type="button"
                        className="m-1 pointer"
                        onClick={(e) => handleFilterApply()}
                      >
                        Apply
                      </button>
                      <button
                        type="button"
                        className="m-1 pointer"
                        onClick={() => clearFilterForm()}
                      >
                        Clear
                      </button>
                      <button
                        type="button"
                        className="Close m-1 pointer"
                        onClick={() => setOpenFilter(false)}
                      >
                        Close
                      </button>
                      {/* <div className="btn text-white  m-1 apllyBTn">Apply</div>
                        <div className="btn  m-1 clearBtn">Clear</div>
                        <div className="btn btn-danger m-1">Close</div> */}
                    </div>
                  </section>
                )}

                {listingData?.length !== 0 && (
                  <div className="mobilePaginationContainer">
                    <Pagination
                      className="mr-1"
                      currentPage={pageNumber}
                      recordsPerPage={numberOfRecords}
                      totalRecords={listingData?.length}
                      onPageChange={(page: number) => setPageNumber(page)}
                    />
                  </div>
                )}

                {/* Table */}
                <div className="card-body col-lg-10 col-md-12 mx-auto px-0 pb-3">
                  <div className="d-flex tableContainer overflow-auto flex-column">
                    <table
                      className={`customTable ${darkmode ? 'darkTable' : ''}`}
                    >
                      <thead className="">
                        <tr>
                          <th
                            className="p-2 col-3 text-left pl-4"
                            onClick={() => {
                              sortTableData('username');
                            }}
                          >
                            Username
                            {sortColumn === 'username' &&
                              (sortOrder === 'asc' ? (
                                <i className="fa fa-sort-up" />
                              ) : (
                                <i className="fa fa-sort-down" />
                              ))}
                          </th>
                          <th
                            className="p-2 col-3 text-left pl-4"
                            onClick={() => {
                              sortTableData('displayName');
                            }}
                          >
                            Display Name
                            {sortColumn === 'displayName' &&
                              (sortOrder === 'asc' ? (
                                <i className="fa fa-sort-up" />
                              ) : (
                                <i className="fa fa-sort-down" />
                              ))}
                          </th>
                          <th
                            className="p-2 col-3 text-left pl-4"
                            onClick={() => {
                              sortTableData('domain');
                            }}
                          >
                            Domain
                            {sortColumn === 'domain' &&
                              (sortOrder === 'asc' ? (
                                <i className="fa fa-sort-up" />
                              ) : (
                                <i className="fa fa-sort-down" />
                              ))}
                          </th>
                          <th
                            className="p-2 col-2 text-left pl-4"
                            onClick={() => {
                              sortTableData('groups');
                            }}
                          >
                            Groups
                            {sortColumn === 'groups' &&
                              (sortOrder === 'asc' ? (
                                <i className="fa fa-sort-up" />
                              ) : (
                                <i className="fa fa-sort-down" />
                              ))}
                          </th>
                          <th className="p-2 col-1"> </th>
                          <th className="p-2 col-1"> </th>
                        </tr>
                      </thead>
                      {!loading && paginatedData.length > 0 && (
                        <tbody className="">{renderListingData}</tbody>
                      )}
                    </table>
                  </div>

                  {/* </div> */}
                  {loading && (
                    <p
                      className={`mt-0 ${
                        darkmode ? 'noRecordsDark' : 'noRecords'
                      }`}
                    >
                      Loading...
                    </p>
                  )}
                  {!loading && paginatedData.length === 0 && (
                    <p
                      className={`mt-0 ${
                        darkmode ? 'noRecordsDark' : 'noRecords'
                      }`}
                    >
                      No RBAC Found..
                    </p>
                  )}
                  {!loading && paginatedData === null && (
                    <p
                      className={`mt-0 ${
                        darkmode ? 'noRecordsDark' : 'noRecords'
                      }`}
                    >
                      Network Error...
                    </p>
                  )}
                  {/* <ReactPaginate
                    breakLabel="..."
                    nextLabel="Next >"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel="< Previous"
                    renderOnZeroPageCount={null}
                    containerClassName={'pagination'}
                    pageLinkClassName={'page-num'}
                    previousLinkClassName={'page-num'}
                    nextLinkClassName={'page-num'}
                    activeLinkClassName={'active'}
                  /> */}
                </div>
              </div>
            </Col>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default RoleBasedAccessControl;

import {ContentHeader} from '@app/components';
import Container from '@app/components/CustomDash/Container';
import SettingCard from '@app/components/settings/SettingCard';
import { SWAGGER_BASE_URL } from '@app/services/constants/swaggerConfig';
import {getSettings, getSettingsDownload} from '@app/services/settings';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

type Props = {};

function Settings({}: Props) {
  const token = useSelector((state: any) => state.auth.token);
  const darkmode = useSelector((state: any) => state.account.darkmode);
  const navigate = useNavigate();
  const [settingData, setSettingData] = useState([]);

  const fetchSettings = (token: string) => {
    getSettings(token)
      .then((res) => {
        setSettingData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchSettings(token);
  }, []);

  const renderData = settingData?.map((i: any, index: any) => (
    <SettingCard
      key={index}
      keyValue={i.key}
      description={i.description}
      value={i.value}
      uom={i.uom}
    />
  ));

  const downloadSettings = () => {
    getSettingsDownload(token)
      .then((res) => {
        console.log(res);
        const url = URL.createObjectURL(new Blob([res.data]));

        // Create a hidden anchor element
        const a = document.createElement('a');
        a.href = url;
        a.download = 'license.licx'; // Replace with the desired filename and extension
        a.click();

        // Release the temporary URL
        URL.revokeObjectURL(url);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAgentDownload = async () => {
 
    const toastId = toast.loading("Downloading...");
    try {
     
      const res = await fetch(`${SWAGGER_BASE_URL}/debug/download/msi`);
      const blob = await res.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'cyberzek-agent.msi');
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);

      toast.dismiss(toastId);
  toast.success('File downloaded successfully!');
    } catch (error) {
      toast.dismiss(toastId);
      navigate('/');
      toast.error('Failed to download file.');
    }
  };

  return (
    <Container>
      <div className="col-10 mx-auto d-flex mt-4 justify-content-end">
        <div className=" d-flex p-3  settingsDownloadContainer filterButton ">
          <p className="mb-0">
          Download 
            <br />
            Windows Agent
          </p>
          <button
            id="settings-download-agents"
            onClick={handleAgentDownload}
            className="ml-4 filterButton"
          >
            <i className="fa-solid fa-download mr-2"></i>Download
          </button>
        </div>
      </div>
      <div className="card-body col-lg-10 col-md-12 mx-auto px-0 pb-3">
        <div className="d-flex tableContainer overflow-auto flex-column">
          <table className={`customTable ${darkmode ? 'darkTable' : ''}`}>
            <thead className="">
              <tr>
                <th className="col-1 p-2">Key</th>
                <th className="col-1 p-2">Description</th>
                <th className="col-2 p-2">Unit of measurement</th>
                <th className="col-1 p-2">Value</th>
                <th className="col-1 p-2"></th>
              </tr>
            </thead>
            <tbody>{renderData}</tbody>
          </table>
        </div>
      </div>
    </Container>
  );
}

export default Settings;

import Container from '@app/components/CustomDash/Container';
import React, {useState} from 'react';
import Internal from './Internal';
import External from './External';
import { useSelector } from 'react-redux';

const ConfigureIP = () => {
  const [activeTab, setActiveTab] = useState('internal');

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };

  const darkmode = useSelector((state: any) => state.account.darkmode);


  return (
    <Container>
      <div>
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            <h3 className="subHeaders mx-5 "> Configure IP Address </h3>
            {/* Pagination for future */}
            <div className="mx-5">
              <div className="d-flex align-items-center">
                {/* {activeTab === 'softwareDetails' &&
                  agentSoftwareDetails?.length !== 0 && (
                    <div className="paginationContainer">
                      <Pagination
                        className="m-1 mb-2"
                        currentPage={softwareDetailsPageNo}
                        recordsPerPage={SOFTWARE_DETAILS_RECORDS_PER_PAGE}
                        totalRecords={agentSoftwareDetails?.length}
                        onPageChange={(page: number) =>
                          setSoftwareDetailsPageNo(page)
                        }
                      />
                    </div>
                  )} */}

                {/* {activeTab === 'softwareDetails' &&
                  agentSoftwareDetails?.length !== 0 && (
                    <button
                      type="button"
                      className="m-1 filterButton"
                      onClick={() => setFilter(!filter)}
                      aria-expanded={filter}
                      aria-controls="example-collapse-text"
                    >
                      <i className="fa-solid fa-filter" />
                      <span className="pl-1">Filter</span>
                    </button>
                  )} */}
              </div>
            </div>
          </div>

          <div className="overflow-auto ">
            <div className={`customTable-2 mx-5 ${darkmode && 'darkSecondaryWrapper'}`}>
              <ul className="tabs-nav">
                <li
                  className={`tab ${
                    activeTab === 'internal' ? 'active' : ''
                  }`}
                  onClick={() => handleTabClick('internal')}
                  aria-hidden
                >
                  Internal
                </li>
                <li
                  className={`tab ${
                    activeTab === 'external' ? 'active' : ''
                  }`}
                  onClick={() => handleTabClick('external')}
                  aria-hidden
                >
                  External
                </li>
                <li
                  className={`tab ${activeTab === '' ? 'active' : ''}`}
                  aria-hidden
                />
                <li
                  className={`tab ${activeTab === '' ? 'active' : ''}`}
                  aria-hidden
                />
              </ul>

              <div className="tab-content">
                <div className="tab-table-container">
                  {activeTab === 'internal' && <Internal />}
                  {activeTab === 'external' && <External />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ConfigureIP;

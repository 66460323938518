import Container from '@app/components/CustomDash/Container';
import React, {useState} from 'react';
import VulnerabilityModule from '../threats/VulnerabilityModule';
import RiskModule from '../threats/RiskModule';
import ControlsModule from '../threats/ControlsModule';
import {useNavigate} from 'react-router-dom';
import { useSelector } from 'react-redux';


const ProductivitySubTabs = () => {
  const [activeTab, setActiveTab] = useState('appUsage');
  const navigate = useNavigate();
  const darkmode = useSelector((state: any) => state.account.darkmode);


  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };

  return (
    <Container>
      <div>
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            <h3 className="subHeaders mx-5 "> Productivity </h3>
            {/* Pagination for future */}
            <div className="mx-5">
              <div className="d-flex align-items-center">
                {/* {activeTab === 'softwareDetails' &&
                  agentSoftwareDetails?.length !== 0 && (
                    <div className="paginationContainer">
                      <Pagination
                        className="m-1 mb-2"
                        currentPage={softwareDetailsPageNo}
                        recordsPerPage={SOFTWARE_DETAILS_RECORDS_PER_PAGE}
                        totalRecords={agentSoftwareDetails?.length}
                        onPageChange={(page: number) =>
                          setSoftwareDetailsPageNo(page)
                        }
                      />
                    </div>
                  )} */}

                {/* Filter for future */}
                {/* {activeTab === 'softwareDetails' &&
                  agentSoftwareDetails?.length !== 0 && (
                    <button
                      type="button"
                      className="m-1 filterButton"
                      onClick={() => setFilter(!filter)}
                      aria-expanded={filter}
                      aria-controls="example-collapse-text"
                    >
                      <i className="fa-solid fa-filter" />
                      <span className="pl-1">Filter</span>
                    </button>
                  )} */}
                  
<button className='filterButton px-5 mr-1' onClick={() => {
{
  navigate('/productivity')

}
                    }}>Back</button>
                <button
                  type="button"
                  className="m-1 filterButton"
                  // onClick={() => setFilter(!filter)}
                  // aria-expanded={filter}
                  aria-controls="example-collapse-text"
                >
                  <i className="fa-solid fa-filter" />
                  <span className="pl-1">Filter</span>
                </button>
              </div>
            </div>
          </div>

          <div className="overflow-auto ">
            <div className={`customTable-2 mx-5 ${darkmode && 'darkSecondaryWrapper'} `}>
              <ul className="tabs-nav">
                <li
                  className={`tab ${activeTab === 'appUsage' ? 'active' : ''}`}
                  onClick={() => handleTabClick('appUsage')}
                  aria-hidden
                >
                  App Usage
                </li>
                <li
                  className={`tab ${activeTab === 'browser' ? 'active' : ''}`}
                  onClick={() => handleTabClick('browser')}
                  aria-hidden
                >
                  Browser
                </li>
                <li
                  className={`tab ${activeTab === '' ? 'active' : ''}`}
                  aria-hidden
                />
              </ul>

              <div className="tab-content">
                <div className="tab-table-container">
                  {/* App Usage Table */}
                  {activeTab === 'appUsage' && (
                    <div className="appUsage">
                      {[1]?.length === 0 ? (
                        <div className="noRecords">No Records</div>
                      ) : (
                        <table className={`w-100 customTable  ${darkmode ? 'darkTable':''}`}>
                        <thead>
                            <tr>
                              {/* <th>Id</th> */}
                              <th className="col-4 py-2">
                                Application Name
                              </th>
                              <th className="col-4">Usage</th>
                              <th className="col-4">
                                Percentage
                              </th>
                            </tr>
                          </thead>
                          <tbody className="mb-3">
                            {/* {[]?.map((data: any) => (
                              <tr key={data.id}>
                                <td className=" text-left pl-4">{data.name}</td>
                                <td className=" text-left pl-4">
                                  {data.publisher}
                                </td>
                                <td className=""></td>
                              </tr>
                            ))} */}
                            <tr>
                              <td />
                              <td />
                              <td />
                            </tr>
                            <tr>
                              <td />
                              <td />
                              <td />
                            </tr>
                            <tr>
                              <td />
                              <td />
                              <td />
                            </tr>
                            <tr>
                              <td />
                              <td />
                              <td />
                            </tr>
                            <tr>
                              <td />
                              <td />
                              <td />
                            </tr>
                            <tr>
                              <td />
                              <td />
                              <td />
                            </tr>
                            <tr>
                              <td />
                              <td />
                              <td />
                            </tr>
                            <tr>
                              <td />
                              <td />
                              <td />
                            </tr>
                            <tr>
                              <td />
                              <td />
                              <td />
                            </tr>
                            <tr>
                              <td />
                              <td />
                              <td />
                            </tr>
                            <tr>
                              <td />
                              <td />
                              <td />
                            </tr>
                          </tbody>
                        </table>
                      )}
                    </div>
                  )}
                  {activeTab === 'browser' && null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ProductivitySubTabs;

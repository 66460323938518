import {removeWindowClass} from '@app/utils/helpers';
import axios from 'axios';
import {
  SWAGGER_GET_ALL_USERS,
  SWAGGER_LOGIN,
  SWAGGER_LOGOUT,
  SWAGGER_USER_PROFILE,
  FORGOT_PASSWORD,
  GET_AGENTS_LOCATION,
  SWAGGER_CHANGE_PASSWORD,
  GET_ALERT_COUNT,
  GET_USERS_COUNT,
  GET_USER_ROLES,
  RBAC_MODULE,
  USER_HASH,
} from './constants/swaggerConfig';



export const loginBySwagger = async (
  email: string,
  password: string,
  id: string,
  baseURLRedux:string
) => {
  const response = await axios.post(
    `${SWAGGER_LOGIN}?userId=${email}&pwd=${password}&ip=${id}`
  );
  const token = response.data;
  localStorage.setItem('token', token);
  removeWindowClass('login-page');
  removeWindowClass('hold-transition');
  return token;
};

export const hashLoginPassword = (value: string) => {
  return axios.post(`${USER_HASH}?str=${value}`);
};

export const logoutBySwagger = (token: string) => {
  return axios.post(`${SWAGGER_LOGOUT}?token=${token}`);
};

export const swaggerGetProfile = (token: string) => {
  return axios.get(`${SWAGGER_USER_PROFILE}/${token}`);
};

export const swaggerPostProfile = (
  token: string,
  password: string,
  userData: any
) => {
  return axios.post(
    `${SWAGGER_USER_PROFILE}/${token}?password=${password}`,
    userData
  );
};
export const swaggerPutProfile = (token: string, userData: any) => {
  return axios.put(`${SWAGGER_USER_PROFILE}/${token}`, userData);
};

export const swaggerGetAllUsers = (token: string) => {
  return axios.get(`${SWAGGER_GET_ALL_USERS}/${token}`);
};

export const getfilteredUsers = (
  token: string,
  userId: string,
  name: string,
  role: string,
  status: string
) => {
  return axios.get(
    `${SWAGGER_GET_ALL_USERS}/${token}?userId=${userId}&name=${name}&role=${role}&status=${status}`
  );
};

export const forgotPassword = (email: string) => {
  return axios
    .post(`${FORGOT_PASSWORD}`, {
      email
    })
    .then((res) => {
      // console.log(res.data);
    })
    .catch((err) => {
      // console.log(err);
    });
};

export const changePassword = (
  token: string,
  password: string,
  userId: string | undefined
) => {
  return axios.post(`${SWAGGER_CHANGE_PASSWORD}/${token}?password=${password}`);
};

export const swaggerGetUsersCount = (token: string) => {
  return axios.get(`${GET_USERS_COUNT}/${token}`);
};

export const getUserRoles = (token: string) => {
  return axios.get(`${GET_USER_ROLES}/${token}`);
};

export const getRbacData = (token: string) => {
  return axios.get(`${RBAC_MODULE}/${token}?count=100&pageNo=0`);
};

export const getAgentsLocation = (token: string, id?: string) => {
  if (id !== undefined) {
    return axios.get(`${GET_AGENTS_LOCATION}/${token}/${id}`);
  }
  return axios.get(`${GET_AGENTS_LOCATION}/${token}`);
};

export const getAlertCount = (token: string) => {
  return axios.get(`${GET_ALERT_COUNT}/${token}`);
};


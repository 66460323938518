import moment from 'moment-timezone';

export const sleep = (time: number) =>
  new Promise((res) => setTimeout(res, time));

export const calculateWindowSize = (windowWidth: number) => {
  if (windowWidth >= 1200) {
    return 'lg';
  }
  if (windowWidth >= 992) {
    return 'md';
  }
  if (windowWidth >= 768) {
    return 'sm';
  }
  return 'xs';
};

export const setWindowClass = (classList: string) => {
  const window: HTMLElement | null =
    document && document.getElementById('root');
  if (window) {
    // @ts-ignore
    window.classList = classList;
  }
};
export const addWindowClass = (classList: string) => {
  const window: HTMLElement | null =
    document && document.getElementById('root');
  if (window) {
    // @ts-ignore
    window.classList.add(classList);
  }
};

export const removeWindowClass = (classList: string) => {
  const window: HTMLElement | null =
    document && document.getElementById('root');
  if (window) {
    // @ts-ignore
    window.classList.remove(classList);
  }
};

export const formatDate = (date: any) => {
  if (date !== null) {
    let momentObject = moment(date);

    // Get the user's local timezone abbreviation
    const localTimezoneAbbreviation = moment
      .tz(momentObject, moment.tz.guess())
      .format('z');

    // Format the date with the user's local datetime and timezone abbreviation
    return (
      momentObject.format('DD MMM YYYY hh:mm A') +
      ' ' +
      localTimezoneAbbreviation
    );
  }

  return '';
};

export const timeStampToUTCDate = (timestamp: number | null) => {
  if (timestamp!== null &&!isNaN(timestamp)) {
    // Convert timestamp to a Moment object
    const momentObject = moment.unix(timestamp / 1000); // Assuming timestamp is in seconds

    // Format the date with the user's local datetime without converting to local timezone
    return momentObject.format('DD MMM YYYY hh:mm A');
  }

  return '';
};

export const timeStampToDate = (timestamp: number | null) => {
  if (timestamp !== null && !isNaN(timestamp)) {
    // Convert timestamp to a Moment object
    const momentObject = moment(timestamp);

    // Get the user's local timezone abbreviation
    const localTimezoneAbbreviation = moment
      .tz(momentObject, moment.tz.guess())
      .format('z');

    // Format the date with the user's local datetime and timezone abbreviation
    return (
      momentObject.format('DD MMM YYYY hh:mm A') +
      ' ' +
      localTimezoneAbbreviation
    );
  }

  return '';
};

export const formatDatetimeToDate = (inputStr: any, format?: string) => {
  if (!inputStr) {
    return '';
  }
  const parsedDate = new Date(inputStr);
  const year = parsedDate?.getFullYear();
  const month = String(parsedDate?.getMonth() + 1).padStart(2, '0');
  const day = String(parsedDate?.getDate()).padStart(2, '0');
  if (format === 'MM/DD/YY' || format === 'mm/dd/yy') {
    return `${month}/${day}/${year % 100}`;
  }
  if (format === 'dd-mm-yyyy') {
    return `${day}-${month}-${year}`;
  }

  return `${year}-${month}-${day}`;
};
export const formatDateToMonthDayYear = (inputStr: any) => {
  if (!inputStr) {
    return '';
  }
  const parsedDate = new Date(inputStr);
  const year = parsedDate?.getFullYear();
  const month = String(parsedDate?.getMonth() + 1).padStart(2, '0');
  const day = String(parsedDate?.getDate()).padStart(2, '0');

  return `${month}/${day}/${year}`;
};

export const dateToMilliseconds = (inputDate: any) => {
  const selectedDate = new Date(inputDate);

  const milleSeconds = selectedDate.getTime();
  return milleSeconds;
};

export const getRbacDetailValue = (value: string | null | undefined): string => {
  return value === 'null' || !value ? '' : value;
};


export function convertUTCToMilliseconds(inputDate: any,hoursToSubtract:string = '4') {
  // 05-06-2024
  // dd-mm-yy

  const momentDate = moment(inputDate);
  const localTime = momentDate.local();
  console.log(localTime)
  console.log(localTime.valueOf())
  // const dateMoment = moment.utc(inputDate, 'YYYY-MM-DD');
  // const dateUTC = dateMoment.valueOf(); // Convert to UTC milliseconds

  // dateMoment.subtract(hoursToSubtract, 'hours');
  // const adjustedDateUTC = dateMoment.valueOf();

  // You can now use dateUTC for further processing or display
  return localTime.valueOf();
}


export function convertUTCToMillisecondsWithTime(inputDate: string, time: string = '23:59:59') {


  const momentDate = moment(inputDate);
  const localTime = momentDate.endOf('day').local();
  console.log(localTime)
  console.log(localTime.valueOf())



  return localTime.valueOf();
}

// with out package 

// export function convertUTCToMilliseconds(inputDate: any) {
//   // Parse the input date string in UTC format (YYYY-MM-DD)
//   const date = new Date(inputDate + 'T00:00:00Z'); 
//   // Get the time value in milliseconds since January 1, 1970, 00:00:00 UTC
//   const dateUTC = date.getTime();
  
//   return dateUTC;
// }


export function convertInputDateToUTCAndMilliseconds(inputDate:any) {
  const date = new Date(inputDate);
    
  // Convert the date to UTC and get the UTC date string
  const utcDateString = date.toISOString();

  // Convert the UTC date string to milliseconds
  const utcDateInMilliseconds = Date.parse(utcDateString);
  console.log(utcDateInMilliseconds)

  return utcDateInMilliseconds 
}



export const formatPayloadDate = (date: any, format?: string) => {
  if (!date) {
    return '';
  }
  const splittedDate = date.split('-');
  const outputDate = `${splittedDate[2]}-${splittedDate[1]}-${splittedDate[0]}`;
  return outputDate;
};

export const formatTime = (input: any) => {
  let seconds: number;

  if (typeof input === 'number') {
    seconds = input;
  } else if (typeof input === 'string') {
    seconds = parseFloat(input);
  } else if (typeof input === 'object' && input !== null) {
    // Assuming the object has a property 'value' which contains the time in seconds
    seconds = parseFloat(input.value);
  } else {
    return 'Invalid input';
  }

  if (isNaN(seconds) || seconds === 0) return '0 Seconds';

  const timeUnits = [
    'Seconds',
    'Minutes',
    'Hours',
    'Days',
    'Weeks',
    'Months',
    'Years'
  ];
  const unitLengths = [60, 60, 24, 7, 4, 12]; // Number of units in each level

  let index = 0;

  while (seconds >= unitLengths[index] && index < timeUnits.length - 1) {
    seconds /= unitLengths[index];
    index += 1;
  }

  if (timeUnits[index] === 'Seconds') {
    return `${Math.round(seconds)} ${timeUnits[index]}`;
  } else if (timeUnits[index] === 'Minutes') {
    return `${Math.floor(seconds)} ${timeUnits[index]}`;
  } else {
    const wholePart = Math.floor(seconds);
    const remainderInSeconds = Math.round((seconds - wholePart) * 60);
    return `${wholePart} ${timeUnits[index]} ${remainderInSeconds} mins`;
  }
};

export const formatBytes = (input: any, decimals = 2) => {
  // if (bytes === 0) return '0 Bytes';
  // const k = 1024;
  // const dm = decimals < 0 ? 0 : decimals;
  // const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  // const i = Math.floor(Math.log(bytes) / Math.log(k));
  // return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;

  let bytes: number;

  if (typeof input === 'number') {
    bytes = input;
  } else if (typeof input === 'string') {
    bytes = parseFloat(input);
  } else if (typeof input === 'object' && input !== null) {
    // Assuming the object has a property 'value' which contains the byte size
    bytes = parseFloat(input.value);
  } else {
    return 'Invalid input';
  }

  if (isNaN(bytes) || bytes === 0) return '0 Bytes';

  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let index = 0;

  while (bytes >= 1024 && index < sizes.length - 1) {
    bytes /= 1024;
    index += 1;
  }

  return `${bytes.toFixed(decimals)} ${sizes[index]}`;
};

export const handleInputChange = (value: any) => {
  let input = value;

  // Allow only numeric input
  input = input.replace(/[^0-9]/g, '');

  // Limit to three characters
  input = input.slice(0, 3);

  // Enforce a minimum value of 10
  const numericValue = Math.max(0, parseInt(input, 10) || 0);

  // Enforce a maximum value of 100
  const clampedValue = Math.min(100, numericValue);

  return clampedValue;
};

export interface ValidationResult {
  minLength: boolean;
  hasUpperCase: boolean;
  hasLowerCase: boolean;
  hasNumber: boolean;
  hasSpecialChar: boolean;
  noSequentialChars: boolean;
  noRepetitivePatterns: boolean;
  notCommonPassword: boolean;
  score: number;
}

export const validatePassword = (password: string): ValidationResult => {
  const minLength = password.length >= 8;
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasNumber = /[0-9]/.test(password);
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
  const noSequentialChars =
    !/(0123456789|abcdefghijklmnopqrstuvwxyz|ABCDEFGHIJKLMNOPQRSTUVWXYZ)/.test(
      password
    );
  const noRepetitivePatterns = !/(.)\1{5,}/.test(password);
  const commonPasswords = [
    '123456',
    'password',
    '12345678',
    '123456789',
    '12345',
    '1234',
    '111111',
    '1234567',
    '123123',
    'abc123',
    'qwerty',
    'password1'
  ];
  const notCommonPassword = !commonPasswords.includes(password.toLowerCase());

  const score = [
    minLength,
    hasUpperCase,
    hasLowerCase,
    hasNumber,
    hasSpecialChar,
    noSequentialChars,
    noRepetitivePatterns,
    notCommonPassword
  ].filter(Boolean).length;

  return {
    minLength,
    hasUpperCase,
    hasLowerCase,
    hasNumber,
    hasSpecialChar,
    noSequentialChars,
    noRepetitivePatterns,
    notCommonPassword,
    score
  };
};

export const getStrengthColor = (score: number): string => {
  switch (score) {
    case 8:
      return 'green';
    case 7:
      return 'limegreen';
    case 6:
      return 'yellowgreen';
    case 5:
      return 'yellow';
    case 4:
      return 'orange';
    case 3:
      return 'orangered';
    case 2:
    case 1:
    default:
      return 'red';
  }
};

import Container from '@app/components/CustomDash/Container'
import React from 'react'

type Props = {}

function PageOneForms({}: Props) {
  return (
    <Container>


<div className="px-3 py-3">



<div className="row">
  <div className="col-lg-6 ">
    <div className="bg-white rounded-lg shadow-sm p-5">
      {/* <!-- Credit card form tabs --> */}
      <ul role="tablist" className="nav bg-light nav-pills rounded-pill nav-fill mb-3">
        <li className="nav-item">
          <a data-toggle="pill" href="#nav-tab-card" className="nav-link active rounded-pill">
                              <i className="fa fa-credit-card"></i>
                              Credit Card
                          </a>
        </li>
        <li className="nav-item">
          <a data-toggle="pill" href="#nav-tab-paypal" className="nav-link rounded-pill">
                              <i className="fa fa-paypal"></i>
                              Paypal
                          </a>
        </li>
        <li className="nav-item">
          <a data-toggle="pill" href="#nav-tab-bank" className="nav-link rounded-pill">
                              <i className="fa fa-university"></i>
                               Bank Transfer
                           </a>
        </li>
      </ul>
      {/* <!-- End --> */}


      {/* <!-- Credit card form content --> */}
      <div className="tab-content">

        {/* <!-- credit card info--> */}
        <div id="nav-tab-card" className="tab-pane fade show active">
          <p className="alert alert-success">Some text success or error</p>
          <form role="form">
            <div className="form-group">
              <label htmlFor="username">Full name (on the card)</label>
              <input type="text" name="username" placeholder="Jason Doe" required className="form-control"/>
            </div>
            <div className="form-group">
              <label htmlFor="cardNumber">Card number</label>
              <div className="input-group">
                <input type="text" name="cardNumber" placeholder="Your card number" className="form-control" required/>
                <div className="input-group-append">
                  <span className="input-group-text text-muted">
                                              <i className="fa fa-cc-visa mx-1"></i>
                                              <i className="fa fa-cc-amex mx-1"></i>
                                              <i className="fa fa-cc-mastercard mx-1"></i>
                                          </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-8">
                <div className="form-group">
                  <label><span className="hidden-xs">Expiration</span></label>
                  <div className="input-group">
                    <input type="number" placeholder="MM" name="" className="form-control" required/>
                    <input type="number" placeholder="YY" name="" className="form-control" required/>
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="form-group mb-4">
                  <label data-toggle="tooltip" title="Three-digits code on the back of your card">CVV
                                              <i className="fa fa-question-circle"></i>
                                          </label>
                  <input type="text" required className="form-control"/>
                </div>
              </div>



            </div>
            <button type="button" className="subscribe btn btn-primary btn-block rounded-pill shadow-sm"> Confirm  </button>
          </form>
        </div>
        {/* <!-- End --> */}

        {/* <!-- Paypal info --> */}
        <div id="nav-tab-paypal" className="tab-pane fade">
          <p>Paypal is easiest way to pay online</p>
          <p>
            <button type="button" className="btn btn-primary rounded-pill"><i className="fa fa-paypal mr-2"></i> Log into my Paypal</button>
          </p>
          <p className="text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>
        </div>
        {/* <!-- End --> */}

        {/* <!-- bank transfer info --> */}
        <div id="nav-tab-bank" className="tab-pane fade">
          <h6>Bank account details</h6>
          <dl>
            <dt>Bank</dt>
            <dd> THE WORLD BANK</dd>
          </dl>
          <dl>
            <dt>Account number</dt>
            <dd>7775877975</dd>
          </dl>
          <dl>
            <dt>IBAN</dt>
            <dd>CZ7775877975656</dd>
          </dl>
          <p className="text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>
        </div>
        {/* <!-- End --> */}
      </div>
      {/* <!-- End --> */}

    </div>
  </div>
  <div className='col-lg-5'>

  <section className="payment-form dark">
      <div className="">
       
        <form>
          <div className="products">
            <h3 className="title">Checkout</h3>
            <div className="item">
              <span className="price">$200</span>
              <p className="item-name">Product 1</p>
              <p className="item-description">Lorem ipsum dolor sit amet</p>
            </div>
            <div className="item">
              <span className="price">$120</span>
              <p className="item-name">Product 2</p>
              <p className="item-description">Lorem ipsum dolor sit amet</p>
            </div>
            <div className="total">Total<span className="price">$320</span></div>
          </div>
          <div className="card-details">
            <h3 className="title">Credit Card Details</h3>
            <div className="row">
              <div className="form-group col-sm-7">
                <label htmlFor="card-holder">Card Holder</label>
                <input id="card-holder" type="text" className="form-control" placeholder="Card Holder" 
                aria-label="Card Holder" aria-describedby="basic-addon1"/>
              </div>
              <div className="form-group col-sm-5">
                <label htmlFor="">Expiration Date</label>
                <div className="input-group expiration-date">
                  <input type="text" className="form-control" placeholder="MM" aria-label="MM" aria-describedby="basic-addon1"/>
                  <span className="date-separator">/</span>
                  <input type="text" className="form-control" placeholder="YY" aria-label="YY" aria-describedby="basic-addon1"/>
                </div>
              </div>
              <div className="form-group col-sm-8">
                <label htmlFor="card-number">Card Number</label>
                <input id="card-number" type="text" className="form-control" placeholder="Card Number" aria-label="Card Holder"
                 aria-describedby="basic-addon1"/>
              </div>
              <div className="form-group col-sm-4">
                <label htmlFor="cvc">CVC</label>
                <input id="cvc" type="text" className="form-control" placeholder="CVC" aria-label="Card Holder" aria-describedby="basic-addon1"/>
              </div>
              <div className="form-group col-sm-12">
                <button type="button" className="btn btn-primary btn-block">Proceed</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>

  </div>

  <div className="col-lg-6">
<div className="card">
<div className="card-header"><strong>Company</strong><small> Form</small></div>
<div className="card-body card-block">
<div className="form-group"><label htmlFor="company" className=" form-control-label">Company</label>
<input type="text" id="company" placeholder="Enter your company name" className="form-control"/></div>
<div className="form-group"><label htmlFor="vat" className=" form-control-label">VAT</label>
<input type="text" id="vat" placeholder="DE1234567890" className="form-control"/></div>
<div className="form-group"><label htmlFor="street" className=" form-control-label">Street</label>
<input type="text" id="street" placeholder="Enter street name" className="form-control"/></div>
<div className="row form-group">
<div className="col-8">
<div className="form-group"><label htmlFor="city" className=" form-control-label">City</label>
<input type="text" id="city" placeholder="Enter your city" className="form-control"/></div>
</div>
<div className="col-8">
<div className="form-group"><label htmlFor="postal-code" className=" form-control-label">Postal Code</label>
<input type="text" id="postal-code" placeholder="Postal Code" className="form-control"/></div>
</div>
</div>
<div className="form-group"><label htmlFor="country" className=" form-control-label">Country</label>
<input type="text" id="country" placeholder="Country name" className="form-control"/></div>
</div>
</div>
</div>
</div>
</div>

    </Container>
  )
}

export default PageOneForms
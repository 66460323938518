import Container from '@app/components/CustomDash/Container';
import NoOfRecords from '@app/components/common/NoOfRecords';
import Pagination from '@app/components/common/Pagination';
import {getAgentEndpointIds} from '@app/services/agents';
import {
  getAlertTypes,
  getAcknowledgedAlerts,
  getAcknowledgedAlertsDownlaod
} from '@app/services/alerts';
import {RECORDS_PER_PAGE} from '@app/services/constants/config';
import {formatDate, handleInputChange} from '@app/utils/helpers';
import React, {useEffect, useState} from 'react';
import {Col, Form} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import SearchDropDown from '../agents/SearchDropDown';

const AcknowledgedAlerts = () => {
  const [showFilter, setShowFilter] = useState(false);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [agentId, setAgentId] = useState('');
  const [roles, setRoles] = useState<any>([]);
  const [clearInput, setClearInput] = useState(false);

  const [alertType, setAlertType] = useState('');
  const [listingData, setListingData] = useState([]);
  const [agentsList, setAgentsList] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [numberOfRecords, setNumberOfRecords] = useState(RECORDS_PER_PAGE);

  const token = useSelector((state: any) => state.auth.token);
  const isLoggedIn = useSelector((state: any) => state.auth.isLoggedIn);
  const isAdmin = useSelector((state: any) => state.auth.isAdmin);
  const darkmode = useSelector((state: any) => state.account.darkmode);

  // Perform array slicing based on the current page
  const startIndex = (pageNumber - 1) * numberOfRecords;
  const endIndex = pageNumber * numberOfRecords;
  const paginatedListingData = listingData?.slice(startIndex, endIndex);

  const [sortOrder, setSortOrder] = useState('asc');
  const [sortColumn, setSortColumn] = useState(null);

  // TODO FIX BUGS
  const sortTableData = (column: any) => {
    const sortedData = [...listingData];

    // *** NEW IMPLEMENTATION ***
    // If One column asc then next column also asc, if one dsc, then next also dsc..
    if (sortColumn === column) {
      sortedData.reverse();
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      if (sortOrder === 'asc' || sortOrder === null) {
        // sort aesc if the current order is asc or null
        sortedData.sort((a, b) => (a[column] < b[column] ? -1 : 1));
        setSortColumn(column);
        setSortOrder('asc');
      } else {
        sortedData.sort((a, b) => (a[column] > b[column] ? -1 : 1)); // sort desc if the current order is desc
        setSortColumn(column);
        setSortOrder('desc');
      }
    }

    setListingData(sortedData);
  };

  const getAllAgents = () => {
    const cachedData = localStorage.getItem('cachedEndpoints');

    if (cachedData) {
      setAgentsList(JSON.parse(cachedData));
    } else {
      getAgentEndpointIds(token)
        .then((response) => {
          const {data} = response;
          setAgentsList(data);
          localStorage.setItem('cachedEndpoints', JSON.stringify(data));
        })
        .catch((err) => {
          setAgentsList([]);
        });
    }
  };

  const fetchAcknowledgedAlerts = (
    token: string,
    agentId: string = '',
    alertType: string = ''
  ) => {
    getAcknowledgedAlerts(token, agentId, alertType)
      .then((response) => {
        setListingData(response.data);
        setLoading(false);
      })
      .catch((err) => setLoading(false));
  };

  const downloadCSV = () => {
    getAcknowledgedAlertsDownlaod(token, agentId, alertType)
      .then((res) => {
        const url = URL.createObjectURL(new Blob([res.data]));

        // Create a hidden anchor element
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Acknowledged Alerts.csv'; // Replace with the desired filename and extension
        a.click();

        // Release the temporary URL
        URL.revokeObjectURL(url);
      })
      .catch((err) => {
        toast.error('Failed to download Software Inventory Reports');
      });
  };

  useEffect(() => {
    if (token) {
      fetchAcknowledgedAlerts(token, agentId, alertType);
    }
    getAllAgents();
  }, []);

  useEffect(() => {
    getAlertTypes(token)
      .then((res) => {
        setRoles(
          Object.entries(res.data).map(([key, value]) => ({key, value}))
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // Pagination Bugfix
  useEffect(() => {
    if (paginatedListingData?.length === 0) {
      setPageNumber(1);
    }
  }, [paginatedListingData]);

  const handleSelect = (id: string) => {
    setAgentId(id);
  };

  const handleClear = () => {
    setClearInput(true);
  };

  const handleClearComplete = () => {
    setClearInput(false);
  };

  return (
    <Container>
      <div className="container-fluid">
        <div className="row d-flex justify-content-center align-items-center">
          <Col xs={12}>
            <div className="mt-2 FilterButtonContainer col-lg-10 col-md-12 mx-auto pt-2 mb-2 d-flex justify-content-between">
              <h3 className="subHeaders"> </h3>
              <div className="d-flex flex-wrap align-items-center justify-content-end">
                <NoOfRecords
                  value={numberOfRecords}
                  setValue={(value) => setNumberOfRecords(value)}
                  totalRecords={listingData?.length}
                />

                {listingData?.length !== 0 && (
                  <div className="paginationContainer">
                    <Pagination
                      className="mr-1"
                      currentPage={pageNumber}
                      recordsPerPage={numberOfRecords}
                      totalRecords={listingData?.length}
                      onPageChange={(page: number) => setPageNumber(page)}
                    />
                  </div>
                )}
                <button
                  type="button"
                  className="  m-1 reportsBackButton"
                  onClick={() => {
                    {
                      navigate('/reports');
                    }
                  }}
                  id="a-reports-back-btn"
                >
                  <i className="fa-solid fa-left-long"></i>
                  <span>Back</span>
                </button>

                <button
                  type="button"
                  onClick={downloadCSV}
                  className="m-1 filterButton mainFilterButton"
                  id="a-reports-download-btn"
                >
                  <i className="fa-solid fa-download"></i>
                  <span className="ml-2">Reports</span>
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setShowFilter(!showFilter);
                  }}
                  className="m-1 filterButton mainFilterButton"
                  id="a-reports-filter-btn"
                >
                  <i className="fa-solid fa-filter" />
                  <span className="ml-2">Filter</span>
                </button>
              </div>
            </div>
            {showFilter && (
              <section
                className={` FilterOption ${
                  darkmode && 'darkSecondaryWrapper'
                } col-lg-10 col-md-12 mx-auto mb-0 d-flex  flex-column`}
              >
                <Form className="d-flex flex-wrap  justify-content-start">
                  <Form.Group
                    controlId="role"
                    className="col-lg-3 col-sm-5 col-12 "
                  >
                    <Form.Label> Endpoint ID</Form.Label>
                    {/* <Form.Control
                      as="select"
                      value={agentId}
                      onChange={(e) => {
                        setAgentId(e.target.value);
                      }}
                      id='a-reports-endpoint-id-option'
                    >
                      <option value="">--Select--</option>
                      {agentsList.map((agent: any) => (
                        <option value={agent}>{agent}</option>
                      ))}
                    </Form.Control> */}
                    <SearchDropDown
                      data={agentsList}
                      onSelect={handleSelect}
                      clearInput={clearInput}
                      onClearComplete={handleClearComplete}
                      isManagedEndPointPage={false}
                    />
                  </Form.Group>

                  <Form.Group
                    controlId="role"
                    className="col-lg-3 col-sm-5 col-12 "
                  >
                    <Form.Label>Alert Type</Form.Label>
                    <Form.Control
                      as="select"
                      value={alertType}
                      onChange={(e) => setAlertType(e.target.value)}
                      id="a-reports-alert-type-option"
                    >
                      <option value="">--Select--</option>
                      {roles?.map((i: any, index: number) => (
                        <option key={index} value={i.key}>
                          {i.value}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Form>

                <div className="d-flex flex-wrap justify-content-center mt-4 pl-1">
                  <button
                    type="button"
                    className=" pointer my-2 my-sm-0 alertFilterButton"
                    onClick={() => {
                      fetchAcknowledgedAlerts(token, agentId, alertType);
                    }}
                    id="a-reports-apply-btn"
                  >
                    Apply
                  </button>
                  <button
                    type="button"
                    className="mx-2 my-2 my-sm-0 pointer alertFilterButton"
                    onClick={() => {
                      setAlertType('');
                      setAgentId('');
                      fetchAcknowledgedAlerts(token);
                      handleClear();
                    }}
                    id="a-reports-clear-btn"
                  >
                    Clear
                  </button>
                  <button
                    type="button"
                    className="Close my-2 my-sm-0 pointer alertFilterButton"
                    onClick={() => {
                      setShowFilter(false);
                    }}
                    id="a-reports-close-btn"
                  >
                    Close
                  </button>
                </div>
              </section>
            )}

            <div className="card-body col-lg-10 col-md-12 mx-auto px-0 pb-3">
              <div className="d-flex tableContainer overflow-auto flex-column">
                <table className={`customTable ${darkmode ? 'darkTable' : ''}`}>
                  <thead className="">
                    <tr>
                      <th
                        className="p-2 text-left col-2 pl-4"
                        onClick={() => {
                          // handleTableHeaderSorting('userId')
                          sortTableData('agentId');
                        }}
                      >
                        {' '}
                        Endpoint ID{' '}
                        {sortColumn === 'agentId' &&
                          (sortOrder === 'asc' ? (
                            <i className="fa fa-sort-up" />
                          ) : (
                            <i className="fa fa-sort-down" />
                          ))}
                      </th>
                      <th
                        className="p-2 text-left col-2"
                        onClick={() => {
                          // handleTableHeaderSorting('userId')
                          sortTableData('createdAt');
                        }}
                      >
                        Time Created{' '}
                        {sortColumn === 'createdAt' &&
                          (sortOrder === 'asc' ? (
                            <i className="fa fa-sort-up" />
                          ) : (
                            <i className="fa fa-sort-down" />
                          ))}
                      </th>
                      <th
                        className="p-2 text-left col-2"
                        onClick={() => {
                          // handleTableHeaderSorting('userId')
                          sortTableData('type');
                        }}
                      >
                        Type{' '}
                        {sortColumn === 'type' &&
                          (sortOrder === 'asc' ? (
                            <i className="fa fa-sort-up" />
                          ) : (
                            <i className="fa fa-sort-down" />
                          ))}
                      </th>
                      <th
                        className="p-2 text-left col-4"
                        onClick={() => {
                          // handleTableHeaderSorting('userId')
                          sortTableData('summary');
                        }}
                      >
                        Summary{' '}
                        {sortColumn === 'summary' &&
                          (sortOrder === 'asc' ? (
                            <i className="fa fa-sort-up" />
                          ) : (
                            <i className="fa fa-sort-down" />
                          ))}
                      </th>
                      <th
                        className="p-2 text-left col-2"
                        onClick={() => {
                          // handleTableHeaderSorting('userId')
                          sortTableData('updatedBy');
                        }}
                      >
                        Acknowledged By{' '}
                        {sortColumn === 'updatedBy' &&
                          (sortOrder === 'asc' ? (
                            <i className="fa fa-sort-up" />
                          ) : (
                            <i className="fa fa-sort-down" />
                          ))}
                      </th>
                    </tr>
                  </thead>

                  {!loading && paginatedListingData.length > 0 && (
                    <tbody className="">
                      {paginatedListingData?.map((data: any) => (
                        <tr key={data.id}>
                          <td className=" tooltipDiv text-left pl-4">
                            {data.agentId.slice(0, 16).toUpperCase()}{' '}
                            {data.agentId.length > 16 ? '...' : ''}{' '}
                            <p className="tooltipText">
                              {data.agentId.toUpperCase()}
                            </p>
                          </td>
                          <td className="p-2 text-left">
                            {formatDate(data.createdAt)}
                          </td>
                          <td className="p-2 text-left">{data.type}</td>
                          <td className="p-2 text-left">{data.summary}</td>
                          <td className="p-2 text-left">{data.updatedBy}</td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
                {loading && (
                  <p
                    className={`mt-0 ${
                      darkmode ? 'noRecordsDark' : 'noRecords'
                    }`}
                  >
                    Loading...
                  </p>
                )}
                {!loading && paginatedListingData.length === 0 && (
                  <p
                    className={`mt-0 ${
                      darkmode ? 'noRecordsDark' : 'noRecords'
                    }`}
                  >
                    No Acknowledged Alerts Found..
                  </p>
                )}
              </div>
            </div>
          </Col>
        </div>
      </div>
    </Container>
  );
};

export default AcknowledgedAlerts;

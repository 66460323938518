import {ContentHeader} from '@app/components';
import Container from '@app/components/CustomDash/Container';
import {swaggerGetAlertAck, swaggerGetAlertDetails} from '@app/services/alerts';
import {formatDate} from '@app/utils/helpers';
import React, {useEffect, useState} from 'react';
import {Table} from 'react-bootstrap';
import {useSelector} from 'react-redux';
import {useNavigate, useParams, useRoutes} from 'react-router-dom';
import {toast} from 'react-toastify';

type Props = {};

function AlertDetailsPage({}: Props) {
  const {alertId} = useParams();
  const navigate = useNavigate();
  const token = useSelector((state: any) => state.auth.token);
  const [alertDetailsData, setAlertDetailsData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const isAdmin = useSelector((state: any) => state.auth.isAdmin);
  const [commentInfo, setCommentInfo] = useState<any>(
    alertDetailsData?.comment
  );

  console.log(isAdmin)

  const darkmode = useSelector((state: any) => state.account.darkmode);

  const fetchAlertDetails = () => {
    swaggerGetAlertDetails(token, alertId)
      .then((res) => {
        console.log(res.data);
        setAlertDetailsData(res.data);
        setCommentInfo(res.data?.comment);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAck = () => {
    swaggerGetAlertAck(token, alertId, commentInfo)
      .then((res) => {
        console.log(res.data);
        toast.success('Acknowledged alert id ' + alertId);
        navigate('/alerts');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchAlertDetails();
  }, []);

  let alertDetailsDescription = alertDetailsData?.description.split('\n');

  const renderDescription = alertDetailsDescription?.map(
    (i: string, index: string) => <p key={index}>{i}</p>
  );

  // console.log(alertDetailsDescription);

  // console.log(alertDetailsData?.description)

  return (
    <Container>
      <div className="mt-5">
        {/* <ContentHeader title="Alert  Details" /> */}
      </div>

      {loading ? (
        <div
          className="container-fluid d-flex justify-content-center align-items-center"
          style={{height: '55vh'}}
        >
          <p
            style={{fontSize: '1.3rem'}}
            className={darkmode && 'noRecordsDark'}
          >
            LOADING...
          </p>
        </div>
      ) : (
        <div className="container-fluid overflow-auto">
          <div
            className={`customTable-2 card card-body ${darkmode && 'darkSecondaryWrapper'}`}
          >
            <div className="d-flex">
              <div className="col-3 borderRight tableKey mt-2">
                <span>ID</span>
              </div>
              <div className="tableValue">
                <span>{alertDetailsData?.id}</span>
              </div>
            </div>

            <div className="d-flex">
              <div className="col-3 borderRight tableKey pt-2">
                <span> Endpoint ID</span>
              </div>
              <div className="tableValue pt-2">
                <span>{alertDetailsData?.agentId}</span>
              </div>
            </div>

            <div className="d-flex">
              <div className="col-3 borderRight tableKey pt-2">
                <span>Created At</span>
              </div>
              <div className="tableValue pt-2">
                <span>
                  {formatDate(alertDetailsData?.createdAt, 'DD MMM YYYY hh:mm')}
                </span>
              </div>
            </div>

            <div className="d-flex">
              <div className="col-3 borderRight tableKey pt-2">
                <span>Acknowledged At</span>
              </div>
              <div className="tableValue pt-2">
                <span>
                  {formatDate(alertDetailsData?.updatedAt, 'DD MMM YYYY hh:mm')}
                </span>
              </div>
            </div>

            <div className="d-flex">
              <div className="col-3 borderRight tableKey pt-2">
                <span>Acknowledged By</span>
              </div>
              <div className="tableValue pt-2">
                {/* <span>{alertDetailsData?.createdBy} </span> */}
                <span>{alertDetailsData?.updatedBy}</span>
              </div>
            </div>

            <div className="d-flex">
              <div className="col-3 borderRight tableKey pt-2">
                <span>Type</span>
              </div>
              <div className="tableValue pt-2">
                <span>{alertDetailsData?.type}</span>
              </div>
            </div>

            <div className="d-flex">
              <div className="col-3 borderRight tableKey pt-2">
                <span>Severity</span>
              </div>
              <div className="tableValue pt-2">
                <span>{alertDetailsData?.severity}</span>
              </div>
            </div>

            <div className="d-flex">
              <div className="col-3 borderRight tableKey pt-2">
                <span>Summary</span>
              </div>
              <div className="tableValue pt-2">
                <span>{alertDetailsData?.summary}</span>
              </div>
            </div>

            <div className="d-flex">
              <div className="col-3 borderRight tableKey pt-2">
                <span>Description</span>
              </div>
              <div className="tableValue pt-2">
                <span>{renderDescription}</span>
              </div>
            </div>

            <div className="d-flex">
              <div className="col-3 borderRight tableKey pt-2">
                <span>Active</span>
              </div>
              <div className="tableValue pt-2">
                <span className="p-2 ">
                  {alertDetailsData?.active ? (
                    <i className="fa-regular fa-circle-check ml-3"></i>
                  ) : (
                    <i className="ml-3 fa-sharp fa-regular fa-circle-xmark"></i>
                  )}
                </span>
              </div>
            </div>

            <div className="d-flex">
              <div className="col-3 borderRight tableKey pt-2">
                <span>Comment</span>
              </div>
              <div className="tableValue col-6 pt-2">
                <span>
                  <textarea
                    className="w-100"
                    rows={6}
                    placeholder='Leave a comment:'
                    value={commentInfo}
                    disabled={
                      alertDetailsData?.updatedBy == null ||
                      alertDetailsData?.comment == null
                        ? false
                        : true
                    }
                    onChange={(e) => {
                      setCommentInfo(e.target.value);
                    }}
                    name=""
                    id="alert-details-comments"
                  />
                </span>
              </div>
            </div>
            <div className="d-flex">
              <div className="col-3 borderRight tableKey pt-2"></div>
              <div className="tableValue col-6 pt-2">
                <div className="d-flex justify-content-between">
                  <button
                    className="btn mr-4  filterButton"
                    onClick={() => {
                      navigate(-1)
                    }}
                    id="alerts-details-back-btn"
                  >
                    Back
                  </button>
                  {isAdmin &&
                    (alertDetailsData?.updatedBy === null ||
                    alertDetailsData?.comment === null ? (
                      <button
                        className="btn filterButton"
                        onClick={handleAck}
                        id="alerts-details-acknowledge-btn"
                      >
                        Acknowledge
                      </button>
                    ) : (
                      ''
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Container>
  );
}

export default AlertDetailsPage;

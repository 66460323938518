import {getUserRoles, logoutBySwagger} from '@app/services/swaggerAuth';
import {formatDate} from '@app/utils/helpers';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {PfButton, PfImage} from '@profabric/react-components';
import styled from 'styled-components';
import editProfileIcon from '../../../public/img/edit-profile-icon.png';
import {getProfileImage, uploadProfileImage} from '@app/services/account';
import {toast} from 'react-toastify';
import {setImage} from '@app/store/reducers/Account';
import { logoutUser } from '@app/store/reducers/auth';

const StyledUserImage = styled(PfImage)`
  // --pf-border: 3px solid #adb5bd;
  --pf-padding: 3px;
`;

const Account = () => {
  const dispatch = useDispatch();

  const handleLogout = () => {
    logoutBySwagger(token)
      .then((res) => {
        localStorage.removeItem('cachedSettingsData');
        localStorage.removeItem('onlineThresholdValue');
        localStorage.removeItem('agentsSettingsValue');

        localStorage.removeItem('cachedUser');
        localStorage.removeItem('cachedImage');
        localStorage.removeItem('cachedAlertData');
        localStorage.removeItem('cachedUsersData');
        localStorage.removeItem('cachedAgentsData');
        localStorage.removeItem('cachedRbacData');
        localStorage.removeItem('cachedValidatedSoftwareData');
        dispatch(logoutUser());
        toast.success('Logout Successful');
      })
      .catch((err) => {
        toast.error('Logout Failed');
      });
  };

  const user = useSelector((state: any) => state.auth.currentUser);
  const token = useSelector((state: any) => state.auth.token);
    const darkmode = useSelector((state: any) => state.account.darkmode);


  const image = useSelector((state: any) => state.account.image);

  const [roleDescription, setRoleDescription] = useState('');
  const [file, setFile] = useState<any>();
  const [toltip, settoltip] = useState<boolean>(false);

  const handleUpload = (e: any) => {
    setFile(e.target.files[0]);
  };

  const fetchRoles = () => {
    getUserRoles(token).then((response) => {
      const {data} = response;
      if (user.roles === 'ADMIN') {
        setRoleDescription(data.ADMIN);
      } else if (user.roles === 'READER') {
        setRoleDescription(data.READER);
      }
    });
  };

  const fetchProfileImg = () => {
    getProfileImage(token)
      .then((res) => {
        console.log(res);
        dispatch(setImage(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (file) {
      const formData = new FormData();
      formData.append('image', file);
      setTimeout(() => {
        uploadProfileImage(token, formData)
          .then((res) => {
            console.log(res);
            toast.success(res.data);
            window.location.reload();
          })
          .catch((err) => {
            console.log(err);
            toast.error(err.response.data);
          });
      }, 200);
    }
  }, [file]);

  useEffect(() => {
    fetchRoles();
    // fetchProfileImg();
  }, [user]);


  return (
    <div className="col-lg-6 mx-auto d-flex flex-column">
      <div
        className="col-11 mx-auto d-flex flex-column align-items-center mt-4"
        style={{borderBottom: '1px solid grey'}}
      >
        <div className="position-relative">
          {image ? (
            <img
              className="profileImg"
              src={`data:image/jpeg;base64,${image}`}
            />
          ) : (
            <StyledUserImage
              width={115}
              height={115}
              rounded
              src="/img/default-profile-pic.png"
              alt="User profile"
            />
          )}
          <img
            src="/img/edit-profile-icon.png"
            alt="change profile"
            className="position-absolute "
            style={{
              width: '35px',
              bottom: '15px',
              right: '0px',
              cursor: 'pointer'
            }}
            onClick={() => {}}
            aria-hidden="true"
          />

          <input
            type="file"
            onChange={handleUpload}
            accept="image/png, image/jpeg"
            className="profileimageEdit tooltip2"
            onMouseEnter={() => {
              settoltip(true)
            }}
            onMouseLeave={() => {
              settoltip(false)
            }}
            id='profile-img-upload'
          />

<span className={`tooltiptext2  ${toltip && 'tooltip2visible'}`}>Image size max 2 Mb and image type jpeg ,png.</span>
        </div>
        <div className="d-flex flex-column align-items-center mt-2">
          <h4 className={darkmode ? 'adminNameTextDark':'adminNameLight'}>
            {user.name || ''}
          </h4>
          <p>{roleDescription || user.roles}</p>
        </div>
      </div>
      <div className="col-10 mx-auto mt-3">
        <div className="d-flex justify-content-between py-2">
          <span className="fw-600">User ID</span>
          <span>{user.userId}</span>
        </div>
        <div className="d-flex justify-content-between py-2">
          <span className="fw-600">Last Login</span>
          <span>{formatDate(user.lastLogin) || ''}</span>
        </div>
        <div className="d-flex justify-content-between py-2">
          <span className="fw-600">Password Changed</span>
          <span>{formatDate(user.lastPasswordChange) || ''}</span>
        </div>
        <div className="d-flex justify-content-between py-2">
          <span className="fw-600">Created</span>
          <span>{formatDate(user.createdAt)}</span>
        </div>
        <div className='form-group mt-4'>
          <div className='d-flex justify-content-center'>
            <button
              id="logout-btn"
              className={darkmode ? 'logOutButtonDeskTopDark':'logOutButtonDeskTop'}
              onClick={handleLogout}
            >
            Log Out
            </button>
        </div>
        </div>
               
      </div>
    </div>
  );
};

export default Account;

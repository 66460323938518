import Container from '@app/components/CustomDash/Container';
import {
  getVulnerabilityDetails,
  getVulnerabilityList,
  postAckVulnerability,
  postAckVulnerabilityDisposition
} from '@app/services/threats';
import {formatDate, formatTime} from '@app/utils/helpers';
import React, {useEffect, useState} from 'react';
import {Form} from 'react-bootstrap';
import {useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';

type Props = {};

interface Vulnerability {
  customerId: string;
  createdBy: string;
  acknowledgedAt: string;
  acknowledgedBy: string;
  updatedBy: string | null;
  createdAt: number;
  updatedAt: number | null;
  id: string;
  softwareId: string;
  agentId: string;
  cveId: string;
  source: string;
  description: string;
  published: number;
  comment: string | null;
  softwareName: string;
  severity: string;
  vulnStatus: string;
  version: string;
  disposition: string;
  disposedBy: string;
  dispositionDate: string;
  scheduledDate: string;
}

function VulnerabilityDetails({}: Props) {
  const {id} = useParams();
  const navigate = useNavigate();
  const [vulnerabilityData, setVulnerabilityData] = useState<Vulnerability>();
  const [currentDate, setCurrentDate] = useState(getFormattedDate());
  const [disposition, setDisposition] = useState('');

  const [commentInfo, setCommentInfo] = useState<any>();
  const [scheduledDate, setScheduledDate] = useState<any>(() => {
    const today = new Date();
    return today.toISOString().split('T')[0];
  });

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Get the selected date from the input
    const selectedDateStr = e.target.value;

    // Create a Date object from the selected date string
    const selectedDate = new Date(selectedDateStr);

    // Convert the selected date to milliseconds
    const selectedDateMillis = selectedDate.getTime();

    // If the selected date is before today, reset the input value to today
    if (selectedDateMillis < new Date().getTime()) {
      e.target.value = scheduledDate; // Reset the input value to today's date
    } else {
      // Otherwise, update the scheduled date state with the selected date
      setScheduledDate(selectedDateStr);
    }
  };

  const token = useSelector((state: any) => state.auth.token);
  const darkmode = useSelector((state: any) => state.account.darkmode);
  const isAdmin = useSelector((state: any) => state.auth.isAdmin);

  function getFormattedDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Month is zero-based
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const fetchAlertDetails = () => {
    getVulnerabilityDetails(token, id)
      .then((response) => {
        console.log(response.data);
        const {data} = response;
        setVulnerabilityData(data);
        setDisposition(data?.disposition);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchAlertDetails();
  }, []);

  const handleAck = async () => {
    try {
      // First, acknowledge the vulnerability
      const ackRes = await postAckVulnerability(token, id, commentInfo);
      if (ackRes.status !== 200) {
        throw new Error('Failed to acknowledge vulnerability');
      }
      toast.success(`Acknowledged Vulnerability id ${id}`);

      // Navigate after both actions are successful
      navigate('/vulnerability');
    } catch (err) {
      console.error(err);
      toast.error('Failed to perform actions. Please try again.');
    }
  };

  const handleDisposition = async () => {
    try {
      const scheduledDateObj = new Date(scheduledDate);
      const reversedScheduledDateMillis = scheduledDateObj.getTime();

      const dispositionRes = await postAckVulnerabilityDisposition(
        token,
        id,
        disposition,
        disposition === 'Scheduled' ? reversedScheduledDateMillis : ''
      );

      if (dispositionRes.status !== 200) {
        throw new Error('Failed to set disposition');
      }

      toast.success(`Set Disposition for Vulnerability id ${id}`);
      // Refresh the window after success
      window.location.reload();
    } catch (error) {
      console.error('Error:', error);
      // Handle error
    }
  };

  console.log(vulnerabilityData?.disposition === 'Mitigation');

  const confirmClear = () => {
    handleDisposition();
    toast.dismiss();
  };

  const cancelClear = () => {
    toast.dismiss(); // Close the toast
  };

  return (
    <Container>
      <div className="container-fluid">
        <div className="d-flex justify-content-between">
          <h3 className="subHeaders mx-5 "> </h3>
        </div>

        <div className="overflow-auto ">
          <div
            className={`customTable-2 VulnerabilityDetails mx-5 ${
              darkmode && 'darkSecondaryWrapper'
            }`}
          >
            <table
              className={`w-100 vulnerabilityDEtailsTav ${
                darkmode ? 'darkTable' : ''
              }`}
            >
              <thead>
                <tr>
                  <th className="col-3"></th>
                  <th></th>
                </tr>
              </thead>

              <tbody className="">
                <tr>
                  <td> ID</td>
                  <td>{vulnerabilityData?.id}</td>
                </tr>
                <tr>
                  <td>Software Name</td>
                  <td>{vulnerabilityData?.softwareName}</td> {/*software name*/}
                </tr>
                <tr>
                  <td>Version</td>
                  <td>{vulnerabilityData?.version}</td> {/*software name*/}
                </tr>
                <tr>
                  <td>CVE ID</td>
                  <td>{vulnerabilityData?.cveId}</td>
                </tr>
                <tr>
                  <td>Acknowledged At</td>
                  <td>{formatDate(vulnerabilityData?.acknowledgedAt)}</td>
                </tr>
                <tr>
                  <td>Acknowledged By</td>
                  <td>{vulnerabilityData?.acknowledgedBy}</td>
                </tr>
                <tr>
                  <td>Updated At</td>
                  <td>{formatDate(vulnerabilityData?.updatedAt)}</td>
                </tr>
                <tr>
                  <td>Updated By</td>
                  <td>{vulnerabilityData?.updatedBy}</td>
                </tr>
                <tr>
                  <td>Disposition </td>
                  <td>
                    <Form.Control
                      as="select"
                      className="col-4"
                      id="disposition-dd-option"
                      disabled={
                      
                          !isAdmin ||
                          vulnerabilityData?.disposition === 'Mitigation'
                        
                      }
                      onChange={(e) => {
                        setDisposition(e.target.value);
                        setVulnerabilityData((prevData: any) => ({
                          ...prevData,
                          scheduledDate: null
                        }));
                      }}
                      value={disposition}
                    >
                      <option value="">Select Disposition</option>
                      <option value="Risk Accepted">Risk Accepted</option>
                      <option value="Mitigation">Mitigation</option>
                      <option value="Scheduled">Scheduled</option>
                    </Form.Control>
                  </td>
                </tr>
                <tr>
                  <td>Disposition Date </td>
                  <td>
                    {!vulnerabilityData?.dispositionDate ? (
                      <Form.Control
                        type="date"
                        className="col-4"
                        value={currentDate}
                        disabled
                      ></Form.Control>
                    ) : (
                      formatDate(vulnerabilityData?.dispositionDate)
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Disposed By </td>
                  <td>{vulnerabilityData?.disposedBy}</td>
                </tr>
                <tr>
                  <td>Scheduled Date </td>
                  <td>
                    {disposition === 'Scheduled' &&
                    vulnerabilityData?.scheduledDate ? (
                      formatDate(vulnerabilityData?.scheduledDate)
                    ) : disposition === 'Scheduled' ? (
                      <Form.Control
                        type="date"
                        disabled={disposition === 'Scheduled' ? false : true}
                        min={scheduledDate}
                        onChange={handleDateChange}
                        className="col-4"
                        id='scheduled-date-d-option'
                      ></Form.Control>
                    ) : (
                      <Form.Control
                        type="date"
                        value={''}
                        disabled
                        className="col-4 disabledColorDate"
                        id='scheduled-date-d-option'
                      ></Form.Control>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Created By</td>
                  <td>{vulnerabilityData?.createdBy}`</td>
                </tr>
                <tr>
                  <td>Date Detected</td> {/*datedetected*/}
                  <td>
                    {/* {formatTime(vulnerabilityData?.published)} */}
                    {formatDate(vulnerabilityData?.createdAt)}
                  </td>
                </tr>
                <tr>
                  <td>Description</td>
                  <td>{vulnerabilityData?.description}</td>
                </tr>
                <tr>
                  <td className="">Notes</td>
                  <td className="pl-3">
                    <textarea
                      className="w-100 rounded"
                      value={
                        !commentInfo ? vulnerabilityData?.comment : commentInfo
                      }
                      name=""
                      id="vul-details-comment"
                      cols={70}
                      rows={6}
                      placeholder="Leave a comment: "
                      onChange={(e) => {
                        setCommentInfo(e.target.value);
                      }}
                    ></textarea>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td className="d-flex justify-content-between">
                    <button
                      className="filterButton px-5"
                      onClick={() => {
                        navigate(-1)
                      }}
                      id="vul-details-back-btn"
                    >
                      Back
                    </button>

                    <div>
                      {isAdmin && (
                        <button
                          className="filterButton mr-4"
                          id='submit-desposition-btn'
                          onClick={() => {
                            if (vulnerabilityData?.disposition) {
                              toast.info(
                                <div>
                                  <p>
                                    Are you sure you want to edit the
                                    disposition date?
                                  </p>
                                  <button
                                    className="btn btn-xs btn-primary mr-3"
                                    onClick={confirmClear}
                                    id='confirm-d-yes-btn'
                                  >
                                    Yes
                                  </button>
                                  <button
                                    className="btn btn-xs btn-secondary"
                                    onClick={cancelClear}
                                    id='confirm-d-no-btn'
                                  >
                                    No
                                  </button>
                                </div>,
                                {
                                  position: 'top-center',
                                  autoClose: false,
                                  closeOnClick: false,
                                  draggable: false
                                }
                              );
                            } else {
                              handleDisposition();
                            }
                          }}
                        >
                          Submit Disposition
                        </button>
                      )}
                      {isAdmin && !vulnerabilityData?.comment && (
                        <button
                          className="filterButton px-5"
                          id="vul-details-acknowledge-btn"
                          onClick={() => {
                            handleAck();
                          }}
                        >
                          Acknowledge
                        </button>
                      )}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default VulnerabilityDetails;

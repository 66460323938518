import axios from 'axios';
import {CONFIGURE_DEBUG_X, DEBUG_SERVER} from './constants/swaggerConfig';

export const getConfigureDebugX = (string: string) => {
  return axios.get(`${CONFIGURE_DEBUG_X}?y=${string}`);
};

export const getDebugServer = () => {
  return axios.get(`${DEBUG_SERVER}`);
};
